import React from 'react';
import BaseComponent from "../../BaseComponent";

import searchIcon from "../../../assets/europackaging/search.svg";

export default class ScreenSearchComponent extends React.Component {

    keywordTimeout = 0;
    isAttached = false;

    constructor(props, context) {
        super(props, context);

        this.state = {
            keyword : null
        };
    }

    componentDidMount() {
        this.isAttached = true;
    }

    componentWillUnmount() {
        this.isAttached = false;
        clearTimeout(this.keywordTimeout);
    }

    /**
     * Handler for input items to ensure the value of the input is put into the state
     * @param event     Event from the onChange property
     */
    handleChange = (event) => {
        const target = event.target;
        const name = target.name;

        let value = target.value;
        if (target.type === "checkbox") {
            value = target.checked;
        }

        this.setState({
            [name] : value
        });

        let interval = this.props.timeoutInterval;
        if (interval === undefined) {
            interval = 800;
        }

        clearTimeout(this.keywordTimeout);
        this.keywordTimeout = setTimeout(() => {
            if (!this.isAttached) return;

            this.handleCallback();
        }, interval);
    }

    handleCallback = () => {
        if (this.props.callback !== undefined) {
            this.props.callback(this.state.keyword);
        }
    }

    render() {
        let placeholder = this.props.placeholder;
        if (placeholder === undefined) {
            placeholder = "Search";
        }

        let iconUri = this.props.iconUri;
        if (iconUri === undefined) {
            iconUri = searchIcon;
        }

        return (
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <span className={"screen-search-icon"} style={{backgroundImage : "url(" + iconUri + ")"}} />
                    </span>
                </div>
                <input type={"text"} className={"form-control"} name={"keyword"} onChange={this.handleChange} placeholder={placeholder} />
            </div>
        )
    }
}