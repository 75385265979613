import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import ScreenSearchComponent from "../common/ScreenSearchComponent";
import {TablePaginator} from "../../common/TableComponent";

import "./CleaningSpecificationConfiguratorComponent.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../common/ToastManagerComponent";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";

export default class CleaningSpecificationConfiguratorComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            specificationNetworkInFlight : false,
            configNetworkInFlight : false,
            currentPage : 1,
            tableKeyword : "",
            specifications : [],
            siteAreas : [],
            fileTypes : [],
            tableTotal : 0,
            tableLimit : 10
        })
    }

    componentDidMount() {
        this.getSpecificationTableDataFromNetwork();
        this.getSpecificationConfigurationDataOverNetwork();
    }

    moveToSpecificationDetail = (specification) => {
        let append = "new";
        if (specification !== undefined) {
            append = specification.id;
        }

        this.props.history.push("/cleaning-specifications/" + append);
    }

    tablePageDidChange = (page) => {
        this.setState({
            currentPage : page
        });

        this.getSpecificationTableDataFromNetwork(undefined, page);
    }

    tableFilterDidChange = (keyword) => {
        this.setState({
            tableKeyword : keyword
        });

        this.getSpecificationTableDataFromNetwork(keyword, undefined);
    }

    findSpecification = (specificationId) => {
        specificationId = parseInt(specificationId);
        for (let i = 0; i < this.state.specifications.length; i++) {
            if (parseInt(this.state.specifications[i].id) === specificationId) {
                return this.state.specifications[i];
            }
        }
        return null;
    }

    getSpecificationMediaCount = (specificationId, mediaTypeId) => {
        let specification = this.findSpecification(specificationId);
        if (specification != null) {
            if (specification.mediaCounts[mediaTypeId] !== undefined) {
                return specification.mediaCounts[mediaTypeId]
            }
        }
        return 0;
    }

    checkSiteArea = (specificationId, siteAreaId) => {
        let specification = this.findSpecification(specificationId);
        if (specification != null) {
            let hasSiteArea = false;

            for (let i = 0; i < specification.siteAreas.length; i++) {
                if (specification.siteAreas[i] === siteAreaId) {
                    hasSiteArea = true;
                    specification.siteAreas.splice(i, 1);
                }
            }

            if (!hasSiteArea) {
                specification.siteAreas.push(siteAreaId);
            }

            this.setState({
                specifications : this.state.specifications
            });
        }
    }

    getSpecificationTableDataFromNetwork = (keyword, page) => {
        if (this.state.specificationNetworkInFlight) return;

        if (keyword === undefined) {
            keyword = this.state.tableKeyword;
        }

        if (page === undefined) {
            page = this.state.currentPage;
        }

        this.setState({
            specificationNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", page);
        if (keyword != null && keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.cleaningSpec.getSpecificationTableData, formData)
            .then((r) => {
                let specifications = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    specifications = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    specificationNetworkInFlight : false,
                    specifications,
                    tableTotal
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later. [D3100]");

                this.setState({
                    specificationNetworkInFlight : false
                });
            });
    }

    submitSpecificationDataOverNetwork = () => {
        if (this.state.specificationNetworkInFlight) return;

        this.setState({
            specificationNetworkInFlight : true
        });

        let data = [];
        this.state.specifications.forEach((specification) => {
            console.log(specification);
            data.push({
                id : specification.id,
                siteAreas : specification.siteAreas
            });
        });

        let formData = new FormData();
        formData.append("data", JSON.stringify(data));

        Axios.post(ENDPOINTS.cleaningSpec.submitCleaningSpecificationConfiguration, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show("Success", "Successfully updated Cleaning Specification configuration", Toast.TYPE_SUCCESS);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    specificationNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later. [D3401]");

                this.setState({
                    specificationNetworkInFlight : false
                });
            });
    }

    getSpecificationConfigurationDataOverNetwork = () => {
        if (this.state.configNetworkInFlight) return;

        this.setState({
            configNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.cleaningSpec.getSpecificationConfigurationTable)
            .then((r) => {
                let siteAreas = [];
                let fileTypes = [];

                let resp = API.parse(r);
                if (resp.success) {
                    siteAreas = resp.data.siteAreas;
                    fileTypes = resp.data.mediaTypes;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    configNetworkInFlight : false,
                    siteAreas,
                    fileTypes
                });
            })
            .catch((e) => {
                console.log(e);
                Toast.show("Error", "Could not load config data. Table may not work correctly.", Toast.TYPE_ERROR);

                this.setState({
                    configNetworkInFlight : false
                });
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        return (
            <div className={"cleaning-specification-configuration-landing-component"}>
                <div className={"row"}>
                    <div className={"col-4"}>
                        <ScreenTitleComponent
                            title={"Cleaning Specifications"}
                            showBack={false}
                            {...this.props} />
                    </div>
                    <div className={"col-4 screen-actions"}>
                        <ScreenSearchComponent
                            callback={this.tableFilterDidChange} />
                    </div>
                    <div className={"col-4 screen-actions text-right"}>
                        <button className={"btn btn-primary"} onClick={() => this.moveToSpecificationDetail()}>Add Specification</button>
                        &nbsp;
                        <button className={"btn btn-success"} onClick={this.submitSpecificationDataOverNetwork}>Save Changes</button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>

                        <div className={"segmented-table-container"}>
                            <div className={"equal-width"}>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        <th><span className={"text"}>Specification Title</span></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.specifications.map((specification) => (
                                            <tr className={"clickable"} onClick={() => this.moveToSpecificationDetail(specification)}><td><span className={"text"}>{specification.title}</span></td></tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className={"centre-width"}>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        {
                                            this.state.siteAreas.map((siteArea) => (
                                                <th className={"text-center"}><span className={"text"}>{siteArea.name}</span></th>
                                            ))
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.specifications.map((specification) => (
                                            <tr>
                                                {
                                                    this.state.siteAreas.map((siteArea) => {
                                                        let checked = false;

                                                        for (let i = 0; i < specification.siteAreas.length; i++) {
                                                            if (specification.siteAreas[i] == siteArea.id) {
                                                                checked = true;
                                                                break;
                                                            }
                                                        }

                                                        return (
                                                            <td className={"text-center"}><span className={"text"}><input type={"checkbox"} checked={checked} onClick={() => this.checkSiteArea(specification.id, siteArea.id)} /></span></td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className={"equal-width"}>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        {
                                            this.state.fileTypes.map((fileType) => (
                                                <th className={"text-center"}><span className={"text"}>{fileType.name}</span></th>
                                            ))
                                        }
                                        <th />
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.specifications.map((specification) => (
                                            <tr>
                                                {
                                                    this.state.fileTypes.map((fileType) => {
                                                        return (
                                                            <td className={"text-center"}>
                                                                <span className={"text"}>
                                                                    <span className={"badge badge-secondary"}>{this.getSpecificationMediaCount(specification.id, fileType.id)}</span>
                                                                </span>
                                                            </td>
                                                        )
                                                    })
                                                }
                                                <td className={"text-center"}>
                                                    <span className={"text"}>
                                                        <span className={"btn btn-primary"} onClick={() => this.moveToSpecificationDetail(specification)}>Edit</span>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.currentPage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.specificationNetworkInFlight || this.state.configNetworkInFlight} />
            </div>
        )
    }
}