import BaseComponent from "../../BaseComponent";
import React from "react";
import DatePicker from "react-datepicker";
import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import {Chronos} from "../../../data/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Bar, Line} from "react-chartjs-2";

import "./ContractorReportingComponent.css";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";
import {DataManager} from "../../../data/DataManager";
import ScreenSearchComponent from "../common/ScreenSearchComponent";
import {Toast} from "../common/ToastManagerComponent";

export default class ContractorReportingComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let user = DataManager.getUser();

        this.initState({
            user,
            contractors : [],
            filterContractorId : -1,
            summaryTableNetworkInFlight : false,
            startDate : Chronos.now().add(-6, Chronos.MONTHS).getDate(),
            endDate : Chronos.now().getDate(),
            contractorIds : [],
            summaryTableData : [],
            siteTableData : [],
            answerTableNetworkInFlight : false,
            answerTableData : [],
            answerCurrentPage : 1,
            answerTableTotal : 0,
            questionAnalysisNetworkInFlight : false,
            questionAnalysisData : [],
            siteChartData : [],
            siteCurrentPage : 1,
            siteTableLimit : 10,
            siteTableTotal : 0,
            regionTableNetworkInFlight : false,
            regionChartNetworkInFlight : false,
            regionTableData : [],
            regionTableTotal : 0,
            regionCurrentPage : 1,
            regionTableLimit : 10,
            selectedRegionalRegionId : -1
        });
    }

    componentDidMount() {
        this.fetchSummaryTableData();
        this.fetchRegionTableData();
        this.fetchSiteTableData();
        this.fetchQuestionAnalysisData();
        this.fetchSiteAnswerReportTableData();
        this.fetchContractorsFromNetwork();
    }

    summaryContractorWasSelected = (contractorId) => {
        this.setState({
            selectedSummaryContractorId : contractorId
        }, () => {
            this.fetchSummaryChartData(contractorId);
        });
    }

    regionWasSelected = (regionId) => {
        this.setState({
            selectedRegionalRegionId : regionId
        }, () => {
            this.fetchRegionalChartData(regionId);
        });
    }

    siteWasSelected = (siteId) => {
        this.setState({
            selectedSiteId : siteId
        }, () => {
            this.fetchSiteChartData(siteId);
        });
    }

    fetchSummaryTableData = () => {
        if (this.state.summaryTableNetworkInFlight) return;
        this.setState({
            summaryTableNetworkInFlight : true
        });

        let contractorIds = [];
        if (parseInt(this.state.filterContractorId) !== -1) {
            contractorIds.push(this.state.filterContractorId);
        }

        let formData = new FormData();
        formData.append("startDate", Chronos.with(this.state.startDate).seconds());
        formData.append("endDate", Chronos.with(this.state.endDate).seconds());
        formData.append("contractorIds", JSON.stringify(contractorIds));
        if (this.state.keyword !== undefined && this.state.keyword !== "") {
            formData.append("keyword", this.state.keyword);
        }

        Axios.post(ENDPOINTS.site.getContractorSummaryTableData, formData)
            .then((r) => {
                let summaryTableData = [];
                let selectedSummaryContractorId = this.state.selectedSummaryContractorId;

                let resp = API.parse(r);
                if (resp.success) {
                    summaryTableData = resp.data.data;

                    if (selectedSummaryContractorId === undefined) {
                        if (resp.data.data.length > 0) {
                            selectedSummaryContractorId = resp.data.data[0].id;
                        }
                    }
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    summaryTableNetworkInFlight : false,
                    summaryTableData,
                    selectedSummaryContractorId
                }, () => {
                    if (this.state.selectedSummaryContractorId !== undefined) {
                        this.fetchSummaryChartData(this.state.selectedSummaryContractorId);
                    }
                });
            })
            .catch((e) => {
                console.log(e);
                // TODO Show error
            });
    }

    fetchSummaryChartData = (contractorId) => {
        if (this.state.summaryChartNetworkInFlight) return;
        this.setState({
            summaryChartNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("startDate", Chronos.with(this.state.startDate).seconds());
        formData.append("endDate", Chronos.with(this.state.endDate).seconds());
        formData.append("contractorId", contractorId);

        Axios.post(ENDPOINTS.site.getContractorSummaryChartData, formData)
            .then((r) => {
                let summaryChartData = [];

                let resp = API.parse(r);
                if (resp.success) {
                    summaryChartData = this.generateSummaryBarDataSet(resp.data.data);
                    console.log(summaryChartData);
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    summaryChartNetworkInFlight : false,
                    summaryChartData
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchRegionTableData = () => {
        if (this.state.regionTableNetworkInFlight) return;
        this.setState({
            regionTableNetworkInFlight : true
        });

        let contractorIds = [];
        if (parseInt(this.state.filterContractorId) !== -1) {
            contractorIds.push(this.state.filterContractorId);
        }

        let formData = new FormData();
        formData.append("startDate", Chronos.with(this.state.startDate).seconds());
        formData.append("endDate", Chronos.with(this.state.endDate).seconds());
        formData.append("contractorIds", JSON.stringify(contractorIds));
        formData.append("page", this.state.regionCurrentPage);
        if (this.state.keyword !== undefined && this.state.keyword !== "") {
            formData.append("keyword", this.state.keyword);
        }

        Axios.post(ENDPOINTS.site.getContractorRegionalTableData, formData)
            .then((r) => {
                let regionTableData = [];
                let regionTableTotal = 0;
                let selectedRegionalRegionId = undefined;

                let resp = API.parse(r);
                if (resp.success) {
                    regionTableData = resp.data.data;
                    regionTableTotal = resp.data.count;

                    if (selectedRegionalRegionId === undefined) {
                        if (resp.data.data.length > 0) {
                            selectedRegionalRegionId = resp.data.data[0].id;
                        }
                    }
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    regionTableNetworkInFlight : false,
                    regionTableData,
                    regionTableTotal,
                    selectedRegionalRegionId
                }, () => {
                    if (this.state.selectedRegionalRegionId !== undefined) {
                        this.fetchRegionalChartData(this.state.selectedRegionalRegionId);
                    }
                });
            })
            .catch((e) => {
                console.log(e);
                // TODO Show error
            });
    }

    fetchRegionalChartData = (regionId) => {
        if (this.state.regionChartNetworkInFlight) return;
        this.setState({
            regionChartNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("startDate", Chronos.with(this.state.startDate).seconds());
        formData.append("endDate", Chronos.with(this.state.endDate).seconds());
        formData.append("regionId", regionId);

        Axios.post(ENDPOINTS.site.getContractorRegionalChartData, formData)
            .then((r) => {
                let regionChartData = [];

                let resp = API.parse(r);
                if (resp.success) {
                    regionChartData = this.generateSummaryBarDataSet(resp.data.data);
                    console.log(regionChartData);
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    regionChartNetworkInFlight : false,
                    regionChartData
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchSiteTableData = () => {
        if (this.state.siteTableNetworkInFlight) return;
        this.setState({
            siteTableNetworkInFlight : true
        });

        let contractorIds = [];
        if (parseInt(this.state.filterContractorId) !== -1) {
            contractorIds.push(this.state.filterContractorId);
        }

        let formData = new FormData();
        formData.append("page", this.state.siteCurrentPage);
        formData.append("startDate", Chronos.with(this.state.startDate).seconds());
        formData.append("endDate", Chronos.with(this.state.endDate).seconds());
        formData.append("contractorIds", JSON.stringify(contractorIds));
        if (this.state.keyword !== undefined && this.state.keyword !== "") {
            formData.append("keyword", this.state.keyword);
        }

        Axios.post(ENDPOINTS.site.getContractorSiteTableData, formData)
            .then((r) => {
                let siteTableData = [];
                let siteTableTotal = 0;
                let selectedSiteId = undefined;

                let resp = API.parse(r);
                if (resp.success) {
                    siteTableData = resp.data.data;
                    siteTableTotal = resp.data.count;
                    if (siteTableData.length > 0) {
                        selectedSiteId = siteTableData[0].id;
                    }
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    siteTableNetworkInFlight : false,
                    siteTableData,
                    siteTableTotal,
                    selectedSiteId
                }, () => {
                    this.fetchSiteChartData(selectedSiteId);
                });
            })
            .catch((e) => {
                console.log(e);
                // TODO Show error
            });
    }

    fetchSiteAnswerReportTableData = () => {
        if (this.state.answerTableNetworkInFlight) return;
        this.setState({
            answerTableNetworkInFlight : true
        });

        let contractorIds = [];
        if (parseInt(this.state.filterContractorId) !== -1) {
            contractorIds.push(this.state.filterContractorId);
        }

        let formData = new FormData();
        formData.append("page", this.state.answerCurrentPage);
        formData.append("startDate", Chronos.with(this.state.startDate).seconds());
        formData.append("endDate", Chronos.with(this.state.endDate).seconds());
        formData.append("contractorIds", JSON.stringify(contractorIds));
        if (this.state.keyword !== undefined && this.state.keyword !== "") {
            formData.append("keyword", this.state.keyword);
        }

        Axios.post(ENDPOINTS.site.getContractorSiteAnswerReportTableData, formData)
            .then((r) => {
                let answerTableData = [];
                let answerTableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    answerTableData = resp.data.data;
                    answerTableTotal = resp.data.count;
                } else {
                    Toast.show("Error", API.formatError(resp), Toast.TYPE_ERROR);
                    console.log(API.formatError(resp));
                }

                this.setState({
                    answerTableNetworkInFlight : false,
                    answerTableData,
                    answerTableTotal
                });
            })
    }

    fetchSiteChartData = (siteId) => {
        if (this.state.siteChartNetworkInFlight) return;
        this.setState({
            siteChartNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("startDate", Chronos.with(this.state.startDate).seconds());
        formData.append("endDate", Chronos.with(this.state.endDate).seconds());
        formData.append("siteId", siteId);

        Axios.post(ENDPOINTS.site.getContractorSiteChartData, formData)
            .then((r) => {
                let siteChartData = [];

                let resp = API.parse(r);
                if (resp.success) {
                    siteChartData = this.generateSummaryBarDataSet(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    siteChartNetworkInFlight : false,
                    siteChartData
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchQuestionAnalysisData = () => {
        if (this.state.questionAnalysisNetworkInFlight) return;
        this.setState({
            questionAnalysisNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("startDate", Chronos.with(this.state.startDate).seconds());
        formData.append("endDate", Chronos.with(this.state.endDate).seconds());
        formData.append("contractorIds", JSON.stringify(this.state.contractorIds));

        Axios.post(ENDPOINTS.site.getContractorQuestionMetricsData, formData)
            .then((r) => {
                let questionAnalysisData = [];

                let resp = API.parse(r);
                if (resp.success) {
                    console.log(resp.data.data);
                    questionAnalysisData = this.generateBarDataset(resp.data.data);
                    console.log(questionAnalysisData);
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    questionAnalysisNetworkInFlight : false,
                    questionAnalysisData
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchContractorsFromNetwork = () => {
        if (this.state.contractorsNetworkInFlight) return;
        this.setState({
            contractorsNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.site.getContractorsForCurrentUser)
            .then((r) => {
                let contractors = [];

                let resp = API.parse(r);
                if (resp.success) {
                    contractors = resp.data.contractors;
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    contractorsNetworkInFlight : false,
                    contractors
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    dateDidChange(type, date) {
        let newState = {};

        if (type === "start") {
            newState.startDate = date;

        } else {
            newState.endDate = date;
        }

        this.setState(newState, () => {
            this.updateTables();
        });
    }

    updateTables = () => {
        this.setState({
            siteCurrentPage : 1,
            regionCurrentPage : 1,
        }, () => {
            this.fetchSummaryTableData();
            this.fetchRegionTableData();
            this.fetchSiteTableData();
            this.fetchSiteAnswerReportTableData();
            this.fetchQuestionAnalysisData();
        });
    }

    generateBarDataset = (reportData) => {
        let data = [];
        let labels = [];
        let backgroundColors = [];

        if (reportData !== undefined) {
            let maxValue = 0;

            for (let x = 0; x < reportData.length; x++) {
                maxValue = Math.max(reportData[x].count, maxValue);

                data.push(reportData[x].count);
                labels.push(reportData[x].questionText);

                if (maxValue > 0) {
                    backgroundColors.push("rgba(204, 0, 0, " + Math.max(0.1, reportData[x].count / maxValue) + ")");
                }
            }
        }

        return {
            datasets : [
                {
                    label : "No. Negative/Neutral Responses",
                    data : data,
                    backgroundColor : backgroundColors
                }
            ],
            labels : labels
        }
    }

    generateSummaryBarDataSet = (summaryData) => {
        let dataSets = [
            {
                label : "Store Check",
                data : [],
                borderColor : "#007bff"
            },
            {
                label : "Contractor Check",
                borderColor: "#17a2b8",
                data : [],
            }
        ];
        let labels = [];

        if (summaryData !== undefined) {
            for (let x = 0; x < summaryData.length; x++) {
                if (summaryData[x].date !== null) {
                    dataSets[0].data.push(this.getOrDefault(summaryData[x], "storePercent", 0));
                    dataSets[1].data.push(this.getOrDefault(summaryData[x], "contractorPercent", 0));

                    let dateLabel = summaryData[x].date;
                    let parts = dateLabel.split("-");
                    labels.push(" week " + parts[1] + ", " + parts[0]);
                }
            }
        }

        return {
            datasets : dataSets,
            labels : labels
        }
    }

    siteTablePageDidChange = (page) => {
        this.setState({
            siteCurrentPage : page
        }, () => {
            this.fetchSiteTableData();
        });
    }

    regionTablePageDidChange = (page) => {
        this.setState({
            regionCurrentPage : page
        }, () => {
            this.fetchRegionTableData();
        });
    }

    tableControlDidChange = (e) => {
        let state = {
            selectedSummaryContractorId : undefined,
            selectedSiteId : undefined,
            contractorChartData : [],
            siteChartData : [],
            siteCurrentPage : 1,
        };
        state[e.target.name] = e.target.value;


        this.setState(state, () => {
            this.updateTables();
        })
    }

    tableKeywordDidChange = (keyword) => {
        this.setState({
            keyword,
            siteCurrentPage : 1,
            selectedSiteId : undefined,
            selectedSummaryContractorId : undefined
        }, () => {
            this.updateTables();
        });
    }

    tableDidRequestFormat = (colData, rowData, renderValue, column) => {
        let outClass = [];

        if (rowData.hasOwnProperty("isTotal")) {
            if (rowData.isTotal) {
                outClass.push("column-bold");
            }
        }

        // if (column.title === "Store Check" || column.title === "Contractor Check" || column.title === "Average") {
        //     let colNum = parseInt(colData);
        //     if (column.title === "Average") {
        //         colNum = this.getTableAverage(rowData);
        //     }
        //
        //     // console.log("COLUMN VALUE: " + column.title + " :: " + rowData.name + " :: " + colData + " :: " + colNum);
        //
        //     if (!isNaN(colNum)) {
        //         if (colNum >= 80) {
        //             // console.log("COLUMN VALUE: GREEN");
        //             outClass.push("column-green");
        //         } else if (colNum >= 70) {
        //             // console.log("COLUMN VALUE: ORANGE");
        //             outClass.push("column-orange");
        //         } else if (colNum > 0) {
        //             // console.log("COLUMN VALUE: RED");
        //             outClass.push("column-red");
        //         }
        //     }
        // }

        let out = "";
        outClass.forEach((className) => {
            if (out !== "") {
                out += " ";
            }
            out += className;
        });

        return out;
    }

    getTableAverage = (row) => {
        let storeScore = parseInt(this.getOrDefault(row, "storePercent", 0));
        let contractorScore = parseInt(this.getOrDefault(row, "contractorPercent", 0));

        let total = (storeScore + contractorScore) / 2;
        if (isNaN(total)) {
            total = 0;
        }

        return total;
    }

    render() {
        let contractorOptions = [];
        if (parseInt(this.state.user.userTypeId) === API.userTypes.ADMINISTRATOR) {
            contractorOptions.push(
                <option value={-1}>All Contractors</option>
            )
        }

        this.state.contractors.forEach((contractor) => {
            contractorOptions.push(
                <option value={contractor.id}>{contractor.name}</option>
            )
        });

        return (
            <div className={"container form-inputs contractor-reporting-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Contractor Report</h3>
                    </div>
                </div>

                <div className={"row table-controls"}>
                    <div className={"col-6 col-md-3"}>
                        <label>Search</label>
                        <ScreenSearchComponent callback={this.tableKeywordDidChange} />
                    </div>

                    <div className={"col-6 col-md-3"}>
                        <label>Show for Contractor:</label>
                        <select className={"form-control"} name={"filterContractorId"} value={this.state.filterContractorId} onChange={this.tableControlDidChange}>
                            {contractorOptions}
                        </select>
                    </div>

                    <div className={"col-6 col-md-3"}>
                        <label>Start Date</label>
                        <DatePicker
                            className={"form-control"}
                            selected={this.state.startDate}
                            onChange={(date) => this.dateDidChange("start", date)}
                            dateFormat={"dd/MM/yyyy"} />
                    </div>

                    <div className={"col-6 col-md-3"}>
                        <label>End Date</label>
                        <DatePicker
                            className={"form-control"}
                            selected={this.state.endDate}
                            onChange={(date) => this.dateDidChange("end", date)}
                            dateFormat={"dd/MM/yyyy"} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Contractor Summary</h3>
                    </div>

                    <div className={"col-12 col-md-6 loading-containable table-container"}>
                        <TableComponent
                            className={"alternating clickable small-table"}
                            data={this.state.summaryTableData}>
                            <Column name={"name"} title={"Contractor"} customFormat={this.tableDidRequestFormat} />
                            <Column name={"storePercent"} title={"Store Check"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} render={(data, row) => {
                                let score = 0;
                                if (data !== null) {
                                    score = data;
                                }
                                return (<span>{score}%</span>)
                            }} />
                            <Column name={"contractorPercent"} title={"Contractor Check"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} render={(data, row) => {
                                let score = 0;
                                if (data !== null) {
                                    score = data;
                                }
                                return (<span>{score}%</span>)
                            }}/>
                            <Column name={"id"} title={"Average"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} render={(data, row) => {
                                let total = this.getTableAverage(row);
                                return <span>{total}%</span>;
                            }} />
                            <Column name={"storeSubmissionCount"} title={"# Store Check Submissions"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} />
                            <Column name={"contractorSubmissionCount"} title={"# Contractor Check Submissions"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} />
                            <Column name={"id"} title={""} className={"text-center column-small"} render={(data, row) => {
                                if (data !== -1) {
                                    if (this.state.selectedSummaryContractorId === data) {
                                        return (<span className={"btn btn-secondary"}>History</span>);
                                    }
                                    return (<span className={"btn btn-primary"} onClick={() => this.summaryContractorWasSelected(data)}>History</span>);
                                }
                                return [];
                            }} />
                        </TableComponent>

                        <UIBlockerComponent shown={this.state.summaryTableNetworkInFlight} containable={true} />
                    </div>

                    <div className={"col-12 col-md-6 loading-containable chart-container"}>
                        <Line
                            data={this.state.summaryChartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                legend : {
                                    display : false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                }
                            }} />

                        <UIBlockerComponent shown={this.state.summaryChartNetworkInFlight} containable={true} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Regional Summary</h3>
                    </div>

                    <div className={"col-12 col-md-6 loading-containable table-container"}>
                        <TableComponent
                            className={"alternating clickable small-table"}
                            data={this.state.regionTableData}>
                            <Column name={"name"} title={"Contractor"} customFormat={this.tableDidRequestFormat} />
                            <Column name={"storePercent"} title={"Store Check"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} render={(data, row) => {
                                let score = 0;
                                if (data !== null) {
                                    score = data;
                                }
                                return (<span>{score}%</span>)
                            }} />
                            <Column name={"contractorPercent"} title={"Contractor Check"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} render={(data, row) => {
                                let score = 0;
                                if (data !== null) {
                                    score = data;
                                }
                                return (<span>{score}%</span>)
                            }}/>
                            <Column name={"id"} title={"Average"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} render={(data, row) => {
                                let total = this.getTableAverage(row);
                                return <span>{total}%</span>;
                            }} />
                            <Column name={"storeSubmissionCount"} title={"# Store Check Submissions"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} />
                            <Column name={"contractorSubmissionCount"} title={"# Contractor Check Submissions"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} />
                            <Column name={"id"} title={""} className={"text-center column-small"} render={(data, row) => {
                                if (data !== -1) {
                                    if (this.state.selectedRegionalRegionId === data) {
                                        return (<span className={"btn btn-secondary"}>History</span>);
                                    }
                                    return (<span className={"btn btn-primary"} onClick={() => this.regionWasSelected(data)}>History</span>);
                                }
                                return [];
                            }} />
                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.regionCurrentPage}
                                totalCount={this.state.regionTableTotal}
                                pageSize={this.state.regionTableLimit}
                                onClick={(page) => this.regionTablePageDidChange(page)} />
                        </div>

                        <UIBlockerComponent shown={this.state.regionTableNetworkInFlight} containable={true} />
                    </div>

                    <div className={"col-12 col-md-6 loading-containable chart-container"}>
                        <Line
                            data={this.state.regionChartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                legend : {
                                    display : false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                }
                            }} />

                        <UIBlockerComponent shown={this.state.regionChartNetworkInFlight} containable={true} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Store List</h3>
                    </div>

                    <div className={"col-12 col-md-6 loading-containable table-container"}>
                        <TableComponent
                            className={"alternating clickable small-table"}
                            data={this.state.siteTableData}>
                            <Column name={"name"} title={"Contractor"} customFormat={this.tableDidRequestFormat} />
                            <Column name={"storePercent"} title={"Store Check"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} render={(data, row) => {
                                let score = 0;
                                if (data !== null) {
                                    score = data;
                                }
                                return (<span>{score}%</span>)
                            }} />
                            <Column name={"contractorPercent"} title={"Contractor Check"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} render={(data, row) => {
                                let score = 0;
                                if (data !== null) {
                                    score = data;
                                }
                                return (<span>{score}%</span>)
                            }}/>
                            <Column name={"id"} title={"Average"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} render={(data, row) => {
                                let total = this.getTableAverage(row);
                                return <span>{total}%</span>;
                            }} />
                            <Column name={"storeSubmissionCount"} title={"# Store Check Submissions"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} />
                            <Column name={"contractorSubmissionCount"} title={"# Contractor Check Submissions"} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} />
                            <Column name={"id"} title={""} className={"text-center column-small"} customFormat={this.tableDidRequestFormat} render={(data, row) => {
                                if (this.state.selectedSiteId === data) {
                                    return (<span className={"btn btn-secondary"}>History</span>);
                                }
                                return (<span className={"btn btn-primary"} onClick={() => this.siteWasSelected(data)}>History</span>);
                            }} />
                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.siteCurrentPage}
                                totalCount={this.state.siteTableTotal}
                                pageSize={this.state.siteTableLimit}
                                onClick={(page) => this.siteTablePageDidChange(page)} />
                        </div>

                        <UIBlockerComponent shown={this.state.siteTableNetworkInFlight} containable={true} />
                    </div>

                    <div className={"col-12 col-md-6 loading-containable chart-container"}>
                        <Line
                            data={this.state.siteChartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                legend : {
                                    display : false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                }
                            }} />

                        <UIBlockerComponent shown={this.state.siteChartNetworkInFlight} containable={true} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Response Summary</h3>
                    </div>

                    <div className={"col-12 col-md-6 loading-containable table-container"}>
                        <TableComponent
                            className={"alternating clickable small-table"}
                            data={this.state.answerTableData}
                            showHeader={false}>
                            <Column name={"name"} title={"Contractor"} />
                            <Column name={"goodCount"} title={"Good Responses"} className={"text-center"} columnClassName={"column-green"} />
                            <Column name={"averageCount"} title={"Average Responses"} className={"text-center"} columnClassName={"column-orange"} />
                            <Column name={"badCount"} title={"Bad Responses"} className={"text-center"} columnClassName={"column-red"} />
                        </TableComponent>

                        <UIBlockerComponent shown={this.state.answerTableNetworkInFlight} containable={true} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Question Analysis</h3>
                    </div>

                    <div className={"col-12 chart-container"}>
                        <Bar
                            data={this.state.questionAnalysisData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                legend : {
                                    display : false
                                },
                                scales: {
                                    xAxes: [{
                                        display: true,
                                        ticks: {
                                            display : false,
                                            beginAtZero: true
                                        }
                                    }],
                                    yAxes: [
                                        {
                                            ticks :{
                                                beginAtZero : true
                                            }
                                        }
                                    ]
                                }
                            }} />
                    </div>
                </div>

            </div>
        )
    }
}