import React from 'react';
import BaseComponent from "../../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";
import {Audit} from "../../../data/Audit";
import ScreenTitleComponent from "../common/ScreenTitleComponent";
import {Chronos} from "../../../data/Chronos";

export default class AuditSubmissionDecisionComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let mode = Audit.TYPE.CLEANING_CHECK;
        if (this.props !== undefined) {
            if (this.props.match !== undefined) {
                if (this.props.match.params !== undefined) {
                    if (this.props.match.params.mode !== undefined) {
                        mode = parseInt(this.props.match.params.mode);
                    }
                }
            }
        }

        this.initState({
            discardNetworkInFlight : false,
            createAuditNetworkInFlight : false,
            submission : DataManager.getCurrentSubmission(),
            mode : mode
        });
    }

    componentDidMount() {
        window._epShowSiteChangeButton(false);
    }

    componentWillUnmount() {
        window._epShowSiteChangeButton(true);
    }

    discardSubmissionAndStartAnew = () => {
        this.props.showModal("Confirmation", "Are you sure you want to start a new submission? The previous submission will be discarded and all answers will be lost. This action cannot be undone", [
            { label : "Discard", className : "danger", click : () => { this.discardSubmissionOverNetwork(); this.props.hideModal(); }},
            { label : "Cancel", click : () => { this.props.hideModal() }}
        ]);
    }

    discardSubmissionOverNetwork = () => {
        if (this.state.discardNetworkInFlight) return;

        this.setState({
            discardNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("auditSubmissionId", this.state.submission.id);

        Axios.post(ENDPOINTS.audit.discardAudit, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let siteId = this.state.submission.siteId;
                    let siteAreaId = this.state.submission.siteAreaId;

                    DataManager.setCurrentSubmission(null);

                    this.createSubmissionOverNetwork(siteId, siteAreaId);
                } else {
                    this.showError(API.formatError(resp));
                }
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Could not discard previous submission.");
            });
    }

    createSubmissionOverNetwork = (siteId, siteAreaId) => {
        if (this.state.createAuditNetworkInFlight) return;

        this.setState({
            createAuditNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("siteId", siteId);
        formData.append("siteAreaId", siteAreaId);

        Axios.post(ENDPOINTS.audit.createAudit, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.auditSubmission != null) {
                        DataManager.setCurrentSubmission(resp.data.auditSubmission);

                        this.moveToAuditStart();
                    }
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    createAuditNetworkInFlight : false
                });
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Please reload the page. [A100]");

                this.setState({
                    createAuditNetworkInFlight : false
                });
            });
    }

    moveToAuditStart = () => {
        let destination = "/cleaning-checks";
        if (this.state.mode === Audit.TYPE.INSPECTION) {
            destination = "/site-inspections";
        }

        this.props.history.push(destination);
    }

    showError = (message) => {
        if (this.props.hasOwnProperty("showModal")) {
            this.props.showModal("Error", message);
        }
    }

    render() {
        let auditVerbiage = "Check";
        if (this.state.mode === Audit.TYPE.INSPECTION) {
            auditVerbiage = "Inspection";
        }

        return (
            <div className={"container audit-submission-decision-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent title={"Finish unfinished " + auditVerbiage + "?"} history={this.props.history} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 text-center form-block"}>
                        <p>The last {auditVerbiage} you started is still unfinished. You started the {auditVerbiage} at:</p>
                        <p>{Chronos.withTimestampSeconds(this.state.submission.dateCreated).format("dd/MM/yyyy HH:mm")}</p>
                        <p>Would you like to continue this {auditVerbiage} or start a new one?</p>
                        <p>
                            <div className="alert alert-warning" role="alert">
                                <p><strong>Warning</strong></p>
                                <p>If you start a new {auditVerbiage}, the previous unfinished {auditVerbiage} will be discarded.</p>
                            </div>
                        </p>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"hidden-xs, col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <button className={"btn btn-success btn-rounded btn-fullwidth"} onClick={this.moveToAuditStart}>Continue Previous {auditVerbiage}</button>
                        <button className={"btn btn-warning btn-rounded btn-fullwidth form-block"} onClick={this.discardSubmissionAndStartAnew}>Start a New {auditVerbiage}</button>
                    </div>
                </div>
            </div>
        );
    }

}