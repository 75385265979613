import React from 'react';
import BaseComponent from "../../BaseComponent";

import "./SiteConfigurationAlertComponent.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import UploadProgressComponent from "../../app/common/UploadProgressComponent";
import {Toast} from "../common/ToastManagerComponent";

export default class SiteConfigurationAlertComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            alertNetworkInFlight : false,
            alert : null,
            uploadFile : null,
            imagePreview : null,
            progressOpen : false,
            progressTitle : null,
            progressMessage : null,
            progressProgress : 0
        });

        this.imageFile = React.createRef();
    }

    imageSelectionWasClicked = () => {
        this.imageFile.current.click();
    }

    imageFileDidChange = (event) => {
        if (this.imageFile.current.files.length > 0) {
            let file = this.imageFile.current.files[0];

            let reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    imagePreview : reader.result
                });
            }
            reader.readAsDataURL(file);

            this.setState({
                uploadFile : file
            });
        }
    }

    submitAlertOverNetwork = () => {
        if (this.state.alertNetworkInFlight) return;

        this.setState({
            alertNetworkInFlight : true
        });

        let validationResult = this.validateCreateFormData(this.state, [
            { key : "alertTitle", type : "string", label : "Alert Title", postName : "title" },
            { key : "alertMessage", type : "string", label : "Alert Content", postName : "message" }
        ]);

        if (!validationResult.success) {
            this.showError(validationResult.error);
            return;
        }

        let formData = validationResult.formData;
        if (this.props.site !== undefined) {
            formData.append("siteId", this.props.site.id);
        }
        if (this.state.alert != null) {
            formData.append("id", this.state.alert.id);
        }

        this.showProgress("Submitting Alert", "Please wait...");

        Axios.post(ENDPOINTS.alerts.submitAlert, formData)
            .then((r) => {
                let alert = null;

                let resp = API.parse(r);
                if (resp.success) {
                    alert = resp.data.alert;

                    this.uploadImageOverNetwork(alert.id);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    alertNetworkInFlight : false,
                    alert
                });
            })
            .catch((e) => {
                console.log(e);

                this.uploadDidFinish(false);
            });
    }

    uploadImageOverNetwork = (alertId) => {
        if (this.state.uploadFile === null) {
            this.uploadDidFinish();
            return;
        }

        if (this.state.alertImageNetworkInFlight) return;

        this.setState({
            alertImageNetworkInFlight : true
        });

        let config = {
            onUploadProgress: (progressEvent) => {
                let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                // This progress will consider ALL images that need to be uploaded.
                let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);

                this.notifyProgress(progress);
            }
        }

        let formData = new FormData();
        formData.append("alertId", alertId);
        formData.append("image", this.state.uploadFile);

        Axios.post(ENDPOINTS.alerts.uploadAlertImage, formData, config)
            .then((r) => {
                let uploadFile = this.state.uploadFile;

                let resp = API.parse(r);
                if (resp.success) {
                    uploadFile = null;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    alertImageNetworkInFlight : false,
                    uploadFile
                });

                this.uploadDidFinish();
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    alertImageNetworkInFlight : false
                })

                this.uploadDidFinish();
            });
    }

    uploadDidFinish = (result) => {
        this.dismissProgress();

        if (result === undefined) {
            result = true;
        }

        if (result) {
            Toast.show("Success", "Alert has been sent", Toast.TYPE_SUCCESS);
        } else {
            Toast.show("Error", "There was a problem sending Alert", Toast.TYPE_ERROR);
        }
    }

    showProgress = (title, message) => {
        this.setState({
            progressOpen : true,
            progressTitle : title,
            progressMessage : message,
            progressProgress : 0
        });
    }

    dismissProgress = () => {
        this.setState({
            progressOpen : false
        });
    }

    notifyProgress = (progress) => {
        this.setState({
            progressProgress : progress
        });
    }



    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {

        return (
            <div className={"container form-inputs site-configuration-alert-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Send Store Alert</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Alert Title</label>
                        <input type={"text"} className={"form-control"} name={"alertTitle"} value={this.state.alertTitle} onChange={this.handleChange} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-6 col-lg-8"}>
                        <label>Alert Content</label>
                        <textarea className={"form-control alert-content"} name={"alertMessage"} value={this.state.alertMessage} onChange={this.handleChange} />
                    </div>

                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label>Attach an image (optional)</label>
                                <div className={"embed-responsive embed-responsive-1by1 image-preview"} style={{backgroundImage : "url(" + this.state.imagePreview + ")"}} />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                <button className={"btn btn-primary"} onClick={this.imageSelectionWasClicked}>Select Image</button>
                                <div className={"file-hide"}>
                                    <input type={"file"} ref={this.imageFile} onChange={this.imageFileDidChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row submit-area"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-success"} onClick={this.submitAlertOverNetwork}>Send Alert</button>
                    </div>
                </div>

                <UploadProgressComponent
                    open={this.state.progressOpen}
                    title={this.state.progressTitle}
                    message={this.state.progressMessage}
                    progress={this.state.progressProgress} />

            </div>
        )
    }
}