import React from 'react';
import BaseComponent from "../../BaseComponent";
import {Doughnut, HorizontalBar} from "react-chartjs-2";import UIBlockerComponent from "../../app/common/UIBlockerComponent";

import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Chronos} from "../../../data/Chronos";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {Audit} from "../../../data/Audit";

import "./SiteConfigurationStatisticsVerticalGraphComponent.css";

export default class SiteConfigurationStatisticsVerticalGraphComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let startDate = Chronos.now().add(-1, Chronos.WEEKS).getDate();
        let endDate = Chronos.now().getDate();
        if (this.props.latestOnly !== undefined && this.props.latestOnly) {
            startDate = null;
            endDate = null;
        }


        this.initState({
            currentScore : "--",
            gaugeData : [],
            barData : [],
            reportDataInFlight : false,
            startDate,
            endDate
        });
    }

    componentDidMount() {
        this.fetchAuditSummaryData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.updateTime !== undefined) {
            let previousForceUpdate = prevProps.updateTime;
            if (previousForceUpdate === undefined || this.props.updateTime > prevProps.updateTime) {
                this.fetchAuditSummaryData();
            }
        }
    }

    generateGaugeDataset = (percentage) => {
        let data = [
            percentage,
            100 - percentage
        ];

        return {
            datasets:[
                {
                    data: data,
                    backgroundColor: ["#59c134", "#c13434"],
                    hoverBackgroundColor: ["#59c134", "#c13434"]
                }
            ],
            labels: ["Good or Average", "Bad"],
        }
    }

    generateBarDataset = (reportData) => {
        let data = [];
        let labels = [];
        let backgroundColours = [];

        if (reportData !== undefined) {
            for (let x = 0; x < reportData.length; x++) {
                if (reportData[x].value > 0) {
                    data.push(reportData[x].value);
                    labels.push(reportData[x].label);
                    backgroundColours.push("#59c134");
                }
            }
        }

        return {
            datasets : [
                {
                    data : data,
                    backgroundColor : backgroundColours
                }
            ],
            labels : labels
        }
    }

    fetchAuditSummaryData = () => {
        if (this.state.reportDataInFlight) return;

        this.setState({
            reportDataInFlight : true
        });

        let startDate = "0";
        let endDate = "0";
        if (this.state.startDate != null) {
            startDate = Chronos.with(this.state.startDate).seconds();
            endDate = Chronos.with(this.state.endDate).seconds();
        }

        let formData = new FormData();
        formData.append("siteId", this.props.siteId);
        formData.append("auditTypeId", this.props.auditTypeId);
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);

        Axios.post(ENDPOINTS.audit.getSiteStatisticsAuditReportData, formData)
            .then((r) => {
                let gaugeData = {};
                let barData = {};
                let currentScore = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    currentScore = Math.round(resp.data.totalPercentage).toFixed(0);
                    gaugeData = this.generateGaugeDataset(resp.data.totalPercentage);
                    barData = this.generateBarDataset(resp.data.reportData);
                } else {
                    // TODO Error
                }

                this.setState({
                    reportDataInFlight : false,
                    currentScore,
                    gaugeData,
                    barData
                });
            })
            .catch((e) => {
                // TODO Error

                this.setState({
                    reportDataInFlight : false
                });
            });
    }

    showError = (message) => {
        console.log(message);
    }

    onDateSelected = (type, date) => {
        console.log(date);

        if (type === "start") {
            this.setState({
                startDate : date
            })
        } else {
            this.setState({
                endDate : date
            });
        }
    }

    displayDatePopout = () => {
        this.setState({
            showDatePickerPopout : true
        });
    }

    hideDatePopout = () => {
        this.setState({
            showDatePickerPopout : false
        });

        this.fetchAuditSummaryData();
    }

    getStoreCheckLabel = () => {
        if (process.env.REACT_APP_LOGIN_STORE_CHECK !== undefined) {
            return process.env.REACT_APP_LOGIN_STORE_CHECK;
        }
        return "Store Check";
    }

    render() {
        let titleLabel = this.getStoreCheckLabel();
        if (this.props.auditTypeId === Audit.TYPE.INSPECTION) {
            titleLabel = "Management Inspection";
        } else if (this.props.auditTypeId === Audit.TYPE.CONTRACTOR) {
            titleLabel = "Contractor Inspection";
        }

        let datePickerPopoutVisibility = "none";
        if (this.state.showDatePickerPopout) {
            datePickerPopoutVisibility = "block";
        }

        let headerElems = [];
        if (this.props.latestOnly !== undefined && this.props.latestOnly) {
            headerElems.push(
                <div className={"statistic-title"}>Latest {titleLabel} Results</div>
            );
        } else {
            headerElems.push(
                <div className={"statistic-title"}>Show {titleLabel} Results for</div>
            );
            headerElems.push(
                <div className={"date-picker-container"}>

                    <div className={"date-display"} onClick={this.displayDatePopout}>
                        {Chronos.with(this.state.startDate).format("dd/MM/yyyy")} - {Chronos.with(this.state.endDate).format("dd/MM/yyyy")}
                    </div>

                    <div className={"date-picker-popout"} style={{"display" : datePickerPopoutVisibility}}>
                        <span className={"date-picker-title"}>Change Reporting Period</span>

                        <label>Start Date</label>
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={(date) => this.onDateSelected("start", date)}
                            dateFormat={"dd/MM/yyyy"} />

                        <label>End Date</label>
                        <DatePicker
                            selected={this.state.endDate}
                            onChange={(date) => this.onDateSelected("end", date)}
                            dateFormat={"dd/MM/yyyy"} />

                        <button className={"btn btn-success fullwidth"} onClick={this.hideDatePopout}>Apply</button>
                    </div>

                </div>
            )
        }

        return (
            <div className={"statistic-vertical card"}>
                {headerElems}

                <div className={"gauge-chart"}>
                    <Doughnut
                        data={this.state.gaugeData}
                        options={{
                            cutoutPercentage: 70,
                            legend : {
                                display : false
                            },
                            rotation: Math.PI,
                            circumference: Math.PI
                        }} />

                    <div className={"overlay-display"}>
                        <div className={"title"}>{this.state.currentScore}%</div>
                        <div className={"subtitle"}>Percentage of good or average results</div>
                    </div>
                </div>

                <div className={"bar-chart"}>
                    <HorizontalBar
                        data={this.state.barData}
                        options={{
                            legend : {
                                display : false
                            },
                            scales: {
                                xAxes: [{
                                    display: true,
                                    ticks: {
                                        suggestedMax: 100,
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }} />
                </div>

                <UIBlockerComponent
                    shown={this.state.reportDataInFlight} />

            </div>
        )
    }
}