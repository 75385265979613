import BaseComponent from "../../BaseComponent";

import "./AuditQuestionSiteVisibilityComponent.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import LoadingSpinner from "../../common/LoadingSpinner";

export default class AuditQuestionSiteVisibilityComponent extends BaseComponent {


    constructor(props, context) {
        super(props, context);

        this.initState({
            sites : [],
            visibility : [],
            keyword : "",
            filterVisibility : -1
        });
    }

    componentDidMount() {
        if (this.props.auditQuestionId !== undefined) {
            this.fetchSitesFromNetwork();
            this.fetchVisibilityFromNetwork();
        }
    }

    findVisibilityForSite = (siteId) => {
        let out = 1;

        siteId = parseInt(siteId);

        if (this.state.visibility !== null) {
            for (let i = 0; i < this.state.visibility.length; i++) {
                if (parseInt(this.state.visibility[i].siteId) === siteId) {
                    out = parseInt(this.state.visibility[i].visibility);
                    break;
                }
            }
        }

        return out;
    }

    setVisibilityForSite = (siteId, value) => {
        siteId = parseInt(siteId);

        if (this.state.visibility !== null) {
            let visibility = this.state.visibility;

            let hasExisting = false;

            for (let i = 0; i < visibility.length; i++) {
                if (parseInt(visibility[i].siteId) === siteId) {
                    visibility[i].visibility = value;
                    hasExisting = true;
                    break;
                }
            }

            if (!hasExisting) {
                let newVisibility = {
                    siteId : siteId,
                    visibility : value
                };
                visibility.push(newVisibility)
            }

            this.setState({
                visibility
            }, () => {
                if (this.props.callback !== undefined) {
                    this.props.callback(visibility);
                }
            });
        }
    }

    onCheckChange = (e, siteId, value) => {
        let checked = e.target.checked;
        if (checked) {
            this.setVisibilityForSite(siteId, value);
        }
    }

    fetchSitesFromNetwork = () => {
        if (this.state.sitesNetworkInFlight) return;
        this.setState({
            sitesNetworkInFlight : true
        });

        let formData = new FormData();

        Axios.post(ENDPOINTS.site.searchSites, formData)
            .then((r) => {
                let sites = [];

                let resp = API.parse(r);
                if (resp.success) {
                    sites = resp.data.sites;
                } else {
                    // TODO Error?
                }

                this.setState({
                    sitesNetworkInFlight : false,
                    sites
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    sitesNetworkInFlight : false
                });
            });
    }

    fetchVisibilityFromNetwork = () => {
        if (this.state.visibilityNetworkInFlight) return;
        this.setState({
            visibilityNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("auditQuestionId", this.props.auditQuestionId);

        Axios.post(ENDPOINTS.audit.getSiteAuditQuestionVisibility, formData)
            .then((r) => {
                let visibility = [];

                let resp = API.parse(r);
                if (resp.success) {
                    visibility = resp.data.siteAuditQuestionVisibility;
                } else {
                    // TODO Error?
                }

                this.setState({
                    visibilityNetworkInFlight : false,
                    visibility
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    visibilityNetworkInFlight : false
                });
            });
    }

    render() {
        let content = [];

        if (this.state.sitesNetworkInFlight || this.state.visibilityNetworkInFlight) {
            content = (
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <LoadingSpinner shown={true} />
                    </div>
                </div>
            );
        } else {
            for (let i = 0; i < this.state.sites.length; i++) {
                let shouldInclude = true;

                let site = this.state.sites[i];

                if (this.state.keyword !== "") {
                    if (!site.name.toLowerCase().includes(this.state.keyword.toLowerCase())) {
                        shouldInclude = false;
                    }
                }

                if (shouldInclude) {
                    let visibility = this.findVisibilityForSite(site.id);

                    if (parseInt(this.state.filterVisibility) !== -1) {
                        if (visibility !== parseInt(this.state.filterVisibility)) {
                            shouldInclude = false;
                        }
                    }

                    if (shouldInclude) {
                        content.push((
                            <div className={"ep-list-group-item"}>
                                <div className={"site-name"}>
                                    {site.name}
                                </div>
                                <div className={"site-options"}>
                                    <label><input type={"radio"} checked={visibility === 1} onChange={(e) => this.onCheckChange(e, site.id, 1)}/> Visible</label>
                                    <label><input type={"radio"} checked={visibility === 0} onChange={(e) => this.onCheckChange(e, site.id, 0)}/> Not Visible</label>
                                </div>
                            </div>
                        ));
                    }
                }
            }
        }

        return (
            <div className={"audit-question-site-visibility-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className="ep-list-group">
                            <div className={"ep-filter-area"}>
                                <div className={"half-block"}>
                                    <label>Search Assets</label>
                                    <input type={"text"} className={"form-control"} name={"keyword"} onChange={this.handleChange} placeholder={"Filter Assets"} />
                                </div>

                                <div className={"half-block"}>
                                    <label>Visibility</label>
                                    <select className={"form-control"} name={"filterVisibility"} onChange={this.handleChange}>
                                        <option value={-1}>All</option>
                                        <option value={1}>Visible</option>
                                        <option value={0}>Not Visible</option>
                                    </select>
                                </div>
                            </div>

                            <div className={"ep-list-scrolling-area"}>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}