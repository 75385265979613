import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import {CompactPicker} from "react-color";

import "./SettingsSiteAreaEditorComponent.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../common/ToastManagerComponent";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";
import UploadProgressComponent from "../../app/common/UploadProgressComponent";

export default class SettingsSiteAreaEditorComponent extends BaseComponent {

    uploadFile = null;

    constructor(props, context) {
        super(props, context);

        let siteAreaId = this.getUriProperty("id", null);
        if (siteAreaId != null) {
            if (siteAreaId === "new") {
                siteAreaId = null;
            }
        }

        this.initState({
            siteAreaNetworkInFlight : false,
            siteAreaId,
            siteArea : null,
            siteAreaIconImagePath : null
        });

        this.fileInput = React.createRef();
    }

    componentDidMount() {
        if (this.state.siteAreaId != null) {
            this.getSiteAreaFromNetwork();
        }
    }

    colourDidChange = (type, colour) => {
        if (type === "text") {
            this.setState({
                siteAreaTextColour : colour.hex
            });
        } else {
            this.setState({
                siteAreaBackgroundColour : colour.hex
            });
        }
    }

    requestFile = () => {
        this.fileInput.current.click();
    }

    fileWasChanged = () => {
        if (this.fileInput.current.files.length > 0) {
            let file = this.fileInput.current.files[0];

            this.uploadFile = file;

            let reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    siteAreaIconImagePath : reader.result
                });
            }
            reader.readAsDataURL(file);
        }
    }

    populateSiteAreaIntoState = (siteArea) => {
        if (siteArea != null) {
            this.setState({
                siteArea : siteArea,
                siteAreaName : siteArea.name,
                siteAreaTextColour : siteArea.textColour,
                siteAreaBackgroundColour : siteArea.backgroundColour,
                siteAreaIconImagePath : siteArea.publicFullPath
            });
        }
    }

    getSiteAreaFromNetwork = () => {
        if (this.state.siteAreaNetworkInFlight) return;

        this.setState({
            siteAreaNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("siteAreaId", this.state.siteAreaId);

        Axios.post(ENDPOINTS.site.getSiteArea, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateSiteAreaIntoState(resp.data.siteArea);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    siteAreaNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later. [D870]")
                this.setState({
                    siteAreaNetworkInFlight : false
                });
            });
    }

    submitSiteAreaOverNetwork = () => {
        if (this.state.siteAreaSubmitNetworkInFlight) return;

        let validationResult = this.validateCreateFormData(this.state, [
            { key : "siteAreaName", type : "string", label : "Asset Area Name", postName : "name" },
            { key : "siteAreaTextColour", type : "string", label : "Text Colour", postName : "textColour" },
            { key : "siteAreaBackgroundColour", type : "string", label : "Background Colour", postName : "backgroundColour" },
            { key : "siteAreaActive", type : "boolean", label: "Active", postName: "active", optional : true, required : false }
        ]);

        if (!validationResult.success) {
            this.showError(validationResult.error);
            return;
        }

        this.setState({
            siteAreaSubmitNetworkInFlight : true,
            progressTitle : "Please wait",
            progressMessage : "Uploading Asset Area...",
            progressProgress : 0
        });

        let formData = validationResult.formData;
        if (this.state.siteArea != null) {
            formData.append("id", this.state.siteArea.id);
        }

        Axios.post(ENDPOINTS.site.submitSiteArea, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.siteArea.active == 1) {
                        this.populateSiteAreaIntoState(resp.data.siteArea);
                        this.uploadSiteAreaImage(resp.data.siteArea.id);
                    } else {
                        Toast.show("Asset Area Deleted", "Successfully deleted Asset Area", Toast.TYPE_SUCCESS);
                        this.props.history.goBack();
                    }
                } else {
                    this.showError(API.formatError(resp));

                    this.setState({
                        siteAreaSubmitNetworkInFlight : false
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later.");
                this.setState({
                    siteAreaSubmitNetworkInFlight : false
                });
            });
    }

    uploadSiteAreaImage = (siteAreaId,) => {
        if (this.uploadFile != null) {
            this.setState({
                siteAreaSubmitNetworkInFlight : true,
                progressTitle : "Uploading Image",
                progressMessage : "Uploading image, please wait..."
            });

            let uploadFile = this.uploadFile;
            if (uploadFile != null) {
                let config = {
                    onUploadProgress: (progressEvent) => {
                        let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                        // This progress will consider ALL images that need to be uploaded.
                        let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);

                        this.setState({
                            progressProgress : progress
                        });
                    }
                }

                let formData = new FormData();
                formData.append("siteAreaId", siteAreaId);
                formData.append("siteAreaImageTypeId", "2"); // Icon
                formData.append("image", uploadFile);

                Axios.post(ENDPOINTS.site.uploadSiteAreaImage, formData, config)
                    .then((r) => {
                        let resp = API.parse(r);
                        if (resp.success) {
                            this.populateSiteAreaIntoState(resp.data.siteArea);
                        } else {
                            this.showError(API.formatError(resp));
                        }

                        this.completeSiteAreaSubmission();
                    })
                    .catch((e) => {
                        console.log(e);
                        this.showError("An unknown error has occurred uploading an image");
                    });
            } else {
                this.completeSiteAreaSubmission();
            }
        } else {
            this.completeSiteAreaSubmission();
        }
    }

    completeSiteAreaSubmission = () => {
        Toast.show("Success", "Successfully updated Asset Area", Toast.TYPE_SUCCESS);

        this.setState({
            siteAreaSubmitNetworkInFlight : false,
            progressTitle : "",
            progressMessage : "",
            progressProgress : 0
        });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    siteAreaDeleteWasRequested = () => {
        this.props.showModal(
            "Confirm Deletion",
            "Are you sure you want to delete this Asset Area?",
            [
                {
                    label: "Confirm Deletion",
                    className : "danger",
                    click : () => {
                        this.props.hideModal();
                        this.setState({
                            siteAreaActive : 0
                        }, () => {
                            this.submitSiteAreaOverNetwork();
                        });
                    }
                },
                {
                    label : "Cancel",
                    click : () => {
                        this.props.hideModal();
                    }
                }
            ]
        )
    }

    render() {
        let deleteButton = [];
        if (this.state.siteArea !== undefined && this.state.siteArea !== null) {
            deleteButton = (
                <button className={"btn btn-danger"} onClick={this.siteAreaDeleteWasRequested}>Delete</button>
            )
        }

        return (
            <div className={"container form-inputs settings-site-area-editor-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent
                            title={"Asset Area"}
                            {...this.props} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Asset Area Name</label>
                        <input type={"text"} className={"form-control"} name={"siteAreaName"} value={this.state.siteAreaName} onChange={this.handleChange} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"hidden-xs col-md-1"} />
                    <div className={"col-12 col-md-2 text-center"}>
                        <label>Icon</label>
                        <div className={"embed-responsive embed-responsive-1by1 image-preview"} style={{backgroundImage : "url(" + this.state.siteAreaIconImagePath + ")"}} />
                        <button className={"btn btn-primary"} onClick={this.requestFile}>Select Icon</button>
                        <div className={"file-hide"}>
                            <input type={"file"} ref={this.fileInput} onChange={this.fileWasChanged} />
                        </div>
                    </div>
                    <div className={"hidden-xs col-md-1"} />

                    <div className={"col-12 col-md-4"}>
                        <label>Text Colour</label>
                        <div>
                            <CompactPicker
                                color={this.state.siteAreaTextColour}
                                onChange={(colour) => this.colourDidChange("text", colour)} />
                        </div>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <label>Background Colour</label>
                        <div>
                            <CompactPicker
                                color={this.state.siteAreaBackgroundColour}
                                onChange={(colour) => this.colourDidChange("background", colour)} />
                        </div>
                    </div>
                </div>

                <div className={"row submit-area"}>
                    <div className={"col-12 col-md-6"}>
                        <button className={"btn btn-success"} onClick={this.submitSiteAreaOverNetwork}>Save Changes</button>
                    </div>
                    <div className={"col-12 col-md-6 text-right"}>
                        {deleteButton}
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.siteAreaNetworkInFlight} />

                    <UploadProgressComponent
                        open={this.state.siteAreaSubmitNetworkInFlight}
                        title={this.state.progressTitle}
                        message={this.state.progressMessage}
                        progress={this.state.progressProgress} />
            </div>
        )
    }

}