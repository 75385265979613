import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import ScreenSearchComponent from "../common/ScreenSearchComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {TablePaginator} from "../../common/TableComponent";
import {Toast} from "../common/ToastManagerComponent";

import "./AuditConfiguratorLandingComponent.css";

export default class AuditConfiguratorLandingComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            currentPage : 1,
            tableKeyword : "",
            questions : [],
            siteAreas : [],
            userTypes : [],
            tableTotal : 0,
            tableLimit : 10
        });
    }

    componentDidMount() {
        this.getAuditQuestionsFromNetwork();
        this.getConfigurationFromNetwork();
    }

    moveToQuestionDetail = (question) => {
        let append = "new";
        if (question !== undefined) {
            append = question.id;
        }

        this.props.history.push("/audits/question/" + append);
    }

    findQuestion = (questionId) => {
        for (let i = 0; i < this.state.questions.length; i++) {
            if (this.state.questions[i].id == questionId) {
                return this.state.questions[i];
            }
        }
        return null;
    }

    checkSiteArea = (questionId, siteAreaId) => {
        let question = this.findQuestion(questionId);
        if (question != null) {
            let hasSiteArea = false;

            for (let i = 0; i < question.siteAreas.length; i++) {
                if (question.siteAreas[i] == siteAreaId) {
                    hasSiteArea = true;
                    question.siteAreas.splice(i, 1);
                    break;
                }
            }

            if (!hasSiteArea) {
                question.siteAreas.push(siteAreaId);
            }

            this.setState({
                questions : this.state.questions
            });
        }
    }

    checkUserType = (questionId, userTypeId) => {
        let question = this.findQuestion(questionId);
        if (question != null) {
            let hasUserType = false;

            for (let i = 0; i < question.userTypes.length; i++) {
                if (question.userTypes[i] == userTypeId) {
                    hasUserType = true;
                    question.userTypes.splice(i, 1);
                    break;
                }
            }

            if (!hasUserType) {
                question.userTypes.push(userTypeId);
            }

            this.setState({
                questions : this.state.questions
            });
        }
    }

    tablePageDidChange = (page) => {
        this.setState({
            currentPage : page
        });

        this.getAuditQuestionsFromNetwork(undefined, page);
    }

    tableFilterDidChange = (keyword) => {
        this.setState({
            currentPage : 1,
            tableKeyword : keyword
        });

        this.getAuditQuestionsFromNetwork(keyword, 1);
    }

    getAuditQuestionsFromNetwork = (keyword, page) => {
        if (this.state.questionNetworkInFlight) return;

        if (keyword === undefined) {
            keyword = this.state.tableKeyword;
        }

        if (page === undefined) {
            page = this.state.currentPage;
        }

        this.setState({
            questionNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", page);
        if (keyword != null && keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.audit.getAuditQuestionsAdmin, formData)
            .then((r) => {
                let questions = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    questions = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    questionNetworkInFlight : false,
                    questions,
                    tableTotal
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please reload the page. [D2100]");

                this.setState({
                    questionNetworkInFlight : false
                });
            });
    }

    getConfigurationFromNetwork = () => {
        if (this.state.configNetworkInFlight) return;

        this.setState({
            configNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.audit.getAuditQuestionConfigurationData)
            .then((r) => {
                let siteAreas = [];
                let userTypes = [];

                let resp = API.parse(r);
                if (resp.success) {
                    siteAreas = resp.data.siteAreas;
                    userTypes = resp.data.userTypes;
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    configNetworkInFlight : true,
                    siteAreas,
                    userTypes
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    configNetworkInFlight : false
                });
            });
    }

    submitQuestionDataOverNetwork = () => {
        if (this.state.questionNetworkInFlight) return;

        this.setState({
            questionNetworkInFlight : true
        });

        let data = [];

        for (let i = 0; i < this.state.questions.length; i++) {
            let question = this.state.questions[i];

            data.push({
                id : question.id,
                siteAreas : question.siteAreas,
                userTypes : question.userTypes
            });
        }

        let formData = new FormData();
        formData.append("data", JSON.stringify(data));

        Axios.post(ENDPOINTS.audit.submitAuditQuestionConfiguration, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show("Success", "Successfully updated Audit Configuration", Toast.TYPE_SUCCESS);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    questionNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later.");
                this.setState({
                    questionNetworkInFlight : false
                });
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {

        return (
            <div className={"container-flex audit-configurator-landing-component"}>
                <div className={"row"}>
                    <div className={"col-4"}>
                        <ScreenTitleComponent
                            title={"Audit Configurator"}
                            showBack={false}
                            {...this.props} />
                    </div>
                    <div className={"col-4 screen-actions"}>
                        <ScreenSearchComponent
                            callback={this.tableFilterDidChange} />
                    </div>
                    <div className={"col-4 screen-actions text-right"}>
                        <button className={"btn btn-primary"} onClick={() => this.moveToQuestionDetail()}>Add Question</button>
                        &nbsp;
                        <button className={"btn btn-success"} onClick={this.submitQuestionDataOverNetwork}>Save Changes</button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>

                        <div className={"segmented-table-container"}>
                            <div className={"equal-width"}>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        <th><span className={"text"}>Question</span></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.questions.map((question) => (
                                            <tr className={"clickable"} onClick={() => this.moveToQuestionDetail(question)}><td><span className={"text"}>{question.questionText}</span></td></tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className={"centre-width"}>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        {
                                            this.state.siteAreas.map((siteArea) => (
                                                <th className={"text-center"}><span className={"text"}>{siteArea.name}</span></th>
                                            ))
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.questions.map((question) => (
                                            <tr>
                                                {
                                                    this.state.siteAreas.map((siteArea) => {
                                                        let checked = false;

                                                        for (let i = 0; i < question.siteAreas.length; i++) {
                                                            if (question.siteAreas[i] == siteArea.id) {
                                                                checked = true;
                                                                break;
                                                            }
                                                        }

                                                        return (
                                                            <td className={"text-center"}><span className={"text"}><input type={"checkbox"} checked={checked} onClick={() => this.checkSiteArea(question.id, siteArea.id)} /></span></td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className={"equal-width"}>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        {
                                            this.state.userTypes.map((userType) => (
                                                <th className={"text-center"}><span className={"text"}>{userType.name}</span></th>
                                            ))
                                        }
                                        <th />
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.questions.map((question) => (
                                            <tr>
                                                {
                                                    this.state.userTypes.map((userType) => {
                                                        let checked = false;

                                                        for (let i = 0; i < question.userTypes.length; i++) {
                                                            if (question.userTypes[i] == userType.id) {
                                                                checked = true;
                                                                break;
                                                            }
                                                        }

                                                        return (
                                                            <td className={"text-center"}>
                                                                <span className={"text"}><input type={"checkbox"} checked={checked} onClick={() => this.checkUserType(question.id, userType.id)}/></span>
                                                            </td>
                                                        )
                                                    })
                                                }
                                                <td className={"text-center"}>
                                                    <span className={"text"}><span className={"btn btn-primary"} onClick={() => this.moveToQuestionDetail(question)}>Edit</span></span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.currentPage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}