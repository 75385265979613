import React from 'react';
import BaseComponent from "../../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import LoadingSpinner from "../../common/LoadingSpinner";

import "./SiteConfigurationAuditAreasComponent.css";
import {Toast} from "../common/ToastManagerComponent";

export default class SiteConfigurationAuditAreasComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            siteAreaNetworkInFlight : false,
            siteAreas : []
        });
    }

    componentDidMount() {
        if (this.props.site !== undefined) {
            this.getSiteAreasFromNetwork();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.site !== undefined) {
            let shouldUpdate = true;
            if (prevProps.site !== undefined) {
                if (this.props.site === prevProps.site) {
                    shouldUpdate = false;
                }
            }

            if (shouldUpdate) {
                this.getSiteAreasFromNetwork();
            }
        }
    }

    checkSiteArea = (siteAreaId) => {
        let siteAreas = this.state.siteAreas;

        for (let i = 0; i < siteAreas.length; i++) {
            if (siteAreas[i].id === siteAreaId) {
                if (siteAreas[i].active == 1) {
                    siteAreas[i].active = 0;
                } else {
                    siteAreas[i].active = 1;
                }
                break;
            }
        }

        this.setState({
            siteAreas
        });
    }

    getSiteAreasFromNetwork = () => {
        if (this.state.siteAreaNetworkInFlight) return;

        this.setState({
            siteAreaNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("siteId", this.props.site.id);

        Axios.post(ENDPOINTS.site.getSiteSiteAreas, formData)
            .then((r) => {
                let siteAreas = [];

                let resp = API.parse(r);
                if (resp.success) {
                    siteAreas = resp.data.siteAreas;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    siteAreaNetworkInFlight : false,
                    siteAreas
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    submitSiteAreasOverNetwork = () => {
        if (this.state.siteAreaNetworkInFlight) return;

        this.setState({
            siteAreaNetworkInFlight : true
        });

        let siteAreaIds = [];
        for (let i = 0; i < this.state.siteAreas.length; i++) {
            if (this.state.siteAreas[i].active == 1) {
                siteAreaIds.push(this.state.siteAreas[i].id);
            }
        }

        let formData = new FormData();
        formData.append("siteId", this.props.site.id);
        formData.append("siteAreaIds", JSON.stringify(siteAreaIds));

        Axios.post(ENDPOINTS.site.submitSiteSiteAreas, formData)
            .then((r) => {
                let siteAreas = this.state.siteAreas;

                let resp = API.parse(r);
                if (resp.success) {
                    siteAreas = resp.data.siteAreas;

                    Toast.show("Success", "Successfully updated Audit Areas", Toast.TYPE_SUCCESS);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    siteAreaNetworkInFlight : false,
                    siteAreas
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    showError = (message) => {
        this.showModal("Error", message);
    }

    render() {
        let primaryContent = [];
        if (this.state.siteAreasNetworkInFlight) {
            primaryContent = (
                <LoadingSpinner />
            )
        } else {
            primaryContent = this.state.siteAreas.map((siteArea) =>
                (
                    <div className={"col-12 col-md-3"}>
                        <label>
                            <input type={"checkbox"} checked={(siteArea.active == 1)} onChange={() => this.checkSiteArea(siteArea.id)} />
                            <span className={"text"}>{siteArea.name}</span>
                        </label>
                    </div>
                )
            )
        }

        let submitButtonLabel = "Save Changes";
        let submitButtonExtraClass = "";
        if (this.state.siteAreaNetworkInFlight || this.state.siteAreas == null || this.state.siteAreas.length === 0) {
            submitButtonLabel = "Please wait...";
            submitButtonExtraClass = " disabled";
        }

        let submitButton = (
            <div className={"row submit-area"}>
                <div className={"col-12"}>
                    <button className={"btn btn-success" + submitButtonExtraClass} onClick={this.submitSiteAreasOverNetwork}>{submitButtonLabel}</button>
                </div>
            </div>
        )

        return (
            <div className={"container form-inputs site-configuration-audit-areas-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Audit Areas</h3>
                    </div>
                </div>

                <div className={"row audit-area-container"}>
                    {primaryContent}
                </div>

                {submitButton}
            </div>
        );
    }

}