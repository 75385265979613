import React from 'react';
import BaseComponent from "../../BaseComponent";
import { v4 as uuidv4 } from "uuid";

import "./ToastManagerComponent.css";

import iconInfo from "../../../assets/europackaging/icon_info.svg";
import iconSuccess from "../../../assets/europackaging/icon_success.svg";
import iconError from "../../../assets/europackaging/icon_warning.svg";
import iconClose from "../../../assets/europackaging/close-cross.svg";

export default class ToastManagerComponent extends BaseComponent {

    isAdded = false;

    constructor(props, context) {
        super(props, context);

        this.initState({
            toasts : []
        });
    }

    componentDidMount() {
        this.isAdded = true;
        // Set up global listener
        window._epToastQueue = (toast) => {
            this.addToast(toast);
        };
    }

    componentWillUnmount() {
        this.isAdded = false;
        window._epToastQueue = undefined;
    }

    addToast = (toast) => {
        let toasts = this.state.toasts;

        toasts.push(toast);

        this.setState({
            toasts
        });

        setTimeout(() => {
            if (this.isAdded) {
                this.removeToast(toast);
            }
        }, toast.duration);
    }

    removeToast = (toast) => {
        let toasts = this.state.toasts;

        for (let i = 0; i < toasts.length; i++) {
            if (toasts[i].id === toast.id) {
                toasts.splice(i, 1);
                break;
            }
        }

        this.setState({
            toasts
        });
    }

    render() {
        return(<div className={"toast-manager-component"}>
            {
                this.state.toasts.map((toast) => {
                    let toastClass = "";
                    let toastIcon = null;
                    if (toast.type === Toast.TYPE_INFO) {
                        toastClass = " toast-info";
                        toastIcon = iconInfo;
                    } else if (toast.type === Toast.TYPE_SUCCESS) {
                        toastClass = " toast-success";
                        toastIcon = iconSuccess;
                    } else if (toast.type === Toast.TYPE_ERROR) {
                        toastClass = " toast-error";
                        toastIcon = iconError;
                    }

                    return (
                        <div className={"toast-item" + toastClass} key={toast.id} style={{animationDuration : toast.duration + "ms"}}>
                            <div className={"toast-icon"} style={{backgroundImage : "url(" + toastIcon + ")"}}/>
                            <div className={"toast-content"}>
                                <div className={"toast-content-title"}>{toast.title}</div>
                                <div className={"toast-content-message"}>{toast.message}</div>
                            </div>
                            <div className={"toast-dismiss"} style={{backgroundImage : "url(" + iconClose + ")"}} onClick={() => this.removeToast(toast)} />
                        </div>
                    )
                })
            }
        </div>);
    }

}

export const Toast = {
    show : (title, message, type, duration) => {
        if (window._epToastQueue !== undefined) {
            if (type === undefined) {
                type = Toast.TYPE_INFO
            }

            if (duration === undefined) {
                duration = 4000;
            }

            window._epToastQueue({
                id : uuidv4(),
                type : type,
                title : title,
                message : message,
                duration : duration
            });
        }
    },
    TYPE_INFO : 1,
    TYPE_SUCCESS : 2,
    TYPE_ERROR : 3
}