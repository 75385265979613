import React from 'react';
import BaseComponent from "../../../BaseComponent";

import "./MediaViewerAudioComponent.css";

import mediaPlay from "../../../../assets/europackaging/media_play.svg";
import mediaPause from "../../../../assets/europackaging/media_pause.svg";
import mediaForward30 from "../../../../assets/europackaging/media_forward_30.svg";
import mediaRewind30 from "../../../../assets/europackaging/media_rewind_30.svg";
import {Chronos} from "../../../../data/Chronos";

export default class MediaViewerAudioComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.audioPlayer = React.createRef();
        this.seekbarElem = React.createRef();

        this.initState({
            audioPlaying : false,
            audioProgress : 0,
            timeCurrent : "00:00",
            timeDuration : "00:00"
        });
    }

    componentDidMount() {
        this.setupAudioPlayer();
    }

    componentWillUnmount() {
        this.tearDownAudioPlayer();
    }

    setupAudioPlayer = () => {
        this.audioPlayer.current.onloadedmetadata = this.audioPlayerDidUpdate;
        this.audioPlayer.current.ontimeupdate = this.audioPlayerDidUpdate;
        this.audioPlayer.current.onplay = this.audioPlayerDidUpdate;
        this.audioPlayer.current.onpause = this.audioPlayerDidUpdate;
    }

    tearDownAudioPlayer = () => {
        this.audioPlayer.current.onloadedmetadata = undefined;
        this.audioPlayer.current.ontimeupdate = undefined;
        this.audioPlayer.current.onplay = undefined;
        this.audioPlayer.current.onpause = undefined;
    }

    audioPlayerDidUpdate = () => {
        console.log("UPDATE");

        let elapsed = this.audioPlayer.current.currentTime;
        let duration = this.audioPlayer.current.duration;

        let position = (elapsed / duration) * 100;

        this.setState({
            audioPlaying : !this.audioPlayer.current.paused,
            audioProgress : position,
            timeCurrent : this.fancyFormatTime(elapsed),
            timeDuration : this.fancyFormatTime(duration)
        });
    }

    playPauseWasClicked = () => {
        if (this.audioPlayer.current.paused) {
            console.log("MEDIA PLAY");
            this.audioPlayer.current.play();
        } else {
            console.log("MEDIA PAUSE");
            this.audioPlayer.current.pause();
        }
    }

    seekButtonWasClicked = (direction) => {
        let currentPlayback = this.audioPlayer.current.currentTime;

        console.log("P: " + currentPlayback);

        if (direction === "back") {
            currentPlayback = Math.max(0, currentPlayback - 30);
        } else if (direction === "forward") {
            currentPlayback = Math.min(this.audioPlayer.current.duration, currentPlayback + 30);
        }

        console.log("CP: " + currentPlayback);

        if (!isNaN(currentPlayback)) {
            this.audioPlayer.current.currentTime = currentPlayback;
        }
    }

    videoDidSeek = (currentTime) => {
        this.audioPlayer.current.currentTime = currentTime;
    }

    seekbarWasClicked = (e) => {
        let seekbar = this.seekbarElem.current;

        let bounds = seekbar.getBoundingClientRect();
        let width = bounds.right - bounds.left;

        let correctedClickX = e.clientX - bounds.left;
        let seekProgress = correctedClickX / width;
        let playbackPosition = Math.floor(this.audioPlayer.current.duration * seekProgress);

        this.videoDidSeek(playbackPosition);
    }

    fancyFormatTime = (time) => {
        var sec_num = parseInt(time, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")
    }

    render() {
        let title = "Audio Guide";
        if (this.props.title !== undefined) {
            title = this.props.title;
        }

        let playPauseIcon = mediaPlay;
        if (this.state.audioPlaying) {
            playPauseIcon = mediaPause;
        }

        let mimeType = this.getOrDefault(this.props, "mimeType", "audio/mp3");

        return (
            <div className={"card media-viewer-audio-component"}>
                <div className="card-header">
                    {title}
                </div>
                <div className="card-body">
                    <audio ref={this.audioPlayer}>
                        <source src={this.props.mediaUrl} type={mimeType} />
                        Your browser does not support the audio element.
                    </audio>

                    <div className={"audio-player"}>
                        <div className={"control-area"}>
                            <div className={"spacer"} />
                            <div className={"control-seek"} onClick={() => this.seekButtonWasClicked("back")} style={{backgroundImage : "url(" + mediaRewind30 + ")"}} />
                            <div className={"spacer"} />
                            <div className={"control-play-pause"} onClick={this.playPauseWasClicked} style={{backgroundImage : "url(" + playPauseIcon + ")"}} />
                            <div className={"spacer"} />
                            <div className={"control-seek"} onClick={() => this.seekButtonWasClicked("forward")} style={{backgroundImage : "url(" + mediaForward30 + ")"}} />
                            <div className={"spacer"} />
                        </div>
                        <div className={"seekbar-container"} onClick={this.seekbarWasClicked} ref={this.seekbarElem}>
                            <div className={"seekbar"} style={{width: this.state.audioProgress + "%"}} />
                            <div className={"time-indicator start-time"}>{this.state.timeCurrent}</div>
                            <div className={"time-indicator end-time"}>{this.state.timeDuration}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}