import React from 'react';
import BaseComponent from "../../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import LoadingSpinner from "../../common/LoadingSpinner";
import {Chronos} from "../../../data/Chronos";

import "./AlertDetailComponent.css";
import {DataManager} from "../../../data/DataManager";
import UIBlockerComponent from "../common/UIBlockerComponent";
import ImageLightboxComponent from "../common/ImageLightboxComponent";
import ScreenTitleComponent from "../common/ScreenTitleComponent";

export default class AlertDetailComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let alertId = this.getUriProperty("id", -1);

        let user = DataManager.getUser();
        let userTypeId = parseInt(user.userTypeId);

        this.initState({
            alertFetchNetworkInFlight : false,
            alertReadNetworkInFlight : false,
            alertDeleteNetworkInFlight : false,
            alertId,
            alert : null,
            lightboxOpen : false,
            lightboxImageUrl : null,
            userTypeId
        });
    }

    componentDidMount() {
        this.getAlertFromNetwork();
    }

    deleteAlertWasClicked = () => {
        this.props.showModal("Confirmation", "Are you sure you want to delete this Alert? This cannot be undone.", [
            {
                label : "Delete",
                className : "danger",
                click : () => {
                    this.props.hideModal();
                    this.deleteAlertOverNetwork();
                }
            },
            {
                label : "Cancel",
                click : () => {
                    this.props.hideModal();
                }
            }
        ])
    }

    getAlertFromNetwork = () => {
        if (this.state.alertFetchNetworkInFlight) return;

        this.setState({
            alertFetchNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("id", this.state.alertId);

        if (this.state.userTypeId === API.userTypes.STORE_CHECK || this.state.userTypeId === API.userTypes.COVID19) {
            let site = DataManager.getCurrentSite();
            formData.append("siteId", site.id);
        }

        Axios.post(ENDPOINTS.alerts.getAlert, formData)
            .then((r) => {
                let alert = null;

                let resp = API.parse(r);
                if (resp.success) {
                    alert = resp.data.alert;
                    this.sendReadReceiptOverNetwork(alert);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    alertFetchNetworkInFlight : false,
                    alert : alert
                });
            })
            .catch((e) => {
                this.showError("Could not get Alert, an unknown error has occurred. Please reload the page and try again. [A4100]")
            });
    }

    sendReadReceiptOverNetwork = (alert) => {
        if (this.state.alertReadNetworkInFlight) return;

        if (this.state.userTypeId === API.userTypes.COVID19) {
            // Do not attempt to read alerts when a temporary user
            return;
        }

        if (alert === undefined) {
            alert = this.state.alert;
        }

        if (alert == null || parseInt(alert.read) !== 0) {
            console.log("Alert already read. Will not send networking.");
            return;
        }

        this.setState({
            alertReadNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("alertId", alert.id);

        if (this.state.userTypeId === API.userTypes.STORE_CHECK) {
            let site = DataManager.getCurrentSite();
            if (site != null) {
                formData.append("siteId", site.id);
            } else {
                console.log("No selected Asset. Will not send Read Receipt. Shouldn't happen.");
                return;
            }
        }

        Axios.post(ENDPOINTS.alerts.readAlert, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let readIds = DataManager.getTemporaryAlertReadIds();
                    readIds.push(alert.id);
                    DataManager.setTemporaryAlertReadIds(readIds);
                } else {
                    console.log("SILENT ERROR: " + API.formatError(resp));
                }

                this.setState({
                    alertReadNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log("SILENT ERROR: Could not mark alert as read.");

                this.setState({
                    alertReadNetworkInFlight : false
                });
            });
    }

    deleteAlertOverNetwork = () => {
        if (this.state.alertDeleteNetworkInFlight) return;

        this.setState({
            alertDeleteNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("alertId", this.state.alert.id);

        Axios.post(ENDPOINTS.alerts.deleteAlert, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.props.history.goBack();
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    alertDeleteNetworkInFlight : false
                });
            })
            .catch((e) => {
                this.showError("Could not delete Alert. There was an unknown error. [A4300]");

                this.setState({
                    alertDeleteNetworkInFlight : false
                });
            });
    }

    openLightbox = (imageUrl) => {
        this.setState({
            lightboxOpen : true,
            lightboxImageUrl : imageUrl
        });
    }

    closeLightbox = () => {
        this.setState({
            lightboxOpen : false
        });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        let alertContent = [];
        if (this.state.alertFetchNetworkInFlight) {
            alertContent = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <LoadingSpinner key={"loading"} />
                    </div>
                </div>
            );
        } else {
            if (this.state.alert != null) {
                alertContent.push(
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"alert-title"}>{this.state.alert.title}</div>
                            <div className={"alert-date"}><span className={"badge badge-secondary"}>{Chronos.withTimestampSeconds(this.state.alert.dateCreated).format("dd/MM/yy HH:mm")}</span></div>
                        </div>
                    </div>
                );

                alertContent.push(
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"card alert-message"}>
                                <div className={"card-body"}>
                                    {this.state.alert.message}
                                </div>
                            </div>
                        </div>
                    </div>
                );

                if (this.state.alert.images !== undefined) {
                    if (this.state.alert.images != null && this.state.alert.images.length > 0) {
                        this.state.alert.images.forEach((image) => {
                            alertContent.push(
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className={"embed-responsive embed-responsive-4by3 alert-image"} style={{backgroundImage : "url(" + image.publicThumbPath + ")"}} onClick={() => this.openLightbox(image.publicFullPath)} />
                                    </div>
                                </div>
                            )
                        })
                    }
                }

                if (this.state.userTypeId !== API.userTypes.STORE_CHECK && this.state.userTypeId !== API.userTypes.COVID19) {
                    alertContent.push(
                        <div className={"row button-area"}>
                            <div className={"col-12"}>
                                <button className={"btn btn-danger btn-rounded btn-fullwidth"}
                                        onClick={this.deleteAlertWasClicked}>Delete Alert
                                </button>
                            </div>
                        </div>
                    );
                }
            }
        }

        return (
            <div className={"container alert-detail-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent title={"Alerts"} history={this.props.history} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        {alertContent}
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.alertDeleteNetworkInFlight} />

                <ImageLightboxComponent
                    open={this.state.lightboxOpen}
                    imageUrl={this.state.lightboxImageUrl}
                    dismissCallback={this.closeLightbox} />

            </div>
        )
    }

}