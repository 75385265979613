import React from 'react';
import BaseComponent from "../BaseComponent";
import UIBlockerComponent from "../app/common/UIBlockerComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import "./RequestForgottenPasswordComponent.css";

export default class SubmitForgottenPasswordComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let token = this.getUriProperty("token", null);

        this.initState({
            networkInFlight : false,
            password : "",
            passwordRetype : "",
            token
        });
    }

    passwordIsValid = () => {
        if (this.state.password !== "" && this.state.passwordRetype !== "") {
            return this.state.password === this.state.passwordRetype;
        }
        return false;
    }

    moveToLogin = () => {
        this.props.history.push("/");
    }

    submitPasswordResetOverNetwork = () => {
        if (this.state.networkInFlight) return;
        if (!this.passwordIsValid()) return;
        if (this.state.token === null) return;

        this.setState({
            networkInFlight : true
        });

        let formData = new FormData();
        formData.append("token", this.state.token);
        formData.append("password", this.state.password);

        Axios.post(ENDPOINTS.auth.submitForgottenPassword, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.props.showModal("Successfully Reset Password", "Your password has been successfully reset. Please login with your new credentials.", [
                        {
                            label : "Continue",
                            className : "success",
                            click : () => {
                                this.props.hideModal();
                                this.moveToLogin();
                            }
                        }
                    ])
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    networkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later. [A1111]");

                this.setState({
                    networkInFlight : false
                });
            });
    }

    showError = (message) => {
        if (this.props.showModal !== undefined) {
            this.props.showModal("Error", message);
        }
    }

    render() {
        let submitButtonExtra = "";
        if (!this.passwordIsValid()) {
            submitButtonExtra = " disabled";
        }

        return (
            <div className={"container forgotten-password-component"}>
                <div className={"row"}>
                    <div className={"hidden-xs col-md-3 col-lg-4"} />
                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                Forgotten Password
                            </div>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <p>Please provide a new password for your user account.</p>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>New Password</label>
                                        <input type={"password"} className={"form-control"} name={"password"} value={this.state.password} onChange={this.handleChange} placeholder={"New Password"} />
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>Repeat Password</label>
                                        <input type={"password"} className={"form-control"} name={"passwordRetype"} value={this.state.passwordRetype} onChange={this.handleChange} placeholder={"Retype Password"} />
                                    </div>
                                </div>
                            </div>
                            <div className={"card-footer text-center"}>
                                <button className={"btn btn-success" + submitButtonExtra} onClick={this.submitPasswordResetOverNetwork}>Set Password</button>
                            </div>
                        </div>
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.networkInFlight} />
            </div>
        )
    }
}