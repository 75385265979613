import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import React from "react";
import ContractorReportingComponent from "./ContractorReportingComponent";
import ContractorHoursLandingComponent from "./ContractorHoursLandingComponent";

export default class ContractorLandingComponent extends BaseComponent {

    TAB_REPORTING = 1;
    TAB_HOURS = 2;

    tabs = [
        {
            id : this.TAB_REPORTING,
            label : "Reports",
            uri : "report"
        },
        {
            id : this.TAB_HOURS,
            label : "Hours",
            uri : "hours"
        }
    ];

    constructor(props, context) {
        super(props, context);

        let currentSelection = this.getUriProperty("section", "report");
        let selectedTab = this.resolveTabUri(currentSelection);

        this.initState({
            selectedTab
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let prevSection = this.getUriProperty("section", null, prevProps);
        let currentSection = this.getUriProperty("section", null);

        if (prevSection !== currentSection) {
            this.setState({
                selectedTab : this.resolveTabUri(currentSection)
            });
        }
    }

    changeTab = (tab) => {
        this.setState({
            selectedTab : tab.id
        });

        this.props.history.push("/contractor/" + tab.uri);
    }

    resolveTabUri = (section) => {
        for (let i = 0; i < this.tabs.length; i++) {
            if (this.tabs[i].uri === section) {
                return this.tabs[i].id;
            }
        }
        return this.TAB_REPORTING;
    }

    render() {
        let contentDetail = [];

        if (this.state.selectedTab === this.TAB_REPORTING) {
            contentDetail = (
                <ContractorReportingComponent {...this.props} />
            )
        } else if (this.state.selectedTab === this.TAB_HOURS) {
            contentDetail = (
                <ContractorHoursLandingComponent {...this.props} />
            )
        }

        return (
            <div className={"container site-configuration-editor-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent
                            title={"Contractor Dashboard"}
                            showBack={false}
                            backLocation={"/"}
                            {...this.props} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <ul className="nav nav-tabs">
                            {this.tabs.map((tab) => (
                                <li className="nav-item">
                                    <span key={"navtab_" + tab.id} className={"nav-link" + ((tab.id === this.state.selectedTab) ? " active" : "")} onClick={() => this.changeTab(tab)}>{tab.label}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {contentDetail}
            </div>
        )
    }
}