import React from 'react';
import BaseComponent from "../../BaseComponent";

import backArrow from "../../../assets/europackaging/arrow_back.svg";

export default class ScreenTitleComponent extends BaseComponent {

    backWasPressed = () => {
        if (this.props.backLocation !== undefined) {
            this.props.history.push(this.props.backLocation);
        } else {
            this.props.history.goBack();
        }
    }

    render() {
        let backButton = [];
        if (this.props.showBack === undefined || this.props.showBack) {
            backButton = (
                <div className={"back-button"} onClick={this.backWasPressed} style={{backgroundImage : "url(" + backArrow + ")"}} />
            );
        }

        return (
            <div className={"screen-title"}>
                {backButton}
                <div className={"title-text"}>
                    {this.props.title}
                </div>
            </div>
        )
    }
}