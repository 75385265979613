import React from 'react';

import "./LoadingSpinner.css";
import hourglass from "../../assets/europackaging/hourglass_empty.svg";

export default class LoadingSpinner extends React.Component {

    render() {
        return (
            <div className={"loading-spinner-component"}>
                <div className={"loading-spinner"} style={{backgroundImage : "url(" + hourglass + ")"}} />
            </div>
        )
    }
}