export const Question = {
    OUTCOMES : {
        GOOD : 1,
        AVERAGE : 2,
        BAD : 3,
        NA : 4
    },
    getDefaultOutcomeLabel : (outcomeTypeId) => {
        switch (outcomeTypeId) {
            case Question.OUTCOMES.GOOD:
                return "Good";
            case Question.OUTCOMES.AVERAGE:
                return "Average";
            case Question.OUTCOMES.BAD:
                return "Bad";
            case Question.OUTCOMES.NA:
                return "N/A";
            default:
                return "INVALID";
        }
    },
    findOutcomeImage : (question, outcomeType) => {
        if (question !== undefined && question != null) {
            if (question.hasOwnProperty("images") && question.images != null) {
                for (let i = 0; i < question.images.length; i++) {
                    if (question.images[i].auditQuestionOutcomeId == outcomeType) {
                        return question.images[i];
                    }
                }
            }
        }
        return null;
    }
}