import React from 'react';
import BaseComponent from "../BaseComponent";

import './LoginComponent.css';
import {Link} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import AlertModal from "../common/AlertModal";

import backIcon from "../../assets/europackaging/arrow_back.svg";
import logoImage from "../../assets/europackaging/ms_logo_alt.png";
import logoImageGeneric from "../../assets/europackaging/ep_logo_alt.png";

import {DataManager} from "../../data/DataManager";
import {Brand} from "../../data/Brand";

export default class LoginComponent extends BaseComponent {

    LOGIN_SELECTION_NONE = 1;
    LOGIN_SELECTION_STORE = 2;
    LOGIN_SELECTION_NORMAL = 3;
    LOGIN_SELECTION_ADMIN = 4;
    LOGIN_SELECTION_COVID = 5;

    constructor(props, context) {
        super(props, context);

        this.initState({
            mode : this.LOGIN_SELECTION_NONE,
            storeNumber : "",
            email : "",
            password : "",
            isMounted : false
        });
    }

    componentDidMount() {
        window._appConfigDidUpdate = () => {
            if (!this.state.isMounted) return;

            console.log("APP CONFIG UPDATED!");

            this.setState({
                nonUpdate : Math.random()
            })
        }

        this.setState({
            isMounted : true
        })
    }

    componentWillUnmount() {
        window._appConfigDidUpdate = undefined;

        this.setState({
            isMounted : false
        })
    }

    setFormMode = (mode) => {
        if (!this.state.isMounted) return;

        this.setState({
            mode
        });
    }

    loginWasSuccessful(token, user, site, clientId, appConfig) {
        this.props.loginCallback(token, user, site, clientId, appConfig);
    }

    submitLogin = (e) => {
        if (e !== undefined) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (this.state.mode === this.LOGIN_SELECTION_NORMAL) {
            API.setClientId(API.clientTypes.APP);
            this.submitStandardLoginOverNetwork();
        } else if (this.state.mode === this.LOGIN_SELECTION_STORE || this.state.mode === this.LOGIN_SELECTION_COVID) {
            API.setClientId(API.clientTypes.APP);
            this.submitTemporaryUserOverNetwork();
        } else if (this.state.mode === this.LOGIN_SELECTION_ADMIN) {
            API.setClientId(API.clientTypes.DASHBOARD);
            this.submitStandardLoginOverNetwork();
        }
    }

    submitStandardLoginOverNetwork = () => {
        if (this.state.loginNetworkInFlight) return;

        let validationResult = this.validateCreateFormData(this.state, [
            { key : "email", type : "string", label : "Email Address", postName : "emailAddress" },
            { key : "password", type : "string", label : "Password", postName : "password" }
        ]);

        if (validationResult.success) {
            let formData = validationResult.formData;

            if (this.state.mode === this.LOGIN_SELECTION_ADMIN) {
                formData.append("userTypeId", JSON.stringify([API.userTypes.ADMINISTRATOR, API.userTypes.CONTRACTOR_MANAGER]));
            }

            this.setState({
                loginNetworkInFlight : true
            });

            Axios.post(ENDPOINTS.auth.login, formData)
                .then((r) => {
                    this.setState({
                        loginNetworkInFlight : false
                    });

                    let resp = API.parse(r);
                    if (resp.success) {
                        this.loginWasSuccessful(resp.data.sessionToken, resp.data.user, resp.data.site, resp.data.clientId, resp.data.appConfig);
                    } else {
                        this.showError(API.formatError(resp));
                    }
                })
                .catch((e) => {
                    this.setState({
                        loginNetworkInFlight : false
                    });

                    console.log("LOGIN ERROR -----");
                    console.log(e);
                    console.log("LOGIN ERROR -----");
                    this.showError("An unknown error has occurred. Please try again later. [L100]");
                })
        } else {
            this.showError(validationResult.error);
        }
    }

    submitTemporaryUserOverNetwork = () => {
        if (this.state.temporaryLoginNetworkInFlight) return;

        let validationResult = this.validateCreateFormData(this.state, [
            { key : "storeNumber", type : "string", label : "Asset Number", postName : "siteNumber" }
        ]);

        if (validationResult.success) {
            this.setState({
                temporaryLoginNetworkInFlight: true
            });

            let userTypeId = API.userTypes.STORE_CHECK;
            if (this.state.mode === this.LOGIN_SELECTION_COVID) {
                userTypeId = API.userTypes.COVID19;
            }

            let formData = new FormData();
            formData.append("siteNumber", this.state.storeNumber);
            formData.append("userTypeId", userTypeId);

            Axios.post(ENDPOINTS.auth.createTemporarySession, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        this.loginWasSuccessful(resp.data.sessionToken, resp.data.user, resp.data.site, resp.data.clientId, resp.data.appConfig);
                    } else {
                        this.showError(API.formatError(resp));
                    }

                    this.setState({
                        temporaryLoginNetworkInFlight: false
                    });
                })
                .catch((e) => {
                    this.showError("An unknown error has occurred. Please try again later. [A1100]");

                    this.setState({
                        temporaryLoginNetworkInFlight: false
                    });
                });
        } else {
            this.showError(validationResult.error);
        }
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    generateModeTitle = (title) => {
        return (
            <div className={"mode-title"}>
                <div className={"back-button"} style={{backgroundImage : "url(" + backIcon + ")"}} onClick={() => this.setFormMode(this.LOGIN_SELECTION_NONE)} />
                <div className={"text"}>{title}</div>
            </div>
        )
    }

    getStoreCheckLabel() {
        if (process.env.REACT_APP_LOGIN_STORE_CHECK !== undefined) {
            return process.env.REACT_APP_LOGIN_STORE_CHECK;
        }
        return "Store Check";
    }

    getStoreNumberLabel() {
        if (process.env.REACT_APP_LOGIN_STORE_NUMBER !== undefined) {
            return process.env.REACT_APP_LOGIN_STORE_NUMBER;
        }
        return "Asset Number";
    }

    render() {
        let formContent = [];

        let configData = DataManager.getAppConfig();
        let covidCheckLabel = this.getOrDefault(configData, DataManager.appConfigKeys.Login.LABEL_LOGIN_TEMPORARY, "Cleaning Area Review");

        let useLogo = logoImageGeneric;
        if (parseInt(process.env.REACT_APP_BRAND_ID) === Brand.MS) {
            useLogo = logoImage;
        }

        if (this.state.mode === this.LOGIN_SELECTION_NONE) {
            formContent = (
                <div className={"login-box"}>
                    <div className={"embed-responsive embed-responsive-16by9 logo"} style={{backgroundImage : "url(" + useLogo + ")"}}/>

                    <div className={"form-block text-center"}>
                        Please select a user type
                    </div>

                    <button className={"btn btn-primary btn-rounded btn-fullwidth form-block"} onClick={() => this.setFormMode(this.LOGIN_SELECTION_STORE)}>{this.getStoreCheckLabel()}</button>
                    <button className={"btn btn-info btn-rounded btn-fullwidth form-block"} onClick={() => this.setFormMode(this.LOGIN_SELECTION_NORMAL)}>Audit Team/Contractor</button>
                    <button className={"btn btn-danger btn-rounded btn-fullwidth form-block"} onClick={() => this.setFormMode(this.LOGIN_SELECTION_ADMIN)}>Administrator</button>
                    <button className={"btn btn-secondary btn-rounded btn-fullwidth form-block"} onClick={() => this.setFormMode(this.LOGIN_SELECTION_COVID)}>{covidCheckLabel}</button>
                </div>
            )
        } else if (this.state.mode === this.LOGIN_SELECTION_STORE || this.state.mode === this.LOGIN_SELECTION_COVID) {
            let title = this.getStoreCheckLabel();
            if (this.state.mode === this.LOGIN_SELECTION_COVID) {
                title = covidCheckLabel;
            }

            formContent = (
                <div className={"login-box"}>
                    <div className={"embed-responsive embed-responsive-16by9 logo"} style={{backgroundImage : "url(" + useLogo + ")"}}/>

                    <div className={"form-block"}>
                        {this.generateModeTitle(title)}
                    </div>

                    <div className={"form-block"}>
                        <label>{this.getStoreNumberLabel()}</label>
                        <input type={"text"} className={"form-control"} name={"storeNumber"} onChange={this.handleChange} value={this.state.storeNumber} placeholder={"Please provide your " + this.getStoreNumberLabel()} />
                    </div>

                    <div className={"form-block"}>
                        <button className={"btn btn-success btn-rounded btn-fullwidth"} onClick={this.submitLogin}>Login</button>
                    </div>
                </div>
            )
        } else if (this.state.mode === this.LOGIN_SELECTION_NORMAL || this.state.mode === this.LOGIN_SELECTION_ADMIN) {
            formContent = (
                <div className={"login-box"}>
                    <div className={"embed-responsive embed-responsive-16by9 logo"} style={{backgroundImage : "url(" + useLogo + ")"}}/>

                    <div className={"form-block"}>
                        {this.generateModeTitle((this.state.mode === this.LOGIN_SELECTION_NORMAL) ? "Audit Team/Contractor" : "Administrator")}
                    </div>

                    <form onSubmit={this.submitLogin}>
                        <div className={"form-block"}>
                            <label>Email Address</label>
                            <input type={"text"} className={"form-control"} name={"email"} onChange={this.handleChange} value={this.state.email} placeholder={"Email Address"} />
                        </div>

                        <div className={"form-block"}>
                            <label>Password</label>
                            <input type={"password"} className={"form-control"} name={"password"} onChange={this.handleChange} value={this.state.password} placeholder={"Password"} />
                        </div>

                        <div className={"form-block"}>
                            <input type={"submit"} value={"Login"} className={"btn btn-success btn-rounded btn-fullwidth"} onClick={this.submitLogin} />
                        </div>
                    </form>


                    <div className={"form-block"}>
                        <Link to={"/forgot"} className={"btn btn-secondary btn-rounded btn-fullwidth"}>Forgotten Password</Link>
                    </div>
                </div>
            )
        }

        return (
            <div className={"login-component container"}>
                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-4"} />
                    <div className={"col-12 col-md-8 col-lg-4"}>
                        {formContent}
                    </div>
                </div>
            </div>
        )
    }

}