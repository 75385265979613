import React from 'react';
import BaseComponent from "../../BaseComponent";

import "./AuditSubmissionCompleteComponent.css";

import verifyIcon from "../../../assets/europackaging/verified.svg";
import {Audit} from "../../../data/Audit";
import {DataManager} from "../../../data/DataManager";
import {API} from "../../../network/API";

export default class AuditSubmissionCompleteComponent extends BaseComponent {


    constructor(props, context) {
        super(props, context);

        let user = DataManager.getUser();

        this.initState({
            user : user
        });
    }

    moveToStart = () => {
        if (this.props.mode === Audit.TYPE.COVID || parseInt(this.state.user.userTypeId) === API.userTypes.COVID19) {
            // If this was a COVID submission log the user out
            // TODO Signal deletion of Temporary Session
            DataManager.clear();
            window.location.href = "/";
            return;
        }
        this.props.history.push("/");
    }

    render() {
        let buttonLabel = "Continue";
        if (parseInt(this.state.user.userTypeId) === API.userTypes.COVID19) {
            buttonLabel = "Finish";
        }

        return (
            <div className={"container audit-submission-complete-component"}>
                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <div className={"card text-center"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"large-success-icon"} style={{backgroundImage : "url(" + verifyIcon + ")"}}/>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"success-title"}>
                                        Complete
                                    </div>

                                    <div className={"success-message"}>
                                        Your submission has been completed successfully.
                                    </div>
                                </div>
                            </div>

                            <div className={"row button-area"}>
                                <div className={"hidden-xs col-md-1 col-lg-2"} />
                                <div className={"col-12 col-md-10 col-lg-8"}>
                                    <button className={"btn btn-success btn-rounded btn-fullwidth"} onClick={this.moveToStart}>{buttonLabel}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}