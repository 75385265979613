import React from 'react';
import BaseComponent from "../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import UIBlockerComponent from "../app/common/UIBlockerComponent";

import "./RequestForgottenPasswordComponent.css";

export default class RequestForgottenPasswordComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            networkInFlight : false,
            emailAddress : ""
        });
    }

    moveToLogin = () => {
        this.props.history.push("/");
    }

    submitForgottenPasswordOverNetwork = () => {
        if (this.state.networkInFlight) return;
        if (this.state.emailAddress === "") return;

        this.setState({
            networkInFlight : true
        });

        let formData = new FormData();
        formData.append("emailAddress", this.state.emailAddress);

        Axios.post(ENDPOINTS.auth.requestForgottenPassword, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.props.showModal("Request Sent", "Please check your email for further details. If you do not receive an email, please check your spam or junk folders.", [
                        {
                            label : "Understood",
                            className : "success",
                            click : () => {
                                this.props.hideModal();
                                this.moveToLogin();
                            }
                        }
                    ]);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    networkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later. [A1110]");

                this.setState({
                    networkInFlight : false
                });
            });
    }

    showError = (message) => {
        if (this.props.showModal !== undefined) {
            this.props.showModal("Error", message);
        }
    }

    render() {
        let buttonExtraClass = "";
        if (this.state.emailAddress === "") {
            buttonExtraClass = " disabled";
        }

        return (
            <div className={"container forgotten-password-component"}>
                <div className={"row"}>
                    <div className={"hidden-xs col-md-3 col-lg-4"} />
                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                Forgotten Password
                            </div>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <p>Please provide your email address. If a user account has been registered against this email, you will receive an email with further instructions.</p>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>Email Address</label>
                                        <input type={"text"} className={"form-control"} name={"emailAddress"} value={this.state.emailAddress} onChange={this.handleChange} placeholder={"Email Address"} />
                                    </div>
                                </div>
                            </div>
                            <div className={"card-footer text-center"}>
                                <button className={"btn btn-success" + buttonExtraClass} onClick={this.submitForgottenPasswordOverNetwork}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row button-space"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-secondary"} onClick={this.moveToLogin}>Return to login</button>
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.networkInFlight} />
            </div>
        )
    }
}