import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import {NavLink} from "react-router-dom";
import SettingsUserDetailsComponent from "./SettingsUserDetailsComponent";

import "./SettingsLandingComponent.css";
import SettingsSiteAreasComponent from "./SettingsSiteAreasComponent";
import SettingsHomeScreenEditorComponent from "./SettingsHomeScreenEditorComponent";
import SiteConfigurationAlertComponent from "../sites/SiteConfigurationAlertComponent";
import {API} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";

export default class SettingsLandingComponent extends BaseComponent {

    NAVIGATION_LOGIN_DETAILS = 1;
    NAVIGATION_SITE_AREAS = 2;
    NAVIGATION_HOME_SCREEN = 3;
    NAVIGATION_ALERTS = 4;

    navigationTabs = [
        {
            id : this.NAVIGATION_LOGIN_DETAILS,
            label : "Login Details",
            url : "/settings",
            visibility : [
                API.userTypes.ADMINISTRATOR,
                API.userTypes.CONTRACTOR_MANAGER
            ]
        },
        {
            id : this.NAVIGATION_SITE_AREAS,
            label : "Asset Areas List",
            url : "/settings/site-areas",
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            id : this.NAVIGATION_HOME_SCREEN,
            label : "Customise",
            url : "/settings/home-screen",
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            id : this.NAVIGATION_ALERTS,
            label : "Global Alerts",
            url : "/settings/global-alerts",
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        }
    ];

    constructor(props, context) {
        super(props, context);

        let user = DataManager.getUser();

        let section = this.getUriProperty("section", null);
        let selectedTab = this.getSelectedTabForSection(section);

        this.initState({
            user,
            selectedTab
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let section = this.getUriProperty("section", null);
        let prevSection = this.getUriProperty("section", null, prevProps);

        if (section !== prevSection) {
            this.initState({
                selectedTab: this.getSelectedTabForSection(section)
            });
        }
    }

    getSelectedTabForSection = (section) => {
        if (section != null) {
            if (section === "site-areas") {
                return this.NAVIGATION_SITE_AREAS;
            } else if (section === "home-screen") {
                return this.NAVIGATION_HOME_SCREEN;
            } else if (section === "global-alerts") {
                return this.NAVIGATION_ALERTS;
            }
        }
        return this.NAVIGATION_LOGIN_DETAILS;
    }

    signOutWasClicked = () => {
        if (this.props.signoutCallback !== undefined) {
            this.props.signoutCallback();
        }
    }

    render() {
        let primaryContent = [];
        if (parseInt(this.state.user.userTypeId) !== API.userTypes.CONTRACTOR_MANAGER) {
            if (this.state.selectedTab === this.NAVIGATION_LOGIN_DETAILS) {
                primaryContent = (
                    <SettingsUserDetailsComponent
                        showModal={this.props.showModal}
                        hideModal={this.props.hideModal}
                        history={this.props.history}/>
                )
            } else if (this.state.selectedTab === this.NAVIGATION_SITE_AREAS) {
                primaryContent = (
                    <SettingsSiteAreasComponent
                        showModal={this.props.showModal}
                        hideModal={this.props.hideModal}
                        history={this.props.history}/>
                )
            } else if (this.state.selectedTab === this.NAVIGATION_HOME_SCREEN) {
                primaryContent = (
                    <SettingsHomeScreenEditorComponent
                        showModal={this.props.showModal}
                        hideModal={this.props.hideModal}
                        history={this.props.history}/>
                )
            } else if (this.state.selectedTab === this.NAVIGATION_ALERTS) {
                primaryContent = (
                    <SiteConfigurationAlertComponent
                        showModal={this.props.showModal}
                        hideModal={this.props.hideModal}
                        history={this.props.history}/>
                )
            }
        } else {
            // Force Contractor Manager users here regardless
            primaryContent = (
                <SettingsUserDetailsComponent
                    showModal={this.props.showModal}
                    hideModal={this.props.hideModal}
                    history={this.props.history}/>
            )
        }

        let tabElems = [];

        this.navigationTabs.forEach((tab) => {
            let shouldAdd = false;

            for (let i = 0; i < tab.visibility.length; i++) {
                if (tab.visibility[i] === parseInt(this.state.user.userTypeId)) {
                    shouldAdd = true;
                }
            }

            if (shouldAdd) {
                tabElems.push(
                    <li className="nav-item">
                        <NavLink className={"nav-link " + (tab.id === this.state.selectedTab ? "active" : "")}
                                 exact={true} to={tab.url}>{tab.label}</NavLink>
                    </li>
                )
            }
        })

        return (
            <div className={"container settings-landing-component"}>
                <div className={"row"}>
                    <div className={"col-8"}>
                        <ScreenTitleComponent
                            title={"Settings"}
                            showBack={false}
                            {...this.props} />
                    </div>

                    <div className={"col-4 text-right screen-actions"}>
                        <button className={"btn btn-danger"} onClick={this.signOutWasClicked}>Sign Out</button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <ul className="nav nav-tabs">
                            {tabElems}
                        </ul>
                    </div>
                </div>

                {primaryContent}

            </div>
        )
    }

}
