import { addDays, addMonths, addWeeks, addYears, addHours, addMinutes, addSeconds, addMilliseconds, format, toDate, parse as dnfParse, isValid, isBefore, isAfter, differenceInMilliseconds, differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears, parseISO, getTime } from 'date-fns/fp'

/**
 * Wrapper API for date-fns
 *
 *
 *
 * @type {{MILLISECONDS: string, before(*=, *=): boolean, SECONDS: string, DAYS: string, parse(*=, *=): ChronosInstance, MONTHS: string, YEARS: string, parseExact(*=, *=, *=): ChronosInstance, HOURS: string, WEEKS: string, now(): ChronosInstance, MINUTES: string, after(*=, *=): boolean}}
 */
export const Chronos = {

    MILLISECONDS : "milliseconds",
    SECONDS : "seconds",
    MINUTES : "minutes",
    HOURS : "hours",
    DAYS : "days",
    WEEKS : "weeks",
    MONTHS : "months",
    YEARS : "years",

    now() {
        return new ChronosInstance(new Date());
    },

    with(date) {
        if (isValid(date)) {
            return new ChronosInstance(date);
        }
        return null;
    },

    withTimestampSeconds(input) {
        let date = new Date(parseInt(input) * 1000);
        return this.with(date);
    },

    withTimestamp(input) {
        let date = new Date(parseInt(input));
        return this.with(date);
    },

    parse(input, options) {
        return new ChronosInstance(parseISO(input), options);
    },

    parseExact(input, format, options) {
        let parsedDate;
        if (this.isNumeric(input)) {
            console.log("CHRONOS WAS NUMERIC");
            parsedDate = dnfParse(parseInt(input));
        } else {
            console.log("CHRONOS WAS NOT NUMERIC");
            parsedDate = dnfParse(format, input);
        }
        console.log(parsedDate);
        return new ChronosInstance(parsedDate, options);
    },

    before(date1, date2) {
        return isBefore(date2, date1);
    },

    after(date1, date2) {
        return isAfter(date2, date1);
    },

    isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

}

export default class ChronosInstance {

    date = undefined;
    options = undefined;

    constructor(date, options) {
        this.date = date;
        this.options = options;
    }

    add = (value, unit) => {
        switch (unit) {
            case Chronos.MILLISECONDS:
                this.date = addMilliseconds(value, this.date); break;
            case Chronos.SECONDS:
                this.date = addSeconds(value, this.date); break;
            case Chronos.MINUTES:
                this.date = addMinutes(value, this.date); break;
            case Chronos.HOURS:
                this.date = addHours(value, this.date); break;
            case Chronos.DAYS:
                this.date = addDays(value, this.date); break;
            case Chronos.WEEKS:
                this.date = addWeeks(value, this.date); break;
            case Chronos.MONTHS:
                this.date = addMonths(value, this.date); break;
            case Chronos.YEARS:
                this.date = addYears(value, this.date); break;
        }

        return this;
    }

    difference = (date, unit) => {
        switch (unit) {
            case Chronos.MILLISECONDS:
                return differenceInMilliseconds(date, this.date);
            case Chronos.SECONDS:
                return differenceInSeconds(date, this.date);
            case Chronos.MINUTES:
                return differenceInMinutes(date, this.date);
            case Chronos.HOURS:
                return differenceInHours(date, this.date);
            case Chronos.DAYS:
                return differenceInDays(date, this.date);
            case Chronos.WEEKS:
                return differenceInWeeks(date, this.date);
            case Chronos.MONTHS:
                return differenceInMonths(date, this.date);
            case Chronos.YEARS:
                return differenceInYears(date, this.date);
        }

        return 0;
    }

    startOfDay = () => {
        return Chronos.with(this.date)
            .add((this.date.getHours() * -1), Chronos.HOURS)
            .add((this.date.getMinutes() * -1), Chronos.MINUTES)
            .add((this.date.getSeconds() * -1), Chronos.SECONDS)
            .add((this.date.getMilliseconds() * -1), Chronos.MILLISECONDS)
            .getDate();
    }

    format = (formatString, options) => {
        if (options === undefined) {
            options = this.options;
        }

        return format(formatString, this.date);
    }

    getDate = () => {
        return this.date;
    }

    isValid = () => {
        return isValid(this.date);
    }

    seconds = () => {
        return (getTime(this.date) / 1000).toFixed(0);
    }

    milliseconds = () => {
        return getTime(this.date);
    }

}