import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";

import "./AuditQuestionEditorComponent.css";

import deleteIcon from "../../../assets/europackaging/trash_white.svg";
import {Audit} from "../../../data/Audit";
import {Question} from "../../../data/Question";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../common/ToastManagerComponent";
import UploadProgressComponent from "../../app/common/UploadProgressComponent";
import AuditQuestionSiteVisibilityComponent from "./AuditQuestionSiteVisibilityComponent";

export default class AuditQuestionEditorComponent extends BaseComponent {

    isAdded = false;

    // These get populated into state at construction
    outcomeTypes = [
        {
            id : Question.OUTCOMES.GOOD,
            label : "Positive Outcome",
            value : null,
            extraLabel : null,
            score : 100,
            defaultValue : Question.getDefaultOutcomeLabel(Question.OUTCOMES.GOOD)
        },
        {
            id : Question.OUTCOMES.AVERAGE,
            label : "Neutral Outcome",
            value : null,
            extraLabel : null,
            score: 100,
            defaultValue : Question.getDefaultOutcomeLabel(Question.OUTCOMES.AVERAGE)
        },
        {
            id : Question.OUTCOMES.BAD,
            label : "Negative Outcome",
            value : null,
            extraLabel : null,
            score : 0,
            defaultValue : Question.getDefaultOutcomeLabel(Question.OUTCOMES.BAD)
        },
        {
            id : Question.OUTCOMES.NA,
            label : "Non-Applicable Outcome",
            value : null,
            score : null,
            defaultValue : "Skip this question"
        }
    ]

    imagePreviewTypes = [
        {
            id : Question.OUTCOMES.GOOD,
            title : "Positive Image"
        },
        {
            id : Question.OUTCOMES.AVERAGE,
            title : "Neutral Image"
        },
        {
            id : Question.OUTCOMES.BAD,
            title : "Negative Image"
        }
    ]

    currentTemporaryImageOutcomeTypeId = null;
    
    deletedImageIds = [];

    constructor(props, context) {
        super(props, context);

        let questionId = this.getUriProperty("id", null);
        if (questionId === "new") {
            questionId = null;
        }
        let isNewQuestion = questionId == null;

        this.initState({
            questionNetworkInFlight : false,
            temporaryImages : [],
            questionId,
            isNewQuestion,
            question : null,
            siteAreas : [],
            userTypes : [],
            selectedSiteAreas : [],
            selectedUserTypes : [],
            progressTitle : "Uploading images...",
            progressMessage : "",
            progressProgress : 0,
            outcomeTypes : this.outcomeTypes,
            questionOmitFromScore : false,
            auditQuestionVisibility : undefined
        });

        this.imageUploadInput = React.createRef();
    }

    componentDidMount() {
        this.isAdded = true;

        if (this.state.questionId !== null) {
            this.getQuestionFromNetwork();
        }
        this.getEditorConfigurationDataFromNetwork();
    }

    componentWillUnmount() {
        this.isAdded = false;
    }

    launchImagePicker = (outcomeTypeId) => {
        this.currentTemporaryImageOutcomeTypeId = outcomeTypeId;

        this.imageUploadInput.current.click();
    }

    deleteImageWasClicked = (imageId, outcomeTypeId) => {
        let success = false;

        let temporaryImages = this.state.temporaryImages;

        if (imageId !== undefined && this.state.question !== null) {
            for (let i = 0; i < this.state.question.outcomeImages.length; i++) {
                if (this.state.question.outcomeImages[i].id === imageId) {
                    this.state.question.outcomeImages.splice(i, 1);
                    this.deletedImageIds.push(imageId);
                    success = true;
                    break;
                }
            }
        } else {
            for (let i = 0; i < temporaryImages.length; i++) {
                if (temporaryImages[i].auditQuestionOutcomeId === outcomeTypeId) {
                    temporaryImages.splice(i, 1);
                    success = true;
                    break;
                }
            }
        }

        if (success) {
            this.setState({
                temporaryImages
            });
        }
    }

    fileDidChange = (event) => {
        if (this.imageUploadInput.current.files.length > 0 && this.currentTemporaryImageOutcomeTypeId != null) {
            let file = this.imageUploadInput.current.files[0];

            let temporaryImages = this.state.temporaryImages;

            for (let i = 0; i < temporaryImages.length; i++) {
                if (temporaryImages[i].auditQuestionOutcomeId === this.currentTemporaryImageOutcomeTypeId) {
                    temporaryImages.splice(i, 1);
                    break;
                }
            }

            let reader = new FileReader();
            reader.onload = () => {
                this.deleteImageWasClicked(undefined, this.currentTemporaryImageOutcomeTypeId);

                temporaryImages.push({
                    auditQuestionOutcomeId : this.currentTemporaryImageOutcomeTypeId,
                    publicFullPath : reader.result,
                    file : file
                });

                this.setState({
                    temporaryImages
                });

                this.imageUploadInput.current.value = null;
            }
            reader.readAsDataURL(file);
        }
    }

    findImageForOutcomeType = (outcomeTypeId) => {
        for (let i = 0; i < this.state.temporaryImages.length; i++) {
            if (this.state.temporaryImages[i].auditQuestionOutcomeId === outcomeTypeId) {
                return this.state.temporaryImages[i];
            }
        }

        if (this.state.question != null) {
            if (this.state.question.outcomeImages !== undefined) {
                for (let i = 0; i < this.state.question.outcomeImages.length; i++) {
                    if (parseInt(this.state.question.outcomeImages[i].auditQuestionOutcomeId) === outcomeTypeId) {
                        return this.state.question.outcomeImages[i];
                    }
                }
            }
        }

        return null;
    }

    populateQuestionIntoState = (question) => {
        let questionText = "";
        let questionSkippable = false;
        let selectedSiteAreas = [];
        let selectedUserTypes = [];
        let questionOmitFromScore = false;

        if (question != null) {
            questionText = question.questionText;
            questionSkippable = parseInt(question.skippable) === 1;
            questionOmitFromScore = parseInt(question.omitFromScore) === 1;

            question.siteAreas.forEach((siteArea) => {
                selectedSiteAreas.push(parseInt(siteArea.siteAreaId));
            });

            question.userTypes.forEach((userType) => {
                selectedUserTypes.push(parseInt(userType.userTypeId));
            });
        }

        let outcomeTypes = this.state.outcomeTypes;
        for (let i = 0; i < outcomeTypes.length; i++) {
            let value = outcomeTypes[i].value;
            let extraLabel = outcomeTypes[i].extraLabel;
            let score = outcomeTypes[i].score;
            if (outcomeTypes[i].id === Question.OUTCOMES.GOOD) {
                value = this.getOrDefault(question, "goodLabel", value);
                extraLabel = this.getOrDefault(question, "goodExtraLabel", extraLabel);
                score = parseInt(this.getOrDefault(question, "goodScore", score));
            } else if (outcomeTypes[i].id === Question.OUTCOMES.AVERAGE) {
                value = this.getOrDefault(question, "averageLabel", value);
                extraLabel = this.getOrDefault(question, "averageExtraLabel", extraLabel);
                score = parseInt(this.getOrDefault(question, "averageScore", score));
            } else if (outcomeTypes[i].id === Question.OUTCOMES.BAD) {
                value = this.getOrDefault(question, "badLabel", value);
                extraLabel = this.getOrDefault(question, "badExtraLabel", extraLabel);
                score = parseInt(this.getOrDefault(question, "badScore", score));
            } else if (outcomeTypes[i].id === Question.OUTCOMES.NA) {
                value = this.getOrDefault(question, "skipLabel", value);
            }
            outcomeTypes[i].value = value;
            outcomeTypes[i].score = score;
            if (outcomeTypes[i].extraLabel !== undefined) {
                outcomeTypes[i].extraLabel = extraLabel;
            }
        }

        this.setState({
            questionText,
            questionSkippable,
            selectedSiteAreas,
            selectedUserTypes,
            questionOmitFromScore
        });
    }

    doesQuestionHaveUserType = (userTypeId) => {
        userTypeId = parseInt(userTypeId);
        for (let i = 0; i < this.state.selectedUserTypes.length; i++) {
            if (this.state.selectedUserTypes[i] === userTypeId) {
                return true;
            }
        }
        return false;
    }

    checkQuestionUserType = (userTypeId) => {
        userTypeId = parseInt(userTypeId);

        let hasUserType = this.doesQuestionHaveUserType(userTypeId);

        let selectedUserTypes = this.state.selectedUserTypes;

        if (hasUserType) {
            for (let i = 0; i < selectedUserTypes.length; i++) {
                if (selectedUserTypes[i] === userTypeId) {
                    selectedUserTypes.splice(i, 1);
                }
            }
        } else {
            selectedUserTypes.push(userTypeId);
        }

        this.setState({
            selectedUserTypes
        });
    }

    doesQuestionHaveSiteArea = (siteAreaId) => {
        siteAreaId = parseInt(siteAreaId);
        for (let i = 0; i < this.state.selectedSiteAreas.length; i++) {
            if (this.state.selectedSiteAreas[i] === siteAreaId) {
                return true;
            }
        }
        return false;
    }

    checkQuestionSiteArea = (siteAreaId) => {
        siteAreaId = parseInt(siteAreaId);

        let hasUserType = this.doesQuestionHaveSiteArea(siteAreaId);

        let selectedSiteAreas = this.state.selectedSiteAreas;

        if (hasUserType) {
            for (let i = 0; i < selectedSiteAreas.length; i++) {
                if (selectedSiteAreas[i] === siteAreaId) {
                    selectedSiteAreas.splice(i, 1);
                }
            }
        } else {
            selectedSiteAreas.push(siteAreaId);
        }

        this.setState({
            selectedSiteAreas
        });
    }

    promptForArchive = (title, message) => {
        this.props.showModal(title, message, [
            {
                label : "Confirm",
                className : "danger",
                click : () => {
                    this.archiveQuestionOverNetwork();
                    this.props.hideModal();
                }
            },
            {
                label : "Cancel",
                click : () => {
                    this.props.hideModal();
                }
            }
        ]);
    }

    getQuestionFromNetwork = () => {
        if (this.state.questionNetworkInFlight || this.state.isNewQuestion) return;

        this.setState({
            questionNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("questionId", this.state.questionId);

        Axios.post(ENDPOINTS.audit.getQuestionAdmin, formData)
            .then((r) => {
                let question = null;

                let resp = API.parse(r);
                if (resp.success) {
                    question = resp.data.question;

                    this.populateQuestionIntoState(question);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    questionNetworkInFlight : false,
                    question
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later. [D2710]")
                this.setState({
                    questionNetworkInFlight : false
                });
            });
    }

    getEditorConfigurationDataFromNetwork = () => {
        if (this.state.configNetworkInFlight) return;

        this.setState({
            configNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.audit.getAuditQuestionConfigurationData)
            .then((r) => {
                let siteAreas = [];
                let userTypes = [];

                let resp = API.parse(r);
                if (resp.success) {
                    siteAreas = resp.data.siteAreas;
                    userTypes = resp.data.userTypes;
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    configNetworkInFlight : false,
                    siteAreas,
                    userTypes
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    configNetworkInFlight : false
                });
            });
    }

    submitQuestionOverNetwork = () => {
        if (this.state.questionSubmitNetworkInFlight) return;

        if (this.state.questionText === "") {
            this.showError("Please provide Question Text");
            return;
        }

        this.setState({
            questionSubmitNetworkInFlight : true,
            progressTitle : "Please Wait",
            progressMessage : "Submitting Question...",
            progressProgress : 0
        });

        let formData = new FormData();
        if (this.state.question != null) {
            formData.append("id", this.state.question.id);
        }
        formData.append("questionText", this.state.questionText);
        formData.append("skippable", this.state.questionSkippable ? "1" : "0");
        formData.append("omitFromScore", this.state.questionOmitFromScore ? "1" : "0");
        formData.append("siteAreas", JSON.stringify(this.state.selectedSiteAreas));
        formData.append("userTypes", JSON.stringify(this.state.selectedUserTypes));
        formData.append("deletedOutcomeImageIds", JSON.stringify(this.deletedImageIds));

        if (this.state.auditQuestionVisibility !== undefined && this.state.question !== null) {
            formData.append("auditQuestionVisibility", JSON.stringify(this.state.auditQuestionVisibility));
        }

        console.log(this.state.outcomeTypes);

        this.state.outcomeTypes.forEach((outcomeType) => {
            switch (outcomeType.id) {
                case Question.OUTCOMES.GOOD:
                    if (outcomeType.value != null && outcomeType.value !== "") {
                        formData.append("goodLabel", outcomeType.value);
                    }
                    if (outcomeType.score != null && outcomeType.score !== "") {
                        formData.append("goodScore", outcomeType.score);
                    }
                    if (outcomeType.extraLabel != null && outcomeType.extraLabel !== "") {
                        formData.append("goodExtraLabel", outcomeType.extraLabel);
                    }
                    break;
                case Question.OUTCOMES.AVERAGE:
                    if (outcomeType.value != null && outcomeType.value !== "") {
                        formData.append("averageLabel", outcomeType.value);
                    }
                    if (outcomeType.score != null && outcomeType.score !== "") {
                        formData.append("averageScore", outcomeType.score);
                    }
                    if (outcomeType.extraLabel != null && outcomeType.extraLabel !== "") {
                        formData.append("averageExtraLabel", outcomeType.extraLabel);
                    }
                    break;
                case Question.OUTCOMES.BAD:
                    if (outcomeType.value != null && outcomeType.value !== "") {
                        formData.append("badLabel", outcomeType.value);
                    }
                    if (outcomeType.score != null && outcomeType.score !== "") {
                        formData.append("badScore", outcomeType.score);
                    }
                    if (outcomeType.extraLabel != null && outcomeType.extraLabel !== "") {
                        formData.append("badExtraLabel", outcomeType.extraLabel);
                    }
                    break;
                case Question.OUTCOMES.NA:
                    if (outcomeType.value != null && outcomeType.value !== "") {
                        formData.append("skipLabel", outcomeType.value);
                    }
                    break;
                default:
                    break;
            }
        });

        Axios.post(ENDPOINTS.audit.submitAuditQuestion, formData)
            .then((r) => {
                let questionSubmitNetworkInFlight = true;
                let question = this.state.question;

                let resp = API.parse(r);
                if (resp.success) {
                    question = resp.data.question;

                    this.populateQuestionIntoState(question);

                    this.deletedImageIds = [];

                    this.uploadOutcomeImagesOverNetwork(question, 0);
                } else {
                    questionSubmitNetworkInFlight = false;
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    questionSubmitNetworkInFlight,
                    question
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please reload the page.");
                this.setState({
                    questionSubmitNetworkInFlight : false
                });
            })
    }

    uploadOutcomeImagesOverNetwork = (question, index) => {
        if (question === undefined) {
            question = this.state.question;
        }

        if (this.state.temporaryImages.length > 0 && index < this.state.temporaryImages.length) {
            let temporaryImage = this.state.temporaryImages[index];

            this.setState({
                progressTitle : "Uploading Images",
                progressMessage : "Uploading Image " + (index + 1) + " of " + this.state.temporaryImages.length
            });

            let formData = new FormData();
            formData.append("auditQuestionId", question.id);
            formData.append("auditQuestionOutcomeId", temporaryImage.auditQuestionOutcomeId);
            formData.append("image", temporaryImage.file);

            let config = {
                onUploadProgress: (progressEvent) => {
                    let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                    // This progress will consider ALL images that need to be uploaded.
                    let progress = (100 * index) + Math.ceil((progressEvent.loaded / progressTotal) * 100) / this.state.temporaryImages.length;

                    this.notifyProgress(progress);
                }
            }

            Axios.post(ENDPOINTS.audit.submitAuditQuestionOutcomeImage, formData, config)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        this.state.question.outcomeImages = resp.data.images;

                        this.uploadOutcomeImagesOverNetwork(question,index + 1);
                    } else {
                        Toast.show("Error", API.formatError(resp), Toast.TYPE_ERROR);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.uploadOutcomeImagesOverNetwork(question, index + 1);
                    Toast.show("Error", "Could not upload Outcome Image [D2730]", Toast.TYPE_ERROR);
                });
        } else {
            // Delay here a bit for the progress bar to complete and look more natural
            setTimeout(() => {
                if (this.isAdded) {
                    this.submissionDidComplete();
                }
            }, 500);
        }
    }

    submissionDidComplete = () => {
        Toast.show("Success", "Successfully updated Question", Toast.TYPE_SUCCESS);

        this.setState({
            questionSubmitNetworkInFlight : false,
            temporaryImages : []
        });
    }

    archiveQuestionOverNetwork = () => {
        if (this.state.questionNetworkInFlight) return;

        this.setState({
            questionNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("auditQuestionId", this.state.question.id);
        formData.append("active", (this.state.question.active == 1) ? 0 : 1);

        Axios.post(ENDPOINTS.audit.archiveAuditQuestion, formData)
            .then((r) => {
                let question = this.state.question;

                let resp = API.parse(r);
                if (resp.success) {
                    question = resp.data.question;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    questionNetworkInFlight : false,
                    question
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please reload the page. [D2280]");
            });
    }

    notifyProgress = (progress) => {
        this.setState({
            progressProgress : progress
        });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    setOutcomeLabel = (outcomeTypeId, value) => {
        let outcomeTypes = this.state.outcomeTypes;

        for (let i = 0; i < this.state.outcomeTypes.length; i++) {
            if (outcomeTypes[i].id === outcomeTypeId) {
                outcomeTypes[i].value = value;
                break;
            }
        }

        this.setState({
            outcomeTypes
        });
    }

    setOutcomeExtraLabel = (outcomeTypeId, value) => {
        let outcomeTypes = this.state.outcomeTypes;

        for (let i = 0; i < this.state.outcomeTypes.length; i++) {
            if (outcomeTypes[i].id === outcomeTypeId) {
                outcomeTypes[i].extraLabel = value;
                break;
            }
        }

        this.setState({
            outcomeTypes
        });
    }

    setOutcomeScore = (outcomeTypeId, score) => {
        let outcomeTypes = this.state.outcomeTypes;

        score = parseInt(score);
        if (isNaN(score)) {
            score = "";
        } else {
            score = Math.min(100, score);
            score = Math.max(0, score);
        }

        for (let i = 0; i < this.state.outcomeTypes.length; i++) {
            if (outcomeTypes[i].id === outcomeTypeId) {
                outcomeTypes[i].score = score;
                break;
            }
        }

        this.setState({
            outcomeTypes
        });
    }

    getStoreCheckLabel = () => {
        if (process.env.REACT_APP_LOGIN_STORE_CHECK !== undefined) {
            return process.env.REACT_APP_LOGIN_STORE_CHECK;
        }
        return "Store Check";
    }

    visibilityDidChange = (visibility) => {
        this.setState({
            auditQuestionVisibility : visibility
        });
    }

    render() {
        let archiveButton = [];
        let visibilityElem = [];
        if (this.state.question != null) {
            if (this.state.question.active == 1) {
                archiveButton = (
                    <button className={"btn btn-danger"} onClick={() => this.promptForArchive("Archive Question", "Are you sure you want to archive this Question? It will no longer appear within the app.")}>Archive</button>
                )
            } else {
                archiveButton = (
                    <button className={"btn btn-warning"} onClick={() => this.promptForArchive("Un-archive Question", "Are you sure you want to un-archive this Question? It will become immediately available within the app.")}>Un-archive</button>
                )
            }

            visibilityElem = (
                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <h3>Asset Visibility</h3>
                    </div>

                    <div className={"col-1 col-md-2 col-lg-3"} />
                    <div className={"col-10 col-md-8 col-lg-6"}>
                        <AuditQuestionSiteVisibilityComponent
                            auditQuestionId={this.state.question.id}
                            callback={this.visibilityDidChange} />
                    </div>
                </div>
            );
        }

        let scoreElements = [];
        this.outcomeTypes.forEach((outcome) => {
            if (outcome.score != null) {
                scoreElements.push(
                    <div className={"col-12 col-md-4"}>
                        <label>{outcome.label}</label>
                        <input type={"number"} className={"form-control text-center"} value={outcome.score} onChange={(e) => this.setOutcomeScore(outcome.id, e.target.value)} />
                    </div>
                )
            }
        })

        return (
            <div className={"container audit-question-editor-component form-inputs"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <ScreenTitleComponent
                            title={"Question Editor"}
                            {...this.props} />
                    </div>
                    <div className={"col-12 col-md-6 screen-actions text-right"}>
                        {archiveButton}
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Question Text</label>
                        <input type={"text"} className={"form-control"} name={"questionText"} value={this.state.questionText} onChange={this.handleChange} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Outcome Labels</h3>
                    </div>

                    {
                        this.outcomeTypes.map((outcome) => (
                            <div className={"col-12 col-md-6"}>
                                <label>{outcome.label}</label>
                                <input type={"text"} className={"form-control"} value={outcome.value} placeholder={outcome.defaultValue} onChange={(e) => this.setOutcomeLabel(outcome.id, e.target.value)} />
                            </div>
                        ))
                    }
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Outcome Image Labels</h3>
                    </div>

                    {
                        this.outcomeTypes.map((outcome) => {
                            if (outcome.hasOwnProperty("extraLabel")) {
                                return (
                                    <div className={"col-12 col-md-6"}>
                                        <label>{outcome.label}</label>
                                        <input type={"text"} className={"form-control"} value={outcome.extraLabel} placeholder={outcome.defaultValue} onChange={(e) => this.setOutcomeExtraLabel(outcome.id, e.target.value)} />
                                    </div>
                                )
                            }
                            return [];
                        })
                    }
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Outcome Score Weighting</h3>
                        <em>Please ensure Score Weights are between 0 and 100 inclusive.</em>
                    </div>

                    {scoreElements}

                    <div className={"col-12 form-block"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label><input type={"checkbox"} name={"questionOmitFromScore"} checked={this.state.questionOmitFromScore} onChange={this.handleChange} /> Omit this question from Audit/Check scoring</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>{this.getStoreCheckLabel()} Imagery</h3>
                    </div>

                    <div className={"hidden-md col-lg-3"} />
                    {
                        this.imagePreviewTypes.map((type) => {
                            let backgroundImageUri = "";
                            let removeButton = [];

                            let currentImage = this.findImageForOutcomeType(type.id);
                            if (currentImage != null) {
                                backgroundImageUri = currentImage.publicFullPath;
                                removeButton = (
                                    <div className={"remove"} style={{backgroundImage : "url(" + deleteIcon + ")"}} onClick={() => this.deleteImageWasClicked(currentImage.id, currentImage.outcomeTypeId)} />
                                );
                            }

                            return (
                                <div className={"col-12 col-sm-4 col-lg-2 text-center"}>
                                    <label>{type.title}</label>
                                    <div className={"embed-responsive embed-responsive-1by1 question-image-preview"} style={{backgroundImage : "url(" + backgroundImageUri + ")"}}>
                                        {removeButton}
                                    </div>
                                    <button className={"btn btn-primary"} onClick={() => this.launchImagePicker(type.id)}>Upload Image</button>
                                </div>
                            )
                        })
                    }

                    <div className={"file-hide"}>
                        <input type={"file"} ref={this.imageUploadInput} onChange={this.fileDidChange} />
                    </div>

                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Optionality</h3>
                    </div>

                    <div className={"hidden-xs col-md-3"}/>
                    <div className={"col-md-6"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <label>
                                    <input type={"checkbox"} checked={this.state.questionSkippable} name={"questionSkippable"} onChange={this.handleChange} /> Allow Question to be skipped during Checks or Inspections.
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row horizontal-check-list"}>
                    <div className={"col-12"}>
                        <h3>Apply to</h3>
                    </div>

                    {
                        this.state.userTypes.map((userType) =>
                            <div className={"col-6 col-md-4 col-lg-3"}>
                                <label>
                                    <input type={"checkbox"} checked={this.doesQuestionHaveUserType(userType.id)} onClick={() => this.checkQuestionUserType((userType.id))} />
                                    <span className={"text"}>{userType.name}</span>
                                </label>
                            </div>
                        )
                    }
                </div>

                <div className={"row horizontal-check-list"}>
                    <div className={"col-12"}>
                        <h3>Enabled for</h3>
                    </div>

                    {
                        this.state.siteAreas.map((siteArea) =>
                            <div className={"col-6 col-md-4 col-lg-3"}>
                                <label>
                                    <input type={"checkbox"} checked={this.doesQuestionHaveSiteArea(siteArea.id)} onClick={() => this.checkQuestionSiteArea(siteArea.id)} />
                                    <span className={"text"}>{siteArea.name}</span>
                                </label>
                            </div>
                        )
                    }
                </div>

                {visibilityElem}

                <div className={"row submit-area"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-success"} onClick={this.submitQuestionOverNetwork}>Save Changes</button>
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.questionNetworkInFlight} />

                <UploadProgressComponent
                    open={this.state.questionSubmitNetworkInFlight}
                    title={this.state.progressTitle}
                    message={this.state.progressMessage}
                    progress={this.state.progressProgress} />

            </div>
        )
    }

}