import React from 'react';
import BaseComponent from "../../../BaseComponent";

import "./MediaViewerPDFComponent.css";

export default class MediaViewerPDFComponent extends BaseComponent {

    render() {
        let title = "Specification PDF";
        if (this.props.title !== undefined) {
            title = this.props.title;
        }

        return (
            <div className={"card media-viewer-pdf-component"}>
                <div className="card-header">
                    {title}
                </div>
                <div className="card-body">
                    <a className={"btn btn-success btn-rounded btn-fullwidth"} href={this.props.mediaUrl}>Download PDF</a>
                </div>
            </div>
        )
    }

}