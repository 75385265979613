import React from 'react';
import BaseComponent from "../../BaseComponent";

import "./UploadProgressComponent.css";

export default class UploadProgressComponent extends BaseComponent {

    render() {
        let open = this.getOrDefault(this.props, "open", false);
        if (!open) {
            return [];
        }

        return (
            <div className={"upload-progress-component"}>
                <div className={"spacer"} />
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"hidden-xs col-md-3 col-lg-4"} />
                        <div className={"col-12 col-md-6 col-lg-4"}>
                            <div className={"progress-modal-dialog"}>
                                <div className={"progress-modal-dialog-title"}>{this.getOrDefault(this.props, "title", "Please Wait")}</div>
                                <div className={"progress-modal-dialog-message"}>{this.getOrDefault(this.props, "message", "")}</div>
                                <div className={"progress-modal-dialog-progress"}>
                                    <div className={"progress"}>
                                        <div className={"progress-bar"} style={{width : this.getOrDefault(this.props, "progress", 0) + "%"}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"spacer"} />
            </div>
        )
    }
}