import React from 'react';
import Axios from "axios";

import BaseComponent from "../../BaseComponent";
import LoadingSpinner from "../../common/LoadingSpinner";
import UIBlockerComponent from "../common/UIBlockerComponent";
import {API, ENDPOINTS} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";
import {Audit} from "../../../data/Audit";

import "./AuditQuestionListComponent.css";

import checkIcon from "../../../assets/europackaging/common_tick.svg";
import ScreenTitleComponent from "../common/ScreenTitleComponent";
import {Toast} from "../../dashboard/common/ToastManagerComponent";
import AuditFinalisationModalComponent from "./AuditFinalisationModalComponent";

export default class AuditQuestionListComponent extends BaseComponent {

    signatureTypes = [
        {
            id : 1,
            label : "Store Representative"
        },
        {
            id : 2,
            label : "Contractor Representative"
        },
        {
            id : 3,
            label : process.env.REACT_APP_BRAND_NAME + " Representative"
        }
    ];

    constructor(props, context) {
        super(props, context);

        let user = DataManager.getUser();

        this.initState({
            user,
            fetchListNetworkInFlight : false,
            questions : [],
            submission : DataManager.getCurrentSubmission(),
            blockerOpen : false,
            submissionCompleteNetworkInFlight : false,
            finaliseModalOpen : false,
            finaliseModalTitle : "",
            finaliseModalMessage : "",
            signatureTypes : []
        });
    }

    componentDidMount() {
        this.getAuditQuestionsFromNetwork();

        window._epShowSiteChangeButton(false);
    }

    componentWillUnmount() {
        window._epShowSiteChangeButton(true);
    }

    moveToQuestion = (siteAreaId, questionId) => {
        let destination = "/cleaning-check-question/";
        if (this.props.mode === Audit.TYPE.INSPECTION) {
            destination = "/inspection-question/";
        } else if (this.props.mode === Audit.TYPE.COVID) {
            destination = "/covid-question/"
        }

        this.props.history.push(destination + siteAreaId + "/" + questionId);
    }

    isFormComplete = () => {
        if (this.state.questions !== undefined && this.state.questions != null) {
            let questionCount = 0;
            let answeredCount = 0;

            this.state.questions.forEach((siteArea) => {
                siteArea.questions.forEach((question) => {
                    questionCount++;
                    if (question.answer !== undefined && question.answer != null) {
                        answeredCount++;
                    }
                });
            });

            return questionCount === answeredCount;
        }
        return false;
    }

    promptToFinalise = () => {
        if (this.state.questions === undefined || this.state.questions == null || this.state.questions.length === 0) {
            return;
        }

        if (!this.isFormComplete()) {
            return;
        }

        let signatureTypes = [];

        let userType = parseInt(this.state.user.userTypeId);
        if (userType === API.userTypes.STORE_CHECK || userType === API.userTypes.COVID19) {
            signatureTypes.push(this.signatureTypes[0]);
            signatureTypes.push(this.signatureTypes[1]);
        } else {
            signatureTypes.push(this.signatureTypes[0]);
            signatureTypes.push(this.signatureTypes[2]);
        }

        this.setState({
            finaliseModalOpen : true,
            finaliseModalTitle : "Finalise Check",
            finaliseModalMessage : "Are you sure you are ready to finish? Once finalised you will no longer be able to modify any answers.",
            signatureTypes
        })
    }

    finaliseCallback = (result, signatures) => {
        if (result) {
            this.completeAuditOverNetwork(signatures);
        }

        this.setState({
            finaliseModalOpen : false
        });
    }

    getAuditQuestionsFromNetwork = () => {
        if (this.state.fetchListNetworkInFlight) return;

        this.setState({
            fetchListNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("siteId", this.state.submission.siteId);
        if (this.state.submission != null) {
            formData.append("auditSubmissionId", this.state.submission.id);
        }

        Axios.post(ENDPOINTS.audit.getQuestions, formData)
            .then((r) => {
                let questions = [];

                let resp = API.parse(r);
                if (resp.success) {
                    questions = resp.data.questions;
                } else {
                    this.showError(API.formatError(resp));
                }

                DataManager.setCurrentQuestions(questions);

                this.setState({
                    fetchListNetworkInFlight : false,
                    questions : questions
                });
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Please try again later. [Q100]");
            });
    }

    completeAuditOverNetwork = (signatures) => {
        if (this.state.submissionCompleteNetworkInFlight) return;

        this.setState({
            submissionCompleteNetworkInFlight : true
        });

        this.showBlocker();

        let formData = new FormData();
        formData.append("auditSubmissionId", this.state.submission.id);

        if (signatures !== undefined) {
            formData.append("signatures", JSON.stringify(signatures));
        }

        Axios.post(ENDPOINTS.audit.finaliseAuditSubmission, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    DataManager.clearSubmission();

                    this.moveToSuccessScreen();
                } else {
                    this.showError(API.formatError(resp));
                }

                this.hideBlocker();
                this.setState({
                    submissionCompleteNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);

                this.showError("An unknown error has occurred. Please try again later. [A2600]")

                this.hideBlocker();
                this.setState({
                    submissionCompleteNetworkInFlight : false
                });
            });
    }

    showError = (message) => {
        if (this.props.hasOwnProperty("showModal")) {
            this.props.showModal("Error", message);
        }
    }

    showBlocker = () => {
        this.setState({
            blockerOpen : true
        });
    }

    hideBlocker = () => {
        this.setState({
            blockerOpen : false
        });
    }

    moveToSuccessScreen = () => {
        this.props.history.push("/audit-complete");
    }

    render() {
        let listItems = [];
        let answeredItems = 0;
        if (this.state.fetchListNetworkInFlight) {
            listItems = (
                <LoadingSpinner />
            );
        } else {
            this.state.questions.forEach((siteArea, index) => {
                let titleIcon = [];
                if (siteArea.iconUrl != null) {
                    titleIcon = (
                        <div className={"question-title-icon"} style={{backgroundImage : "url(" + siteArea.iconUrl + ")"}} />
                    )
                }

                let titleStyle = {};
                if (siteArea.textColour != null) {
                    titleStyle.color = siteArea.textColour;
                }

                if (siteArea.backgroundColour != null) {
                    titleStyle.backgroundColor = siteArea.backgroundColour;
                }

                listItems.push(
                    <div className={"question-title"} style={titleStyle}>
                        {titleIcon}
                        <div className={"question-title-text"}>{siteArea.name}</div>
                    </div>
                )

                siteArea.questions.forEach((question, index) => {
                    let checkTick = [];
                    if (question.answer !== undefined) {
                        if (question.answer != null) {
                            checkTick = (
                                <div className={"tick"} style={{backgroundImage : "url(" + checkIcon + ")"}} />
                            )
                            answeredItems++;
                        }
                    }

                    listItems.push(
                        <div className={"question-item"} onClick={() => this.moveToQuestion(siteArea.id, question.id)}>
                            <div className={"checkmark"}>
                                {checkTick}
                            </div>
                            <div className={"title"}>
                                {(index + 1)}. {question.questionText}
                            </div>
                        </div>
                    )
                })
            })
        }

        let submitButtonExtraClass = "";
        if (!this.isFormComplete()) {
            submitButtonExtraClass = " disabled";
        }

        let screenTitle = (process.env.REACT_APP_VERBIAGE_CLEANING_CHECK !== undefined) ? process.env.REACT_APP_VERBIAGE_CLEANING_CHECK : "Cleaning Check";
        let auditVerbiage = "Check";
        if (this.props.mode === Audit.TYPE.INSPECTION) {
            screenTitle = "Inspection";
            auditVerbiage = "Inspection";
        } else if (this.props.mode === Audit.TYPE.COVID) {
            screenTitle = "Cleaning Area Review";
        }

        return (
            <div className={"container audit-question-list-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent title={screenTitle} history={this.props.history} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"question-list"}>
                            {listItems}
                        </div>
                    </div>
                </div>

                <div className={"bottom-button"}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"hidden-xs col-md-2 col-lg-3"} />
                            <div className={"col-12 col-md-8 col-lg-6"}>
                                <button className={"btn btn-success btn-rounded btn-fullwidth" + submitButtonExtraClass} onClick={this.promptToFinalise}>Finalise {auditVerbiage}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <AuditFinalisationModalComponent
                    open={this.state.finaliseModalOpen}
                    title={this.state.finaliseModalTitle}
                    message={this.state.finaliseModalMessage}
                    signatureTypes={this.state.signatureTypes}
                    callback={this.finaliseCallback} />

                <UIBlockerComponent
                    shown={this.state.blockerOpen} />
            </div>
        )
    }
}