import React from 'react';
import BaseComponent from "../../BaseComponent";

import '../../common/AlertModal.css';
import "./AuditFinalisationModalComponent.css";

export default class AuditFinalisationModalComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            signatureValues : [],
            errorMessage : null
        });
    }

    setSignatureData = (signatureTypeId, value) => {
        let signatureValues = this.state.signatureValues;

        let updated = false;
        console.log("Looking for type: " + signatureTypeId);
        for (let i = 0; i < signatureValues.length; i++) {
            console.log("CMP: " + signatureValues[i].id + " :: " + (signatureValues[i].id === signatureTypeId ? "YES" : "NO"));
            if (signatureValues[i].id === signatureTypeId) {
                signatureValues[i].value = value;
                updated = true;
                break;
            }
        }

        if (!updated) {
            signatureValues.push({
                id : signatureTypeId,
                value
            });
        }

        this.setState({
            signatureValues
        });
    }

    getSignatureData = (signatureTypeId) => {
        for (let i = 0; i < this.state.signatureValues.length; i++) {
            if (this.state.signatureValues[i].id === signatureTypeId) {
                return this.state.signatureValues[i].value;
            }
        }
        return "";
    }

    submit = () => {
        if (this.props.signatureTypes !== undefined) {
            let validated = true;

            console.log(this.props.signatureTypes.length + " :: " + this.state.signatureValues.length);
            if (this.props.signatureTypes.length !== this.state.signatureValues.length) {
                validated = false;
            } else {
                this.state.signatureValues.forEach((item) => {
                    if (item.value === "") {
                        validated = false;
                        return false;
                    }
                });
            }

            if (!validated) {
                this.setState({
                    errorMessage : "Please provide all requested Signatures"
                });
                return;
            }
        }

        if (this.props.callback !== undefined) {
            this.props.callback(true, this.state.signatureValues);
        }
    }

    dismiss = () => {
        if (this.props.callback !== undefined) {
            this.props.callback(false);
        }
    }

    render() {
        let open = false;
        if (this.props.open !== undefined) {
            open = this.props.open;
        }

        if (open) {
            let message = "";
            if (this.props.message !== undefined) {
                message = this.props.message;
            }

            let signatures = [];
            if (this.props.signatureTypes !== undefined) {
                this.props.signatureTypes.forEach((signatureType) => {
                    signatures.push(
                        <div className={"signature"}>
                            <label>{signatureType.label}</label>
                            <input type={"text"} className={"form-control"} name={"signature_input_" + signatureType.id} value={this.getSignatureData(signatureType.id)} placeholder={"Please provide a name"} onChange={(e) => this.setSignatureData(signatureType.id, e.target.value)} />
                        </div>
                    )
                });
            }

            let errorMessage = [];
            if (this.state.errorMessage != null) {
                errorMessage = (
                    <div className={"alert alert-danger"}>{this.state.errorMessage}</div>
                )
            }

            return (
                <div className={"alert-modal audit-finalisation-modal-component"}>
                    <div className={"spacer"} />

                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"hidden-xs col-md-3 col-lg-4"} />
                            <div className={"col-12 col-md-6 col-lg-4"}>
                                <div className={"alert-modal-dialog"}>
                                    <div className={"alert-modal-dialog-title"}>{this.props.title}</div>
                                    <div className={"alert-modal-dialog-message"}>{message}</div>
                                    {errorMessage}
                                    {signatures}
                                    <div className={"alert-modal-dialog-buttons"}>
                                        <div className={"alert-dialog-button success"} onClick={this.submit}>Finalise</div>
                                    </div>
                                    <div className={"alert-modal-dialog-buttons"}>
                                        <div className={"alert-dialog-button danger"} onClick={this.dismiss}>Do Not Finalise</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"spacer"} />
                </div>
            );
        }
        return [];
    }
}