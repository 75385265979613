import React from 'react';
import BaseComponent from "../../../BaseComponent";

export default class MediaViewerYouTubeComponent extends BaseComponent {

    render() {
        let title = "Video Guide";
        if (this.props.title !== undefined) {
            title = this.props.title;
        }

        return (
            <div className={"card"}>
                <div className="card-header">
                    {title}
                </div>
                <div className="card-body">
                    <div className={"embed-responsive embed-responsive-16by9 video-player-container"}>
                        <iframe
                            title={"Video"}
                            src={"https://www.youtube.com/embed/" + this.props.videoId}
                            className={"video-player"}
                            frameBorder="0" />
                    </div>

                </div>
            </div>
        )
    }

}