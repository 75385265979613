import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import ScreenSearchComponent from "../common/ScreenSearchComponent";

import "./ContractorHoursLandingComponent.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Chronos} from "../../../data/Chronos";
import {TablePaginator} from "../../common/TableComponent";
import {Toast} from "../common/ToastManagerComponent";

export default class ContractorHoursLandingComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let startYear = 2021;
        let currentYear = parseInt(Chronos.now().format("yyyy"));

        let selectableYears = [];

        for (let i = currentYear; i >= startYear; i--) {
            selectableYears.push(i);
        }

        this.initState({
            currentPage : 1,
            currentYear,
            selectableYears,
            tableKeyword : null,
            tableData : [],
            tableCount : 0,
            tableLimit : 20,
            contractorHoursDataInFlight : false
        });
    }

    componentDidMount() {
        this.getContractorHoursTableDataFromNetwork();
    }

    tableFilterDidChange = (keyword) => {
        this.setState({
            currentPage : 1,
            tableKeyword : keyword
        });

        this.getContractorHoursTableDataFromNetwork(1, undefined, keyword);
    }

    tablePageDidChange = (page) => {
        this.setState({
            currentPage : page
        });

        this.getContractorHoursTableDataFromNetwork(page);
    }

    yearDidChange = (year) => {
        this.setState({
            currentYear : year
        });

        this.getContractorHoursTableDataFromNetwork(undefined, year);
    }

    getContractorHoursTableDataFromNetwork = (page, year, keyword) => {
        if (this.state.contractorHoursDataInFlight) return;

        this.setState({
            contractorHoursDataInFlight : true
        });

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (year === undefined) {
            year = this.state.currentYear;
        }

        if (keyword === undefined) {
            keyword = this.state.tableKeyword;
        }

        let formData = new FormData();
        formData.append("page", page);
        formData.append("year", year);
        if (keyword != null && keyword !== undefined) {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.contractorHours.getContractorHourTableData, formData)
            .then((r) => {
                let tableData = [];
                let tableCount = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.data;
                    tableCount = resp.data.count;
                } else {
                    Toast.show("Error", resp.error.desc, Toast.TYPE_ERROR);
                }

                this.setState({
                    contractorHoursDataInFlight : false,
                    tableData,
                    tableCount
                });
            })
            .catch((e) => {
                Toast.show("Error", "An unknown error has occurred. Please try again later.", Toast.TYPE_ERROR);
            });
    }

    render() {
        let weekHeadings = [];
        for (let i = 1; i <= 52; i++) {
            weekHeadings.push(
                <th><span className={"text text-center"}>Week {i}</span></th>
            )
        }

        let weekRows = [];
        if (this.state.tableData.length > 0) {
            this.state.tableData.forEach((site) => {
                let tds = [];

                site.weeks.forEach((week) => {
                    tds.push(
                        <td><span className={"text text-center"}>{week}</span></td>
                    )
                })

                weekRows.push(
                    <tr>{tds}</tr>
                )
            });
        } else {
            weekRows.push(
                <tr>
                    <td colspan={52}><em>No Data to display</em></td>
                </tr>
            )
        }

        return (
            <div className={"container-flex contractor-hours-landing-component"}>
                <div className={"row"}>
                    <div className={"col-12 screen-actions text-right"}>
                        <button className={"btn btn-primary"}>Weekly Hour Upload</button>
                    </div>
                </div>

                <div className={"row form-block"}>
                    <div className={"col-12 col-md-4"}>
                        <label>Show Data for Year</label>
                        <select className={"form-control"} value={this.state.currentYear} onChange={(e) => this.yearDidChange(e.target.value)}>
                            {
                                this.state.selectableYears.map((year) => (
                                    <option value={year}>{year}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <label>&nbsp;</label>
                        <ScreenSearchComponent
                            callback={this.tableFilterDidChange} />
                    </div>
                </div>

                <div className={"row form-block"}>
                    <div className={"col-12"}>

                        <div className={"segmented-table-container"}>
                            <div className={"equal-width"}>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        <th><span className={"text"}>Asset Name</span></th>
                                        <th><span className={"text"}>Contractor</span></th>
                                        <th><span className={"text"}>{process.env.REACT_APP_BRAND_NAME} Region</span></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.tableData.map((site) => (
                                            <tr>
                                                <td><span className={"text"}>{site.siteName}</span></td>
                                                <td><span className={"text"}>{site.contractorName}</span></td>
                                                <td><span className={"text"}>{site.regionName}</span></td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className={"centre-width"}>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        {weekHeadings}
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {weekRows}
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.currentPage}
                                totalCount={this.state.tableCount}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>
                    </div>
                </div>

            </div>
        )

    }
}