import React from 'react';
import BaseComponent from "../../BaseComponent";
import {DataManager} from "../../../data/DataManager";
import {API, ENDPOINTS} from "../../../network/API";
import Axios from "axios";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";

export default class SettingsUserDetailsComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            userNetworkInFlight : false
        });
    }

    componentDidMount() {
        let user = DataManager.getUser();
        if (user != null) {
            this.getUserFromNetwork();
        }
    }

    populateUserIntoState = (user) => {
        if (user != null) {
            this.setState({
                userEmail: user.emailAddress,
                userGivenName: user.givenName,
                userFamilyName: user.familyName,
                userEmployeeId: user.employeeId,
                userUserType: user.userType
            });
        }
    }

    getUserFromNetwork = () => {
        if (this.state.userNetworkInFlight) return;

        this.setState({
            userNetworkInFlight : true
        });

        let user = DataManager.getUser();

        let formData = new FormData();
        formData.append("id", user.id);

        Axios.post(ENDPOINTS.user.getUser, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateUserIntoState(resp.data.user);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    userNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("Could not load User. An unknown error has occurred. Please try again later.");

                this.setState({
                    userNetworkInFlight : false
                });
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        return (
            <div className={"form-inputs"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>User Details</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>Forename</label>
                        <input type={"text"} className={"form-control"} name={"userGivenName"} value={this.state.userGivenName} onChange={this.handleChange} readOnly={true} />
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <label>Surname</label>
                        <input type={"text"} className={"form-control"} name={"userFamilyName"} value={this.state.userFamilyName} onChange={this.handleChange} readOnly={true} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>Email Address</label>
                        <input type={"email"} className={"form-control"} name={"userEmail"} value={this.state.userEmail} onChange={this.handleChange} readOnly={true} />
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <label>Employee ID</label>
                        <input type={"text"} className={"form-control"} name={"userEmployeeId"} value={this.state.userEmployeeId} onChange={this.handleChange} readOnly={true} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>User Type</label>
                        <input type={"text"} className={"form-control"} name={"userUserType"} value={this.state.userUserType} onChange={this.handleChange} readOnly={true} />
                    </div>
                </div>

                <div className={"row actions-area"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-primary"}>Change Password</button>
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.userNetworkInFlight} />
            </div>
        )
    }

}