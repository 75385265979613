import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import ScreenSearchComponent from "../common/ScreenSearchComponent";

import "./DashboardHomeComponent.css";
import EstateReportTableComponent from "../../common/estatereport/EstateReportTableComponent";
import ExportDataWidgetComponent from "../../common/estatereport/ExportDataWidgetComponent";
import {DataManager} from "../../../data/DataManager";
import {API} from "../../../network/API";

export default class DashboardHomeComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        // If the incoming user is a Contractor Manager, redirect to the right starting screen
        let redirectUserLocation = undefined;
        let user = DataManager.getUser();
        if (parseInt(user.userTypeId) === API.userTypes.CONTRACTOR_MANAGER) {
            redirectUserLocation = "/contractor";
        }

        this.initState({
            tableKeyword : null,
            redirectUserLocation
        });
    }

    componentDidMount() {
        if (this.state.redirectUserLocation !== undefined) {
            this.props.history.push(this.state.redirectUserLocation);
        }
    }

    searchKeywordDidChange = (keyword) => {
        this.setState({
            currentPage : 1,
            tableKeyword : keyword
        });
    }

    render() {
        if (this.state.redirectUserLocation !== undefined) {
            return [];
        }

        return (
            <div className={"container dashboard-home-component form-inputs"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-4"}>
                        <ScreenTitleComponent
                            title={"Estate Report"}
                            showBack={false}
                            {...this.props} />
                    </div>
                    <div className={"col-12 col-md-4 screen-actions"}>
                        <ScreenSearchComponent
                            callback={(keyword) => { this.searchKeywordDidChange(keyword) }}/>
                    </div>
                    <div className={"col-12 col-md-4 screen-actions text-right"}>
                        <ExportDataWidgetComponent {...this.props} />
                    </div>
                </div>

                <EstateReportTableComponent
                    tableKeyword={this.state.tableKeyword}
                    {...this.props} />
            </div>
        )

    }
}