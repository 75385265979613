import React from 'react';
import BaseComponent from "../../BaseComponent";
import {DataManager} from "../../../data/DataManager";

import "./AuditQuestionInputComponent.css";
import {Question} from "../../../data/Question";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import ImageLightboxComponent from "../common/ImageLightboxComponent";

import fullscreenIcon from "../../../assets/europackaging/fullscreen.svg";
import trashIcon from "../../../assets/europackaging/trash_white.svg";
import UploadProgressComponent from "../common/UploadProgressComponent";
import UIBlockerComponent from "../common/UIBlockerComponent";
import {Audit} from "../../../data/Audit";

import thumbsUp from "../../../assets/europackaging/thumbs_up.svg";
import thumbsDown from "../../../assets/europackaging/thumbs_down.svg";
import average from "../../../assets/europackaging/average.svg";
import ScreenTitleComponent from "../common/ScreenTitleComponent";
import imageCompression from "browser-image-compression";

export default class AuditQuestionInputComponent extends BaseComponent {

    inputItems = [
        {
            outcomeTypeId : Question.OUTCOMES.GOOD,
            buttonClass : "btn-success",
            label : "Good"
        },
        {
            outcomeTypeId : Question.OUTCOMES.AVERAGE,
            buttonClass : "btn-secondary",
            label : "Average"
        },
        {
            outcomeTypeId : Question.OUTCOMES.BAD,
            buttonClass : "btn-danger",
            label : "Bad"
        }
    ]

    photoFilePicker = undefined;

    imagesToUpload = [];
    imagesToDelete = [];
    currentImageTempId = -1;

    constructor(props, context) {
        super(props, context);

        let siteAreaId = this.getUriProperty("siteAreaId");
        let questionId = this.getUriProperty("questionId");

        let question = this.getQuestion(siteAreaId, questionId);

        if (question == null) {
            //this.props.history.pop();
            console.log("UH OH");
        }

        this.initState({
            submission : DataManager.getCurrentSubmission(),
            siteAreaId : siteAreaId,
            questionId : questionId,
            question : question,
            answer : null,
            images : [],
            selectedOutcomeId : -1,
            inputComments : "",
            answerNetworkInFlight : false,
            answerSubmissionNetworkInFlight : false,
            lightboxOpen : false,
            lightboxImageUrl : null,
            lightboxButtons : [],
            lightboxComments : undefined,
            progressOpen : false,
            progressTitle : "",
            progressMessage : "",
            progressProgress : 0
        });

        this.photoFilePicker = React.createRef();
    }

    componentDidMount() {
        this.getAnswerFromNetwork();

        window._epShowSiteChangeButton(false);
    }

    componentWillUnmount() {
        window._epShowSiteChangeButton(true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let oldQuestionId = this.getUriProperty("questionId", null, prevProps);
        let newQuestionId = this.getUriProperty("questionId", null);

        if (oldQuestionId !== null) {
            if (oldQuestionId !== newQuestionId) {
                let siteAreaId = this.getUriProperty("siteAreaId", null);

                let question = this.getQuestion(siteAreaId, newQuestionId);

                this.setState({
                    siteAreaId: siteAreaId,
                    questionId: newQuestionId,
                    question: question,
                    answer: null,
                    images: [],
                    selectedOutcomeId: -1,
                    inputComments: ""
                });

                this.imagesToUpload = [];
                this.imagesToDelete = [];
                this.currentImageTempId = -1;

                this.getAnswerFromNetwork(question.id);
            }
        }
    }

    getSiteArea = (siteAreaId) => {
        let questions = DataManager.getCurrentQuestions();
        if (questions != null) {
            for (let i = 0; i < questions.length; i++) {
                if (questions[i].id == siteAreaId) {
                    return questions[i];
                }
            }
        }
    }

    getQuestion = (siteAreaId, questionId) => {
        let siteArea = this.getSiteArea(siteAreaId);
        for (let x = 0; x < siteArea.questions.length; x++) {
            if (siteArea.questions[x].id == questionId) {
                let output = siteArea.questions[x];
                output.questionIndex = x;
                output.siteAreaName = siteArea.name;
                output.siteAreaId = siteArea.id;
                return output;
            }
        }

        return null;
    }

    moveToNextQuestion = () => {
        let nextIndex = this.state.question.questionIndex + 1;

        let siteArea = this.getSiteArea(this.state.question.siteAreaId);

        let destination = "";

        if (nextIndex < siteArea.questions.length) {
            let question = siteArea.questions[nextIndex];

            if (this.props.mode === Audit.TYPE.CLEANING_CHECK) {
                destination = "/cleaning-check-question/";
            } else if (this.props.mode === Audit.TYPE.COVID) {
                destination = "/covid-question/";
            } else {
                destination = "/inspection-question/";
            }

            destination += siteArea.id + "/" + question.id;
            
            this.props.history.push(destination);
        } else {
            this.moveToQuestionList();
        }
    }

    moveToCleaningSpecification = () => {
        this.props.history.push("/cleaning-specifications/" + this.state.question.siteAreaId);
    }

    moveToQuestionList = () => {
        let destination = "";
        if (this.props.mode === Audit.TYPE.CLEANING_CHECK) {
            destination = "/cleaning-checks";
        } else if (this.props.mode === Audit.TYPE.COVID) {
            destination = "/covid-checks/";
        } else {
            destination = "/site-inspections/";
        }

        this.props.history.push(destination);
    }

    selectOutcome = (outcomeId, closeLightbox) => {
        if (closeLightbox !== undefined) {
            if (closeLightbox) {
                this.dismissImageLightbox();
            }
        }

        this.setState({
            selectedOutcomeId : parseInt(outcomeId)
        });
    }

    photoOptionWasClicked = () => {
        this.photoFilePicker.current.click();
    }

    photoFileDidChange = (e) => {
        let elem = e.target;

        if (elem.files.length > 0) {
            let imageFile = elem.files[0];

            // Add Image to queue
            this.imagesToUpload.push(imageFile);

            // Load image and display thumbnail until upload
            let fileReader = new FileReader();
            fileReader.onload = () => {
                let imageBase64 = fileReader.result;

                let stateImages = this.state.images;
                stateImages.push({
                    id : this.currentImageTempId,
                    imagePath : imageBase64,
                    thumbPath : imageBase64,
                    imageFile : imageFile
                });
                this.setState({
                    images : stateImages
                });

                // Decrement temporary ID
                this.currentImageTempId--;
            };
            fileReader.readAsDataURL(imageFile);
        }
    }

    handleImageRemoval = (image) => {
        this.props.showModal("Confirmation", "Are you sure you want to remove this image?", [
            {
                label : "Delete",
                className : "danger",
                click : () => {
                    this.props.hideModal();
                    this.executeImageRemoval(image);
                }
            },
            {
                label : "Cancel",
                click : () => {
                    this.props.hideModal();
                }
            }
        ]);
    }

    executeImageRemoval = (image) => {
        if (image.id <= 0) {
            if (image.imageFile !== undefined) {
                for (let i = 0; i < this.imagesToUpload.length; i++) {
                    if (this.imagesToUpload[i] === image.imageFile) {
                        this.imagesToUpload.splice(i, 1);
                        break;
                    }
                }
            }
        } else {
            this.imagesToDelete.push(image.id);
        }

        let images = this.state.images;
        for (let i = 0; i < images.length; i++) {
            if (images[i].id === image.id) {
                images.splice(i, 1);
            }
        }
        this.setState({
            images : images
        });

        console.log(this.imagesToUpload);
    }

    findOutcomeImage = (outcomeId) => {
        if (this.state.question !== undefined && this.state.question != null) {
            if (this.state.question.hasOwnProperty("outcomeImages")) {
                for (let i = 0; i < this.state.question.outcomeImages.length; i++) {
                    let image = this.state.question.outcomeImages[i];
                    if (image != null) {
                        if (image.auditQuestionOutcomeId == outcomeId) {
                            return image;
                        }
                    }
                }
            }
        }
        return null;
    }

    showQuestionImage = (e, image) => {
        e.stopPropagation();

        if (image === undefined || image == null) {
            return;
        }

        let outcomeId = parseInt(image.auditQuestionOutcomeId);
        let comments = this.getOutcomeComments(outcomeId);

        let button = null;
        if (outcomeId === Question.OUTCOMES.GOOD) {
            button = {
                label : this.getOutcomeLabel(outcomeId),
                className : "btn-success",
                click : () => {
                    this.selectOutcome(Question.OUTCOMES.GOOD, true)
                }
            };
        } else if (outcomeId === Question.OUTCOMES.AVERAGE) {
            button = {
                label : this.getOutcomeLabel(outcomeId),
                className : "btn-secondary",
                click : () => {
                    this.selectOutcome(Question.OUTCOMES.AVERAGE, true);
                }
            }
        } else if (outcomeId === Question.OUTCOMES.BAD) {
            button = {
                label : this.getOutcomeLabel(outcomeId),
                className : "btn-danger",
                click : () => {
                    this.selectOutcome(Question.OUTCOMES.BAD, true);
                }
            }
        }

        this.showImageLightbox(image.publicFullPath, [button], comments);
    }

    showImageLightbox = (imageUrl, buttons, comments) => {
        this.setState({
            lightboxOpen : true,
            lightboxImageUrl : imageUrl,
            lightboxButtons : buttons,
            lightboxComments : comments
        });
    }

    dismissImageLightbox = () => {
        console.log("DISMISS!");
        this.setState({
            lightboxOpen : false
        });
    }

    showProgress = (title, message) => {
        this.setState({
            progressOpen : true,
            progressTitle : title,
            progressMessage : message,
            progressProgress : 0
        });
    }

    notifyProgress = (progress) => {
        this.setState({
            progressProgress : progress
        });
    }

    dismissProgress = () => {
        this.setState({
            progressOpen : false
        });
    }

    getAnswerFromNetwork = (questionId) => {
        if (this.state.answerNetworkInFlight) return;

        this.setState({
            answerNetworkInFlight : true
        });

        if (questionId === undefined) {
            questionId = this.state.question.id;
        }

        let formData = new FormData();
        formData.append("siteAreaId", this.state.question.siteAreaId);
        formData.append("auditSubmissionId", this.state.submission.id);
        formData.append("auditQuestionId", questionId);

        Axios.post(ENDPOINTS.audit.getAnswer, formData)
            .then((r) => {
                let answer = null;
                let images = [];
                let comments = "";
                let outcomeId = -1;

                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.answer != null) {
                        answer = resp.data.answer;
                        comments = resp.data.answer.comments;
                        outcomeId = parseInt(resp.data.answer.auditQuestionOutcomeId);

                        if (resp.data.answer.images !== undefined) {
                            images = [];

                            for (let i = 0; i < resp.data.answer.images.length; i++) {
                                let image = resp.data.answer.images[i];

                                images.push({
                                    id: image.id,
                                    imagePath: image.publicFullPath,
                                    thumbPath: image.publicThumbPath
                                });
                            }
                        }
                    }
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    answerNetworkInFlight : false,
                    answer : answer,
                    images : images,
                    inputComments : comments,
                    selectedOutcomeId : outcomeId
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    skipQuestion = () => {
        this.selectOutcome(Question.OUTCOMES.NA);
        setTimeout(() => {
            this.submitQuestionAnswerOverNetwork();
        }, 100);
    }

    submitQuestionAnswerOverNetwork = () => {
        if (this.state.answerSubmissionNetworkInFlight) return;


        // Outcome must be selected
        if (this.state.selectedOutcomeId === -1) {
            return;
        }

        // Do not allow Bad Outcome Question to be submitted without an image being selected
        if ((this.state.selectedOutcomeId === Question.OUTCOMES.BAD || this.state.selectedOutcomeId === Question.OUTCOMES.AVERAGE) && (this.state.images.length === 0 || this.state.inputComments === "")) { //
            return;
        }

        this.setState({
            answerSubmissionNetworkInFlight : true
        });

        let formData = new FormData();

        if (this.state.answer != null) {
            formData.append("auditAnswerId", this.state.answer.id);
        }

        formData.append("siteAreaId", this.state.question.siteAreaId);
        formData.append("auditSubmissionId", this.state.submission.id);
        formData.append("auditQuestionId", this.state.question.id);
        formData.append("auditQuestionOutcomeId", this.state.selectedOutcomeId);
        formData.append("comments", this.state.inputComments);
        formData.append("deletedAnswerImageIds", JSON.stringify(this.imagesToDelete));

        Axios.post(ENDPOINTS.audit.submitAuditAnswer, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.uploadAnswerImage(resp.data.answer.id,0);

                    this.setState({
                        answerSubmissionNetworkInFlight : false,
                        answer : resp.data.answer
                    });
                } else {
                    this.showError(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later. [A2400]");
            });
    }

    uploadAnswerImage = (answerId, fileIndex) => {
        if (fileIndex >= this.imagesToUpload.length) {
            this.dismissProgress();
            this.moveToNextQuestion();
            return;
        }

        if (fileIndex === 0) {
            this.showProgress("Uploading Images", "Please wait, uploading your images...");
        }

        imageCompression(this.imagesToUpload[fileIndex], {
                maxSizeMB: 1, // 1MB
                maxWidthOrHeight : 1280, // 720p
                useWebWorker : true,
                initialQuality : 0.9
            })
            .then((output) => {
                let formData = new FormData();
                formData.append("auditSubmissionAnswerId", answerId);
                formData.append("image", output);

                let config = {
                    onUploadProgress: (progressEvent) => {
                        let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                        // This progress will consider ALL images that need to be uploaded.
                        let progress = (100 * fileIndex) + Math.ceil((progressEvent.loaded / progressTotal) * 100) / this.imagesToUpload.length;

                        this.notifyProgress(progress);
                    }
                }

                Axios.post(ENDPOINTS.audit.uploadAuditAnswerImage, formData, config)
                    .then((r) => {
                        let resp = API.parse(r);
                        if (resp.success) {
                            this.uploadAnswerImage(answerId, fileIndex + 1);
                        } else {
                            this.props.showModal("Error", API.formatError(resp), [
                                {
                                    label : "Retry",
                                    className : "success",
                                    click : () => {
                                        this.props.hideModal();
                                        this.uploadAnswerImage(answerId, fileIndex);
                                    }
                                },
                                {
                                    label : "Skip",
                                    className : "danger",
                                    click : () => {
                                        this.props.hideModal();
                                        this.uploadAnswerImage(answerId, fileIndex + 1);
                                    }
                                }
                            ]);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.props.showModal("Error", "An unknown error occurred while uploading an image. Please try again. [A2500]", [
                            {
                                label : "Retry",
                                className : "success",
                                click : () => {
                                    this.props.hideModal();
                                    this.uploadAnswerImage(answerId, fileIndex);
                                }
                            },
                            {
                                label : "Skip",
                                className : "danger",
                                click : () => {
                                    this.props.hideModal();
                                    this.uploadAnswerImage(answerId, fileIndex + 1);
                                }
                            }
                        ]);
                    })
            })
    }

    showError(message) {
        this.props.showModal("Error", message);
    }

    getOutcomeLabel = (outcomeTypeId) => {
        switch (outcomeTypeId) {
            case Question.OUTCOMES.GOOD:
                return this.getOrDefaultNotNull(this.state.question, "goodLabel", "Good");
            case Question.OUTCOMES.AVERAGE:
                return this.getOrDefaultNotNull(this.state.question, "averageLabel", "Average");
            case Question.OUTCOMES.BAD:
                return this.getOrDefaultNotNull(this.state.question, "badLabel", "Bad");
            case Question.OUTCOMES.NA:
                return this.getOrDefaultNotNull(this.state.question, "skipLabel", "Skip this Question");
            default:
                return "";
        }
    }

    getOutcomeComments = (outcomeTypeId) => {
        switch (outcomeTypeId) {
            case Question.OUTCOMES.GOOD:
                return this.getOrDefaultNotNull(this.state.question, "goodExtraLabel", undefined);
            case Question.OUTCOMES.AVERAGE:
                return this.getOrDefaultNotNull(this.state.question, "averageExtraLabel", undefined);
            case Question.OUTCOMES.BAD:
                return this.getOrDefaultNotNull(this.state.question, "badExtraLabel", undefined);
            default:
                return undefined;
        }
    }

    render() {
        if (this.state.question == null) {
            return [];
        }

        let inputs = this.inputItems.map((input) => {
            let classAdditional = "";
            if (this.state.selectedOutcomeId === input.outcomeTypeId) {
                classAdditional = " active";
            }

            let imageUrl = null;
            let image = null;
            if (this.props.mode !== Audit.TYPE.INSPECTION) {
                image = this.findOutcomeImage(input.outcomeTypeId);
                if (image != null) {
                    imageUrl = image.publicThumbPath;
                }
            }

            let imageElem = [];
            if (imageUrl != null) {
                imageElem = (
                    <div className={"embed-responsive embed-responsive-1by1 input-image"} style={{backgroundImage : "url(" + imageUrl + ")"}}>
                        <div className={"fullscreen-icon"} onClick={(e) => this.showQuestionImage(e, image)} style={{backgroundImage : "url(" + fullscreenIcon + ")"}} />
                    </div>
                );
            } else {
                let defaultIcon = average;
                if (input.outcomeTypeId === Question.OUTCOMES.GOOD) {
                    defaultIcon = thumbsUp;
                } else if (input.outcomeTypeId === Question.OUTCOMES.BAD) {
                    defaultIcon = thumbsDown;
                }

                imageElem = (
                    <div className={"embed-responsive embed-responsive-1by1 input-image input-image-icon"} style={{backgroundImage : "url(" + defaultIcon + ")"}}/>
                );
            }

            let buttonLabel = this.getOutcomeLabel(input.outcomeTypeId);
            let buttonClass = "";
            if (buttonLabel.length > 15) {
                buttonClass = " button-xsmall-label";
            } else if (buttonLabel.length > 10) {
                buttonClass = " button-small-label";
            }

            return (<div className={"col-4 input-item-wrapper"}>
                <div className={"input-item " + classAdditional} onClick={() => this.selectOutcome(input.outcomeTypeId)}>
                    {imageElem}
                    <button className={"btn " + input.buttonClass + " btn-fullwidth" + buttonClass}>{buttonLabel}</button>
                </div>
            </div>)
        })

        let selectedOutcomeId = parseInt(this.state.selectedOutcomeId);
        let submitButtonEnabled = (parseInt(this.state.selectedOutcomeId) !== -1 && !this.state.answerSubmissionNetworkInFlight);
        if ((this.state.selectedOutcomeId === Question.OUTCOMES.BAD || this.state.selectedOutcomeId === Question.OUTCOMES.AVERAGE) && (this.state.images.length === 0 || this.state.inputComments === "")) { //
            submitButtonEnabled = false;
        }
        let submitButtonClass = "";
        if (!submitButtonEnabled) {
            submitButtonClass = "disabled";
        }

        let extraInputsExtraClass = " expanded";
        let commentsLabel = (<span className={"badge"}>Comments</span>);
        let photosLabel = (<span className={"badge"}>Photo Uploads</span>);

        if (selectedOutcomeId === Question.OUTCOMES.BAD || selectedOutcomeId === Question.OUTCOMES.AVERAGE) { //
            commentsLabel = (<span className={"badge badge-danger"}>Comments (Required)</span> )
            photosLabel = (<span className={"badge badge-danger"}>Photo Uploads (Please provide at least 1 photo)</span>);
        }

        let skippedWarning = [];
        if (selectedOutcomeId === Question.OUTCOMES.NA) {
            skippedWarning = (
                <div className={"row form-block"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6 text-center"}>
                        <div className={"alert alert-warning"}>This question was skipped.</div>
                    </div>
                </div>
            )
        }

        let skipButton = [];
        if (this.state.question.skippable !== undefined && parseInt(this.state.question.skippable) === 1) {
            skipButton = (
                <button className={"btn btn-warning btn-rounded btn-fullwidth form-block"} onClick={this.skipQuestion}>{this.getOutcomeLabel(Question.OUTCOMES.NA)}</button>
            );
        }

        return (
            <div className={"container audit-question-input-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent title={this.state.question.siteAreaName} history={this.props.history} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <strong>Question {(this.state.question.questionIndex + 1)}</strong>
                            </div>
                            <div className={"card-body"}>
                                {this.state.question.questionText}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row form-block"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <button className={"btn btn-primary btn-rounded btn-fullwidth"} onClick={this.moveToCleaningSpecification}>View Cleaning Spec</button>
                    </div>
                </div>

                {skippedWarning}

                <div className={"row input-area"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <div className={"row input-items"}>
                            {inputs}
                        </div>
                    </div>
                </div>

                <div className={"row extra-inputs-area" + extraInputsExtraClass}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label>{commentsLabel}</label>
                                <textarea className={"form-control"} name={"inputComments"} value={this.state.inputComments} onChange={this.handleChange} placeholder={"Provide some comments about what could do with improving"} />
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label>{photosLabel}</label>
                                <div className={"photo-thumb-gallery"}>
                                    {
                                        this.state.images.map((image) =>
                                            <div className={"photo-thumb"} style={{backgroundImage : "url(" + image.thumbPath + ")"}}>
                                                <div className={"photo-thumb-delete"} style={{backgroundImage : "url(" + trashIcon + ")"}} onClick={() => this.handleImageRemoval(image)} />
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={"file-hide"}>
                                    <input type={"file"} ref={this.photoFilePicker} onChange={this.photoFileDidChange} accept={".jpg, .jpeg, .png, .gif, image/*"} />
                                </div>
                                <button className={"btn btn-primary btn-rounded btn-fullwidth"} onClick={this.photoOptionWasClicked}>Add Photo</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row submit-area"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <button className={"btn btn-success btn-rounded btn-fullwidth " + submitButtonClass} onClick={this.submitQuestionAnswerOverNetwork}>Confirm Choice</button>
                        {skipButton}
                        <button className={"btn btn-secondary btn-rounded btn-fullwidth form-block"} onClick={this.moveToQuestionList}>Return to Question List</button>
                    </div>
                </div>

                <ImageLightboxComponent
                    open={this.state.lightboxOpen}
                    imageUrl={this.state.lightboxImageUrl}
                    comments={this.state.lightboxComments}
                    buttons={this.state.lightboxButtons}
                    dismissCallback={this.dismissImageLightbox} />

                <UploadProgressComponent
                    open={this.state.progressOpen}
                    title={this.state.progressTitle}
                    message={this.state.progressMessage}
                    progress={this.state.progressProgress} />

                <UIBlockerComponent
                    shown={this.state.answerNetworkInFlight} />
            </div>
        )
    }
}