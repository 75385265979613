import React from 'react';
import BaseComponent from "../../BaseComponent";

import "./SiteInfoBarComponent.css";
import {Link} from "react-router-dom";
import {DataManager} from "../../../data/DataManager";
import {API} from "../../../network/API";

export default class SiteInfoBarComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            user : DataManager.getUser(),
            shouldShowChangeButton : true
        });

        this.outLink = React.createRef();
    }

    componentDidMount() {
        window._epShowSiteChangeButton = (state) => {
            this.setState({
                shouldShowChangeButton : state
            });
        }
    }

    componentWillUnmount() {
        window._epShowSiteChangeButton = undefined;
    }

    changeSiteWasClicked = () => {
        if (window._epSiteChangeCallback !== undefined) {
            window._epSiteChangeCallback();
        } else {
            this.outLink.current.click();
        }
    }

    render() {
        let siteName = "";
        if (this.props.site !== undefined && this.props.site != null) {
            siteName = this.props.site.name;
        } else {
            let site = DataManager.getCurrentSite();
            if (site != null) {
                siteName = site.name;
            }
        }

        let changeButton = [];
        let userTypeId = parseInt(this.state.user.userTypeId);

        if (this.state.shouldShowChangeButton) {
            if (userTypeId !== API.userTypes.STORE_CHECK && userTypeId !== API.userTypes.COVID19) {
                changeButton = (
                    <span className={"site-button"} onClick={this.changeSiteWasClicked}>Change Asset</span>
                );
            }
        }

        return (
            <div className={"site-info-bar-component"}>
                <div className={"container"}>
                    <div className={"site-bar"}>
                        <div className={"site-display"}>{siteName}</div>
                        {changeButton}
                    </div>
                </div>
                <div className={"file-hide"}>
                    <Link to={"/site-change"} ref={this.outLink}>&nbsp;</Link>
                </div>
            </div>
        );
    }

}