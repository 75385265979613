import React from 'react';
import BaseComponent from "../../BaseComponent";


import "./SiteConfigurationStatisticsComponent.css";
import SiteConfigurationStatisticsVerticalGraphComponent from "./SiteConfigurationStatisticsVerticalGraphComponent";
import {Audit} from "../../../data/Audit";
import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Chronos} from "../../../data/Chronos";
import DatePicker from "react-datepicker";
import {Question} from "../../../data/Question";
import ImageGalleryComponent from "../common/ImageGalleryComponent";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";
import {Toast} from "../common/ToastManagerComponent";

export default class SiteConfigurationStatisticsComponent extends BaseComponent {

    auditTypes = [
        {id : Audit.TYPE.CLEANING_CHECK, label : "Store Check"},
        {id : Audit.TYPE.INSPECTION, label : "Management Inspection"},
        {id : Audit.TYPE.CONTRACTOR, label : "Contractor Inspection"},
        {id : Audit.TYPE.COVID, label : "Cleaning Area Review"}
    ];

    constructor(props, context) {
        super(props, context);

        this.initState({
            tableData : null,
            tableCount : 0,
            sortedColumn : null,
            tablePage : 1,
            tableLimit : 20,
            startDate : Chronos.now().add(-1, Chronos.WEEKS).getDate(),
            endDate : Chronos.now().getDate(),
            siteAreas : [],
            answerImagesShown : false,
            answerImages : [],
            exportDownloadUrl : null,
            exportDownloadFilename: null,
            forceGraphUpdate : undefined
        });

        this.exportDownloadElem = React.createRef();
    }

    componentDidMount() {
        if (this.props.site !== undefined && this.props.site != null) {
            this.fetchSiteAreasFromNetwork();
            this.getAnswerTableData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.site != null && this.props.site !== prevProps.site) {
            this.fetchSiteAreasFromNetwork();
            this.getAnswerTableData()
        }
    }

    getAnswerTableData = (page, auditTypeId, siteAreaId, startDate, endDate, sorting, keyword) => {
        if (this.state.answerTableNetworkInFlight) return;
        this.setState({
            answerTableNetworkInFlight : true
        });

        if (page === undefined) {
            page = this.state.tablePage;
        }

        if (auditTypeId === undefined) {
            auditTypeId = this.state.auditTypeId;
        }

        if (siteAreaId === undefined) {
            siteAreaId = this.state.siteAreaId;
        }

        if (startDate === undefined) {
            startDate = this.state.startDate;
        }

        if (endDate === undefined) {
            endDate = this.state.endDate;
        }

        if (sorting === undefined) {
            sorting = this.state.sortedColumn;
        }

        if (keyword === undefined) {
            keyword = this.state.tableKeyword;
        }

        let formData = new FormData();
        formData.append("siteId", this.props.site.id);
        formData.append("page", page);
        if (auditTypeId !== undefined) {
            formData.append("auditTypeId", auditTypeId);
        }

        if (siteAreaId !== undefined) {
            formData.append("siteAreaId", siteAreaId);
        }

        if (startDate !== undefined) {
            formData.append("startDate", Chronos.with(startDate).seconds());
        }

        if (endDate !== undefined) {
            formData.append("endDate", Chronos.with(endDate).seconds());
        }

        if (sorting !== undefined && sorting !== null) {
            formData.append("sorting", JSON.stringify(sorting));
        }

        if (keyword !== undefined) {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.audit.getAuditAnswerTableData, formData)
            .then((r) => {
                let tableData = null;
                let tableCount = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.result;
                    tableCount = parseInt(resp.data.count);
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    answerTableNetworkInFlight : false,
                    tableData,
                    tableCount
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    dateDidChange(type, date) {
        if (type === "start") {
            this.setState({
                startDate : date
            });

            this.getAnswerTableData(undefined, undefined, undefined, date);
        } else {
            this.setState({
                endDate : date
            });

            this.getAnswerTableData(undefined, undefined, undefined, undefined, date);
        }
    }

    tableControlDidChange = (e) => {
        this.handleChange(e);

        let siteAreaId = undefined;
        let auditTypeId = undefined;

        if (e.target !== undefined) {
            if (e.target.name === "auditTypeId") {
                auditTypeId = parseInt(e.target.value);
            } else if (e.target.name === "siteAreaId") {
                siteAreaId = parseInt(e.target.value);
            }
        }

        if (siteAreaId !== undefined || auditTypeId !== undefined) {
            this.setState({
                tablePage : 1
            });
            this.getAnswerTableData(1, auditTypeId, siteAreaId);
        }
    }

    tableDidSort = (sorting) => {
        this.setState({
            sortedColumn : sorting
        });

        this.getAnswerTableData(undefined, undefined, undefined, undefined, undefined, sorting);
    }

    tablePageDidChange = (page) => {
        this.setState({
            tablePage : page
        });

        this.getAnswerTableData(page);
    }

    fetchSiteAreasFromNetwork = () => {
        let formData = new FormData();
        formData.append("siteId", this.props.site.id);

        Axios.post(ENDPOINTS.audit.getSiteAreas, formData)
            .then((r) => {
                let siteAreas = [];

                let resp = API.parse(r);
                if (resp.success) {
                    siteAreas = resp.data.siteAreas;
                } else {
                    // TODO Error
                }

                this.setState({
                    siteAreas
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchEstateExportFromNetwork = () => {
        if (this.state.tableCount <= 0) return;

        let suffix = [
            this.props.site.id,
            Chronos.with(this.state.startDate).seconds(),
            Chronos.with(this.state.endDate).seconds()
        ];

        if (this.state.auditTypeId !== undefined && this.state.auditTypeId != -1) {
            suffix.push(this.state.auditTypeId);
        } else {
            suffix.push("-1");
        }

        if (this.state.siteAreaId !== undefined && this.state.siteAreaId != -1) {
            suffix.push(this.state.siteAreaId);
        } else {
            suffix.push("-1");
        }

        let suffixString = "";
        suffix.forEach((str) => {
            suffixString += str + "/";
        });

        window.location.href = API.getAPIUrl(ENDPOINTS.audit.exportAuditSubmission + "/" + suffixString);
    }

    showImageGallery = (images) => {
        if (images !== undefined) {
            if (images.length > 0) {
                this.setState({
                    answerImagesShown: true,
                    answerImages: images
                });
            }
        }
    }

    dismissImageGallery = () => {
        this.setState({
            answerImagesShown : false
        });
    }

    tableRowWasClicked = (row) => {
        this.props.showModal("Table Actions", "Please select an option", [
            {
                label : "Delete Answer",
                className : "danger",
                click : () => {
                    this.promptUserToDeleteAnswer(row.id);
                }
            },
            {
                label : "Cancel",
                click : () => {
                    this.props.hideModal();
                }
            }
        ]);
    }

    promptUserToDeleteAnswer = (answerId) => {
        this.props.showModal("Confirm Deletion", "Please confirm you wish to delete this Answer. This action cannot be undone.", [
            {
                label : "Confirm Deletion",
                className : "danger",
                click : () => {
                    this.deleteAnswerOverNetwork(answerId);
                    this.props.hideModal();
                }
            },
            {
                label : "Do not delete",
                click : () => {
                    this.props.hideModal();
                }
            }
        ]);
    }

    deleteAnswerOverNetwork = (answerId) => {
        if (this.state.deletionNetworkInFlight) return;

        this.setState({
            deletionNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("auditSubmissionId", answerId);

        Axios.post(ENDPOINTS.audit.setAuditSubmissionInactive, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show("Success", "Successfully removed Answer", Toast.TYPE_SUCCESS);
                    this.getAnswerTableData();
                    this.forceGraphsToUpdate();
                } else {
                    this.props.showModal("Error", API.formatError(resp));
                }

                this.setState({
                    deletionNetworkInFlight : false
                });
            })
            .catch((e) => {
                this.props.showModal("Error", "An unknown error has occurred. Please try again later. [D2810]");

                this.setState({
                    deletionNetworkInFlight : false
                });
            });
    }

    forceGraphsToUpdate = () => {
        this.setState({
            forceGraphUpdate : Chronos.now().seconds()
        });
    }

    render() {
        if (this.props.site === undefined || this.props.site == null) {
            return [];
        }

        let exportButtonClass = "";
        if (this.state.tableCount === 0) {
            exportButtonClass = " disabled";
        }

        return (
            <div className={"container form-inputs audit-configuration-statistics-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Site Statistics</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-4"}>
                        <SiteConfigurationStatisticsVerticalGraphComponent
                            auditTypeId={Audit.TYPE.CLEANING_CHECK}
                            siteId={this.props.site.id}
                            updateTime={this.state.forceGraphUpdate} />
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <SiteConfigurationStatisticsVerticalGraphComponent
                            auditTypeId={Audit.TYPE.INSPECTION}
                            siteId={this.props.site.id}
                            updateTime={this.state.forceGraphUpdate}  />
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <SiteConfigurationStatisticsVerticalGraphComponent
                            auditTypeId={Audit.TYPE.CONTRACTOR}
                            siteId={this.props.site.id}
                            updateTime={this.state.forceGraphUpdate}  />
                    </div>
                </div>

                <div className={"row table-controls"}>
                    <div className={"col-6 col-md-3"}>
                        <label>Show results for:</label>
                        <select className={"form-control"} name={"auditTypeId"} value={this.state.auditTypeId} onChange={this.tableControlDidChange}>
                            <option value={-1}>All</option>
                            {
                                this.auditTypes.map((item) => (
                                    <option value={item.id}>{item.label}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className={"col-6 col-md-3"}>
                        <label>For Site Area:</label>
                        <select className={"form-control"} name={"siteAreaId"} value={this.state.siteAreaId} onChange={this.tableControlDidChange}>
                            <option value={-1}>All</option>
                            {
                                this.state.siteAreas.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className={"col-6 col-md-2"}>
                        <label>Start Date</label>
                        <DatePicker
                            className={"form-control"}
                            selected={this.state.startDate}
                            onChange={(date) => this.dateDidChange("start", date)}
                            dateFormat={"dd/MM/yyyy"} />
                    </div>

                    <div className={"col-6 col-md-2"}>
                        <label>End Date</label>
                        <DatePicker
                            className={"form-control"}
                            selected={this.state.endDate}
                            onChange={(date) => this.dateDidChange("end", date)}
                            dateFormat={"dd/MM/yyyy"} />
                    </div>

                    <div className={"col-12 col-md-2"}>
                        <label>&nbsp;</label><br />
                        <button className={"btn btn-primary" + exportButtonClass} onClick={this.fetchEstateExportFromNetwork}>Export PDF</button>
                        <div className={"file-hide"}>
                            <a ref={this.exportDownloadElem} href={this.state.exportDownloadUrl} download={this.state.exportDownloadFilename}> </a>
                        </div>
                    </div>
                </div>

                <div className={"row audit-table"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.tableData}
                            onRowClick={this.tableRowWasClicked}
                            onSort={(sort) => { this.tableDidSort(sort) }}
                            sortedColumn={this.state.sortedColumn}>
                            <Column name={"questionText"} title={"Question"} sortable={true} />
                            <Column name={"auditTypeName"} title={"Audit Type"} className={"text-center column-small"} sortable={true} />
                            <Column name={"siteAreaName"} title={"Asset Area"} className={"text-center column-small"} sortable={true} />
                            <Column name={"givenName"} title={"Answered By"} sortable={true} className={"text-center column-small"} render={(data, row) => {
                                if (row.signatureName !== undefined && row.signatureName !== "") {
                                    return row.signatureName;
                                }
                                if (row.givenName != null && row.familyName != null) {
                                    return row.givenName + " " + row.familyName;
                                }
                                return "(Anonymous)";
                            }} />
                            <Column name={"dateCreated"} title={"Date Answered"} className={"text-center column-small"} sortable={true} render={(data, row) => {
                                return Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm");
                            }} />
                            <Column name={"auditQuestionOutcomeId"} title={"Answer"} className={"text-center column-small"} sortable={true} render={(data, row) => {
                                data = parseInt(data);
                                let out = "N/A";
                                switch (data) {
                                    case Question.OUTCOMES.GOOD:
                                        out = this.getOrDefaultNotNull(row, "goodLabel", Question.getDefaultOutcomeLabel(Question.OUTCOMES.GOOD)); break;
                                    case Question.OUTCOMES.AVERAGE:
                                        out = this.getOrDefaultNotNull(row, "averageLabel", Question.getDefaultOutcomeLabel(Question.OUTCOMES.AVERAGE)); break;
                                    case Question.OUTCOMES.BAD:
                                        out = this.getOrDefaultNotNull(row, "badLabel", Question.getDefaultOutcomeLabel(Question.OUTCOMES.BAD)); break;
                                    case Question.OUTCOMES.NA:
                                        out = this.getOrDefaultNotNull(row, "skipLabel", Question.getDefaultOutcomeLabel(Question.OUTCOMES.NA)); break;
                                    default: break;
                                }
                                return out;
                            }} />
                            <Column name={"images"} title={"Photos"} className={"text-center column-small no-print"} render={(data, row) => {
                                let count = 0;
                                let label = "Images";
                                let buttonClass = "btn-primary";
                                if (data !== undefined && data !== null) {
                                    if (data.length === 1) {
                                        label = "Image";
                                    }
                                    count = data.length;

                                    if (data.length === 0) {
                                        buttonClass = "btn-secondary";
                                    }
                                }
                                return (
                                    <button className={"btn " + buttonClass + " column-small"} onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.showImageGallery(data) }}><span className={"badge badge-light"}>{count}</span> {label}</button>
                                );
                            }} />
                            <Column name={"images"} title={"Photos"} className={"text-center column-small no-ui"} render={(data, row) => {
                                let imageElems = [];
                                if (data !== undefined && data !== null) {
                                    data.forEach((image) => {
                                        imageElems.push(
                                            <img src={image.publicFullPath} className={"report-image"} alt={image.id} />
                                        )
                                    });
                                }
                                return imageElems;
                            }} />
                            <Column name={"comments"} title={"Comments"} className={"column-small"} />
                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.tablePage}
                                totalCount={this.state.tableCount}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>
                    </div>
                </div>

                <ImageGalleryComponent
                    shown={this.state.answerImagesShown}
                    images={this.state.answerImages}
                    dismissCallback={this.dismissImageGallery} />

                <UIBlockerComponent
                    shown={this.state.deletionNetworkInFlight} />
            </div>
        )
    }
}