import React from 'react';
import BaseComponent from "../../BaseComponent";
import DatePicker from "react-datepicker";
import {Chronos} from "../../../data/Chronos";
import {ENDPOINTS} from "../../../network/API";
import Axios from "axios";
import {Toast} from "../../dashboard/common/ToastManagerComponent";

import "./ExportDataWidgetComponent.css";

export default class ExportDataWidgetComponent extends BaseComponent {

    REPORT_TYPE_AUDIT_AREA = 1;
    REPORT_TYPE_SUMMARY = 2;

    constructor(props, context) {
        super(props, context);

        let startDate = Chronos.now().add(-1, Chronos.WEEKS).getDate();
        let endDate = Chronos.now().getDate();

        this.initState({
            exportDownloadUrl : "",
            exportDownloadFilename : "",
            exportStartDate : startDate,
            exportEndDate :endDate,
            exportDropdownVisible : false
        });

        this.exportDownloadElem = React.createRef();
    }

    dateDidChange = (type, date) => {
        if (type === "start") {
            this.setState({
                exportStartDate : date
            });
        } else {
            this.setState({
                exportEndDate : date
            });
        }
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    toggleExportOptionsVisibility = (type) => {
        let visibility = true;
        if (this.state.exportOptionsVisibile) {
            visibility = false;
        }

        this.setState({
            exportStartDate : this.state.startDate,
            exportEndDate : this.state.endDate,
            selectedExportType : type,
            exportDropdownVisible : false, // Hide button dropdown
            exportOptionsVisibile : visibility
        });
    }

    toggleExportDropDown = () => {
        let exportDropdownVisible = true;
        if (this.state.exportDropdownVisable) {
            exportDropdownVisible = false;
        }
        this.setState({
            exportDropdownVisible: exportDropdownVisible
        });
    }

    fetchEstateExportFromNetwork = () => {
        if (this.state.estateReportExportInFlight) return;

        this.setState({
            estateReportExportInFlight : true
        });

        let formData = new FormData();
        formData.append("startDate", Chronos.with(this.state.exportStartDate).seconds());
        formData.append("endDate", Chronos.with(this.state.exportEndDate).seconds());

        let url = ENDPOINTS.site.exportEstateReportData;
        if (this.state.selectedExportType === this.REPORT_TYPE_SUMMARY) {
            url = ENDPOINTS.site.getSiteSummaryExport;
        }

        Axios.post(url, formData)
            .then((r) => {
                console.log(r);
                if (r.status === 200) {
                    console.log(r.data);
                    this.setState({
                        exportDownloadUrl: window.URL.createObjectURL(new Blob([r.data]), {type:"text/csv"}),
                        exportDownloadFilename: "export_" + Chronos.now().format("yyyy_MM_dd_HH_mm_ss") + ".csv"
                    });

                    this.toggleExportOptionsVisibility();

                    setTimeout(() => {
                        this.exportDownloadElem.current.click();

                        window.URL.revokeObjectURL(this.state.exportDownloadUrl);

                        Toast.show("Export Complete", "Your download has started", Toast.TYPE_INFO);

                        this.setState({
                            estateReportExportInFlight : false,
                            exportDownloadUrl: "",
                            exportDownloadFilename: ""
                        });
                    }, 100)


                } else {
                    Toast.show("Error", "An error has occurred while fetching the download. Please reload the page.", Toast.TYPE_ERROR);
                }
            })
            .catch((e) => {
                Toast.show("Error", "An error occurred while fetching download. Please reload the page.", Toast.TYPE_ERROR);
            });
    }

    render() {
        let exportOptionsElems = [];
        if (this.state.exportOptionsVisibile) {
            let downloadLabel = "Download";
            let downloadClassExtra = "";
            if (this.state.estateReportExportInFlight) {
                downloadLabel = "Please Wait";
                downloadClassExtra = " disabled";
            }

            exportOptionsElems = (
                <div className={"export-options-container"}>
                    <div className={"close"} onClick={this.toggleExportOptionsVisibility}><span className={"text"}>X</span></div>

                    <div className={"form-block"}>
                        <span className={"export-title"}>Export Data</span>
                        <span className={"export-explain"}>Please specify a start date and end date to obtain data for and click "download" to acquire the report.</span>
                    </div>

                    <div className={"form-block"}>
                        <label>Start Date</label>
                        <DatePicker
                            className={"form-control"}
                            selected={this.state.exportStartDate}
                            onChange={(date) => this.dateDidChange("start", date)}
                            dateFormat={"dd/MM/yyyy"} />
                    </div>

                    <div className={"form-block"}>
                        <label>End Date</label>
                        <DatePicker
                            className={"form-control"}
                            selected={this.state.exportEndDate}
                            onChange={(date) => this.dateDidChange("end", date)}
                            dateFormat={"dd/MM/yyyy"} />
                    </div>

                    <div className={"form-block"}>
                        <button className={"btn btn-success btn-fullwidth" + downloadClassExtra} onClick={this.fetchEstateExportFromNetwork}>{downloadLabel}</button>
                    </div>
                </div>
            )
        }

        let exportDropdown = [];
        if (this.state.exportDropdownVisible) {
            exportDropdown = (
                <div className="dropdown-menu show" aria-labelledby="btnExportGroup">
                    <a className="dropdown-item" href={"#"} onClick={() => this.toggleExportOptionsVisibility(this.REPORT_TYPE_AUDIT_AREA)}>Area Report</a>
                    <a className="dropdown-item" href={"#"} onClick={() => this.toggleExportOptionsVisibility(this.REPORT_TYPE_SUMMARY)}>Submission Report</a>
                </div>
            )
        }

        return (
            <div className={"export-container"}>
                <div className="btn-group" role="group">
                    <button id="btnExportGroup" type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.toggleExportDropDown}>Export Data</button>
                    {exportDropdown}
                </div>
                {exportOptionsElems}
                <div className={"file-hide"}>
                    <a ref={this.exportDownloadElem} href={this.state.exportDownloadUrl} download={this.state.exportDownloadFilename}> </a>
                </div>
            </div>
        );
    }
}