import React from 'react';
import BaseComponent from "../../BaseComponent";

import "./HeaderBarComponent.css";

import DefaultLogo from "../../../assets/europackaging/ms_logo.png";
import genericLogo from "../../../assets/europackaging/ep_logo.png";
import accountCircle from "../../../assets/europackaging/account_circle.svg";
import {Brand} from "../../../data/Brand";

export default class HeaderBarComponent extends BaseComponent {

    profileActionWasClicked = () => {
        if (this.props.profileCallback !== undefined) {
            this.props.profileCallback();
        }
    }

    render() {
        let useLogo = genericLogo;
        if (parseInt(process.env.REACT_APP_BRAND_ID) === Brand.MS) {
            useLogo = DefaultLogo;
        }

        return (
            <div className={"header-bar-component"}>
                <div className={"header-bar"}>
                    <div className={"logo"} style={{backgroundImage : "url(" + useLogo + ")"}}/>
                    <div className={"actions"}>
                        <div className={"action-item"} style={{backgroundImage : "url(" + accountCircle + ")"}} onClick={this.profileActionWasClicked} />
                    </div>
                </div>
            </div>
        )
    }

}