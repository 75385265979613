import React from 'react';
import BaseComponent from "../../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import LoadingSpinner from "../../common/LoadingSpinner";
import {CleaningSpecification} from "../../../data/CleaningSpecification";
import MediaViewerPDFComponent from "./media/MediaViewerPDFComponent";
import MediaViewerAudioComponent from "./media/MediaViewerAudioComponent";
import MediaViewerYouTubeComponent from "./media/MediaViewerYouTubeComponent";

import "./CleaningSpecificationDetailComponent.css";
import ScreenTitleComponent from "../common/ScreenTitleComponent";

export default class CleaningSpecificationDetailComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let siteAreaId = this.getUriProperty("siteAreaId", null);
        let cleaningSpecId = this.getUriProperty("id", null);

        this.initState({
            siteAreaId : siteAreaId,
            cleaningSpecId : cleaningSpecId,
            cleaningSpec : null,
            cleaningSpecNetworkInFlight : false
        });
    }

    componentDidMount() {
        this.getCleaningSpecificationFromNetwork();

        window._epShowSiteChangeButton(false);
    }

    componentWillUnmount() {
        window._epShowSiteChangeButton(true);
    }

    getCleaningSpecificationFromNetwork = () => {
        if (this.state.cleaningSpecNetworkInFlight) return;

        this.setState({
            cleaningSpecNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("siteAreaId", this.state.siteAreaId);
        formData.append("cleaningSpecificationId", this.state.cleaningSpecId);

        Axios.post(ENDPOINTS.cleaningSpec.getCleaningSpecification, formData)
            .then((r) => {
                let cleaningSpec = null;

                let resp = API.parse(r);
                if (resp.success) {
                    cleaningSpec = resp.data.cleaningSpecification;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    cleaningSpecNetworkInFlight : false,
                    cleaningSpec : cleaningSpec
                })
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Please reload the page. [A3000]")
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    wrapMediaElement = (element) => {
        return (
            <div className={"row"}>
                <div className={"hidden-xs col-md-2 col-lg-3"} />
                <div className={"col-12 col-md-8 col-lg-6"}>
                    {element}
                </div>
            </div>
        );
    }

    render() {
        let screenTitle = "";
        let specificationItems = [];

        if (!this.state.cleaningSpecNetworkInFlight && this.state.cleaningSpec != null) {
            let spec = this.state.cleaningSpec;

            screenTitle = spec.siteAreaName + " - " + spec.title;

            if (spec.media !== undefined) {
                spec.media.forEach((media) => {
                    let mediaType = parseInt(media.cleaningSpecificationMediaTypeId);

                    if (mediaType === CleaningSpecification.MEDIA_TYPES.PDF) {
                        specificationItems.push(
                            this.wrapMediaElement(
                                <MediaViewerPDFComponent
                                    mediaUrl={media.mediaPath} />
                            )
                        )
                    } else if (mediaType === CleaningSpecification.MEDIA_TYPES.AUDIO) {
                        specificationItems.push(
                            this.wrapMediaElement(
                                <MediaViewerAudioComponent
                                    mediaUrl={media.mediaPath}
                                    mimeType={media.mimeType} />
                            )
                        )
                    } else if (mediaType === CleaningSpecification.MEDIA_TYPES.VIDEO) {
                        specificationItems.push(
                            this.wrapMediaElement(
                                <MediaViewerYouTubeComponent
                                    videoId={media.mediaPath} />
                            )
                        )
                    }
                });
            }
        } else {
            specificationItems = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <LoadingSpinner key={"loading"} />
                    </div>
                </div>
            )
        }

        return (
            <div className={"container cleaning-specification-detail-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent title={screenTitle} history={this.props.history} />
                    </div>
                </div>

                {specificationItems}
            </div>
        )
    }

}