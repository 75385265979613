import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {API, ENDPOINTS} from "../network/API";
import {DataManager} from "../data/DataManager";
import BaseComponent from "./BaseComponent";
import LoginComponent from "./login/LoginComponent";
import AlertModal from "./common/AlertModal";
import HeaderBarComponent from "./app/common/HeaderBarComponent";
import SiteInfoBarComponent from "./app/common/SiteInfoBarComponent";
import MainMenuComponent from "./app/home/MainMenuComponent";
import SiteSelectionComponent from "./app/user/SiteSelectionComponent";
import AuditSubmissionDecisionComponent from "./app/audit/AuditSubmissionDecisionComponent";
import AuditQuestionListComponent from "./app/audit/AuditQuestionListComponent";
import AuditQuestionInputComponent from "./app/audit/AuditQuestionInputComponent";
import AuditSubmissionCompleteComponent from "./app/audit/AuditSubmissionCompleteComponent";
import {Audit} from "../data/Audit";
import AuditSummaryComponent from "./app/audit/AuditSummaryComponent";
import CleaningSpecificationListComponent from "./app/specifications/CleaningSpecificationListComponent";
import CleaningSpecificationDetailComponent from "./app/specifications/CleaningSpecificationDetailComponent";
import AlertListComponent from "./app/alert/AlertListComponent";
import AlertDetailComponent from "./app/alert/AlertDetailComponent";
import TemporaryUserLandingComponent from "./login/TemporaryUserLandingComponent";
import TopBarComponent from "./dashboard/common/TopBarComponent";
import SiteConfigurationEditorComponent from "./dashboard/sites/SiteConfigurationEditorComponent";
import ToastManagerComponent from "./dashboard/common/ToastManagerComponent";
import UserManagementLandingComponent from "./dashboard/users/UserManagementLandingComponent";
import UserManagementEditorComponent from "./dashboard/users/UserManagementEditorComponent";
import AuditConfiguratorLandingComponent from "./dashboard/audits/AuditConfiguratorLandingComponent";
import AuditQuestionEditorComponent from "./dashboard/audits/AuditQuestionEditorComponent";
import CleaningSpecificationConfiguratorComponent
    from "./dashboard/specifications/CleaningSpecificationConfiguratorComponent";
import CleaningSpecificationEditorComponent from "./dashboard/specifications/CleaningSpecificationEditorComponent";
import SettingsLandingComponent from "./dashboard/settings/SettingsLandingComponent";
import SettingsSiteAreaEditorComponent from "./dashboard/settings/SettingsSiteAreaEditorComponent";
import ContractorHoursLandingComponent from "./dashboard/contractorhours/ContractorHoursLandingComponent";
import DashboardHomeComponent from "./dashboard/home/DashboardHomeComponent";
import Axios from "axios";
import SiteConfiguratorLandingComponent from "./dashboard/sites/SiteConfiguratorLandingComponent";
import RequestForgottenPasswordComponent from "./login/RequestForgottenPasswordComponent";
import SubmitForgottenPasswordComponent from "./login/SubmitForgottenPasswordComponent";
import ContractorLandingComponent from "./dashboard/contractorhours/ContractorLandingComponent";

export default class MainComponent extends BaseComponent {

    clientId = null;

    constructor(props, context) {
        super(props, context);

        let site = localStorage.getItem("site");
        if (site != null) {
            site = JSON.parse(site);
        }

        this.initState({
            user : null,
            alertModalOpen : false,
            alertModalIcon : undefined,
            alertModalTitle : undefined,
            alertModalMessage : undefined,
            alertModalButtons : undefined,
            currentSite : site,
            appConfigNetworkInFlight : false
        });
    }

    componentDidMount() {
        console.log(process.env);

        let pageTitle = process.env.REACT_APP_BRAND_NAME + " Cleaning";
        if (process.env.REACT_APP_PAGE_TITLE !== undefined) {
            pageTitle = process.env.REACT_APP_PAGE_TITLE;
        }

        document.title = pageTitle;

        let user = DataManager.getUser();
        let sessionToken = localStorage.getItem("sessionToken");
        let clientId = DataManager.getClientId();

        if (clientId !== null) {
            API.setClientId(clientId);
            this.clientId = clientId;
        }

        if (user !== null && sessionToken !== null) {
            this.userDidLogin(sessionToken, user);
        }

        API.onAuthFailed(() => {
            this.userDidLogout();

            setTimeout(() => {
                this.navigateToStartingScreen();
            }, 50);
        });

        this.getAppConfigFromNetwork();
    }

    userDidLogin = (token, user, site, clientId, appConfig) => {
        if (user !== undefined && user != null) {
            DataManager.setUser(user);

            if (token !== undefined) {
                localStorage.setItem("sessionToken", token);
                API.setAuthenticationToken(token);
            }

            if (site !== undefined && site != null) {
                DataManager.setCurrentSite(site);
            }

            if (clientId !== undefined) {
                DataManager.setClientId(clientId);
                API.setClientId(clientId);
                this.clientId = clientId;
            }

            this.setState({
                user: user,
                currentSite : site
            });

            if (appConfig !== undefined) {
                DataManager.setAppConfig(appConfig);
            } else {
                this.getAppConfigFromNetwork();
            }
        }
    }

    userDidLogout = () => {
        DataManager.clear();

        this.setState({
            user : null
        });

        // Force page refresh at this point, shake loose any low-hanging fruit
        window.location.href = "/";
    }

    siteDidChange = (site) => {
        DataManager.setCurrentSite(site);

        this.setState({
            currentSite : site
        });
    }

    profileWasClicked = () => {
        this.showModal("Sign Out", "Are you sure you want to sign out?", [
            {
                label : "Sign Out",
                className : "danger",
                click : () => {
                    this.userDidLogout();
                    this.hideModal();
                }
            },
            {
                label : "Cancel",
                click : () => {
                    this.hideModal();
                }
            }
        ])
    }

    navigateToStartingScreen = () => {
        window.location.href = "/";
    }

    showModal = (title, message, buttons, icon) => {
        console.log("SHOW MODAL");

        this.setState({
            alertModalOpen : true,
            alertModalIcon : icon,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        });
    }

    hideModal = () => {
        this.setState({
            alertModalOpen : false
        });
    }

    getAppConfigFromNetwork = () => {
        if (this.state.appConfigNetworkInFlight) {
            return false;
        }

        this.setState({
            appConfigNetworkInFlight : false
        });

        Axios.get(ENDPOINTS.config.getConfig)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    DataManager.setAppConfig(resp.data);
                } else {
                    console.log("Could not load App Config: " + resp.error.desc);
                }
            })
            .catch((e) => {
                console.log("Failed to load App Config, exception occurred.");
                console.log(e);
            });
    }

    render() {
        let alertModal = (
            <AlertModal
                open={this.state.alertModalOpen}
                icon={this.state.alertModalIcon}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
                buttons={this.state.alertModalButtons}
                dismissCallback={this.hideModal} />
        );

        console.log(this.state.user);
        if (this.state.user === undefined || this.state.user === null) {
            return (
                <div>
                    <BrowserRouter>
                        <Switch>
                            <Route
                                path={"/"} exact
                                render={(props) =>
                                    <LoginComponent
                                        loginCallback={this.userDidLogin}
                                        showModal={this.showModal}
                                        hideModal={this.hideModal}
                                        {...props} />
                                }/>

                            <Route
                                path={"/forgot"} exact
                                render={(props) =>
                                    <RequestForgottenPasswordComponent
                                        showModal={this.showModal}
                                        hideModal={this.hideModal}
                                        {...props} />
                                } />

                            <Route
                                path={"/forgotten/:token"} exact
                                render={(props) =>
                                    <SubmitForgottenPasswordComponent
                                        showModal={this.showModal}
                                        hideModal={this.hideModal}
                                        {...props} />
                                } />

                            <Route
                                path={"/covid-check"} exact
                                render={(props) =>
                                    <TemporaryUserLandingComponent
                                        loginCallback={this.userDidLogin}
                                        showModal={this.showModal}
                                        hideModal={this.hideModal}
                                        {...props} />
                                } />
                        </Switch>
                    </BrowserRouter>

                    {alertModal}
                </div>
            );
        } else {
            console.log("CLIENT ID: " + this.clientId + " :: " + (this.clientId === API.clientTypes.APP ? "APP" : "DASHBOARD"));
            if (this.clientId === API.clientTypes.APP) {
                return (
                    <BrowserRouter>
                        <div className={"header-bar-padding"}>
                            <HeaderBarComponent profileCallback={this.profileWasClicked} />
                            <SiteInfoBarComponent site={this.state.currentSite} />

                            <Switch>
                                <Route
                                    path={"/"} exact
                                    render={(props) =>
                                        <MainMenuComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            siteChangeCallback={this.siteDidChange}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/site-change"} exact
                                    render={(props) =>
                                        <SiteSelectionComponent
                                            siteChangeCallback={this.siteDidChange}
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/site-inspections"} exact
                                    render={(props) =>
                                        <AuditQuestionListComponent
                                            mode={Audit.TYPE.INSPECTION}
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/cleaning-checks"} exact
                                    render={(props) =>
                                        <AuditQuestionListComponent
                                            mode={Audit.TYPE.CLEANING_CHECK}
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/inspection-summary"} exact
                                    render={(props) =>
                                        <AuditSummaryComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/cleaning-check-question/:siteAreaId?/:questionId?"} exact
                                    render={(props) =>
                                        <AuditQuestionInputComponent
                                            mode={Audit.TYPE.CLEANING_CHECK}
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/inspection-question/:siteAreaId?/:questionId?"} exact
                                    render={(props) =>
                                        <AuditQuestionInputComponent
                                            mode={Audit.TYPE.INSPECTION}
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/audit-decision/:mode"} exact
                                    render={(props) =>
                                        <AuditSubmissionDecisionComponent
                                            site={this.state.currentSite}
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/audit-complete"} exact
                                    render={(props) =>
                                        <AuditSubmissionCompleteComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/cleaning-specifications/:id?"} exact
                                    render={(props) =>
                                        <CleaningSpecificationListComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/cleaning-specifications/view/:siteAreaId?/:id?"} exact
                                    render={(props) =>
                                        <CleaningSpecificationDetailComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/alerts"} exact
                                    render={(props) =>
                                        <AlertListComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/alerts/view/:id?"} exact
                                    render={(props) =>
                                        <AlertDetailComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/site-details/:section?"} exact
                                    render={(props) =>
                                        <SiteConfigurationEditorComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            useAltRouting={true}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/sites/:id/:section?"}
                                    render={(props) =>
                                        <SiteConfigurationEditorComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                { /* COVID CHECKS */ }

                                <Route
                                    path={"/covid-check"} exact
                                    render={(props) =>
                                        <AuditQuestionListComponent
                                            mode={Audit.TYPE.COVID}
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/covid-question/:siteAreaId?/:questionId?"} exact
                                    render={(props) =>
                                        <AuditQuestionInputComponent
                                            mode={Audit.TYPE.COVID}
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/covid-complete"} exact
                                    render={(props) =>
                                        <AuditSubmissionCompleteComponent
                                            mode={Audit.TYPE.COVID}
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                            </Switch>

                            {alertModal}
                        </div>
                    </BrowserRouter>
                )
            } else if (this.clientId === API.clientTypes.DASHBOARD) {
                // Standard Flow
                let routes = (
                    <Switch>
                        <Route
                            path={"/"} exact
                            render={(props) =>
                                <DashboardHomeComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/sites"} exact
                            render={(props) =>
                                <SiteConfiguratorLandingComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/sites/:id/:section?"}
                            render={(props) =>
                                <SiteConfigurationEditorComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/users"} exact
                            render={(props) =>
                                <UserManagementLandingComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/users/:id"}
                            render={(props) =>
                                <UserManagementEditorComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/audits/"} exact
                            render={(props) =>
                                <AuditConfiguratorLandingComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/audits/question/:id"}
                            render={(props) =>
                                <AuditQuestionEditorComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/cleaning-specifications"} exact
                            render={(props) =>
                                <CleaningSpecificationConfiguratorComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/cleaning-specifications/:id"}
                            render={(props) =>
                                <CleaningSpecificationEditorComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/contractor/:section?"}
                            render={(props) =>
                                <ContractorLandingComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/contractor-hours/"}
                            render={(props) =>
                                <ContractorHoursLandingComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                        <Route
                            path={"/settings/:section?"}
                            render={(props) =>
                                <SettingsLandingComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    signoutCallback={this.profileWasClicked}
                                    {...props} />
                            } />

                        <Route
                            path={"/site-area/:id"}
                            render={(props) =>
                                <SettingsSiteAreaEditorComponent
                                    showModal={this.showModal}
                                    hideModal={this.hideModal}
                                    {...props} />
                            } />

                    </Switch>
                );

                if (this.state.user !== undefined) {
                    if (parseInt(this.state.user.userTypeId) === API.userTypes.CONTRACTOR_MANAGER) {
                        // Contract Manager Flow only
                        routes = (
                            <Switch>

                                <Route
                                    path={"/"}
                                    exact={true}
                                    render={(props) =>
                                        <ContractorLandingComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/contractor/:section?"}
                                    render={(props) =>
                                        <ContractorLandingComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            {...props} />
                                    } />

                                <Route
                                    path={"/settings/:section?"}
                                    render={(props) =>
                                        <SettingsLandingComponent
                                            showModal={this.showModal}
                                            hideModal={this.hideModal}
                                            signoutCallback={this.profileWasClicked}
                                            {...props} />
                                    } />

                            </Switch>
                        )
                    }
                }

                return (
                    <BrowserRouter>
                        <div>
                            <TopBarComponent profileCallback={this.profileWasClicked}/>

                            {routes}

                            {alertModal}

                            <ToastManagerComponent />
                        </div>
                    </BrowserRouter>
                );
            }
        }
    }
}