import './App.css';
import MainComponent from "./components/MainComponent";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <MainComponent />
  );
}

export default App;
