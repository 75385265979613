import React from 'react';
import BaseComponent from "../../BaseComponent";

import "./ImageLightboxComponent.css";

import closeIcon from "../../../assets/europackaging/close-cross.svg";

export default class ImageLightboxComponent extends BaseComponent {

    dialogDidSignalClose = () => {
        if (this.props.dismissCallback != null) {
            this.props.dismissCallback();
        }
    }

    render() {
        let open = false;
        if (this.props.open !== undefined) {
            open = this.props.open;
        }

        if (!open) {
            return [];
        }

        let extraButtons = [];
        if (this.props.buttons !== undefined) {
            let buttons = this.props.buttons.map((button) => {
                let buttonClassExtra = "";
                if (button.className !== undefined) {
                    buttonClassExtra = " " + button.className;
                }

                return (
                    <button className={"btn btn-rounded btn-fullwidth" + buttonClassExtra} onClick={button.click}>{button.label}</button>
                )
            });

            extraButtons = (
                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6 button-area"}>
                        {buttons}
                    </div>
                </div>
            )
        }

        let comments = [];
        if (this.props.comments !== undefined) {
            comments = (
                <div className={"lightbox-image-comments"}>{this.props.comments}</div>
            )
        }

        return (
            <div className={"image-lightbox-component"}>
                <div className={"lightbox-close"} onClick={this.dialogDidSignalClose} style={{backgroundImage : "url(" + closeIcon + ")"}} />

                <div className={"spacer"} />

                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12 image-container"}>
                            <img src={this.props.imageUrl} className={"lightbox-image"} />
                        </div>
                    </div>

                    {comments}

                    {extraButtons}
                </div>

                <div className={"spacer"} />
            </div>
        )
    }
}