import React from 'react';
import BaseComponent from "../../BaseComponent";
import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../common/ToastManagerComponent";
import ScreenSearchComponent from "../common/ScreenSearchComponent";

import "./UserSelectionDialogComponent.css";

export default class UserSelectionDialogComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            userNetworkInFlight : false,
            tableData : [],
            currentPage : 1,
            tableTotal: 0,
            tableLimit : 10
        });
    }

    componentDidMount() {
        this.fetchUsersFromNetwork();
    }

    tableFilterDidChange = (keyword) => {
        this.fetchUsersFromNetwork(1, keyword);

        this.setState({
            tableKeyword : keyword,
            currentPage : 1
        });
    }

    tablePageDidChange = (page) => {
        this.setState({
            currentPage : page
        });

        this.fetchUsersFromNetwork(page);
    }

    fetchUsersFromNetwork = (page, keyword) => {
        if (this.state.userNetworkInFlight) return;

        this.setState({
            userNetworkInFlight : true
        });

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.tableKeyword;
        }

        let formData = new FormData();
        formData.append("page", page);

        if (keyword !== undefined && keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.user.getUsersAdmin, formData)
            .then((r) => {
                let tableData = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    Toast.show("Error", API.formatError(resp), Toast.TYPE_ERROR);
                }

                this.setState({
                    userNetworkInFlight : false,
                    tableData,
                    tableTotal
                });
            })
            .catch((e) => {
                console.log(e);
                Toast.show("Error", "Could not load Users, please try again later", Toast.TYPE_ERROR);

                this.setState({
                    userNetworkInFlight: false
                });
            });
    }

    dispatchCallback = (user) => {
        if (this.props.callback !== undefined) {
            this.props.callback(user);
        }
    }

    render() {
        if (!this.props.open) return [];

        return (
            <div className={"user-selection-dialog-component modal"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header"}>
                            Select User
                            <button type="button" className="close" aria-label="Close" onClick={() => this.dispatchCallback(undefined)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className={"modal-body"}>
                            <div className={"row"}>
                                <div className={"col-12 filter-margin"}>
                                    <ScreenSearchComponent
                                        callback={this.tableFilterDidChange} />
                                </div>

                                <div className={"col-12"}>
                                    <TableComponent
                                        className={"alternating clickable"}
                                        data={this.state.tableData}
                                        onRowClick={this.dispatchCallback}>
                                        <Column name={"givenName"} title={"Name"} render={(data, row) => {
                                            return row.givenName + " " + row.familyName;
                                        }} />
                                        <Column name={"siteCount"} title={"Asset Count"} />
                                        <Column name={"id"} title={""}  className={"text-center"} render={(column, row) => {
                                            return (
                                                <span className={"btn btn-primary"}>Select</span>
                                            );
                                        }} />
                                    </TableComponent>

                                    <div className={"form-block ep-table-paginate-container"}>
                                        <div className={"push"} />
                                        <TablePaginator
                                            page={this.state.currentPage}
                                            totalCount={this.state.tableTotal}
                                            pageSize={this.state.tableLimit}
                                            onClick={(page) => this.tablePageDidChange(page)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"modal-footer"}>
                            <button className={"btn btn-primary"} onClick={() => this.dispatchCallback(undefined)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}