import {API} from "../network/API";

export const Audit = {
    TYPE : {
        CLEANING_CHECK : 1,
        INSPECTION : 2,
        CONTRACTOR : 3,
        COVID: 4
    },
    getAuditTypeForUser : (userTypeId) => {
        if (userTypeId == API.userTypes.STORE_CHECK) {
            return Audit.TYPE.CLEANING_CHECK;
        } else if (userTypeId == API.userTypes.CONTRACTOR) {
            return Audit.TYPE.CONTRACTOR;
        } else if (userTypeId == API.userTypes.COVID19) {
            return Audit.TYPE.COVID;
        }
        return Audit.TYPE.INSPECTION;
    }
}