import React from 'react';
import BaseComponent from "../../BaseComponent";

import closeCross from "../../../assets/europackaging/close-cross.svg";

import "./ImageGalleryComponent.css";

export default class ImageGalleryComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            selectedImageUrl : null
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.images !== this.props.images) {
            let selectedImageUrl = null;

            if (this.props.images.length > 0) {
                selectedImageUrl = this.props.images[0].publicFullPath;
            }

            this.setState({
                selectedImageUrl : selectedImageUrl
            });
        }
    }

    selectImage = (image) => {
        this.setState({
            selectedImageUrl : image.publicFullPath
        });
    }

    dismissWasCalled = () => {
        if (this.props.dismissCallback !== undefined) {
            this.props.dismissCallback();
        }
    }

    render() {
        if (!this.props.shown) {
            return [];
        }

        return (
            <div className={"image-gallery-component"}>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6 col-lg-8 col-xl-9 image-display"} style={{backgroundImage : "url(" + this.state.selectedImageUrl + ")"}}/>
                        <div className={"col-12 col-md-6 col-lg-4 col-xl-3 image-thumbnails"}>
                            <div className={"header-bar"}>
                                <div className={"close-button"} style={{backgroundImage : "url(" + closeCross + ")" }} onClick={this.dismissWasCalled} />
                            </div>
                            <div className={"row thumbnail-container"}>
                                {this.props.images.map((image) => {
                                    let classExtra = "";
                                    if (image.publicFullPath === this.state.selectedImageUrl) {
                                        classExtra = " active";
                                    }

                                    return (
                                        <div className={"col-6 col-md-4 col-lg-4 col-xl-4"}>
                                            <div className={"thumbnail-item" + classExtra} style={{backgroundImage : "url(" + image.publicThumbPath + ")"}} onClick={() => this.selectImage(image)} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}