import React from 'react';
import BaseComponent from "../../BaseComponent";
import {Doughnut, HorizontalBar} from "react-chartjs-2";

import "./AuditSummaryComponent.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";
import UIBlockerComponent from "../common/UIBlockerComponent";
import {Chronos} from "../../../data/Chronos";
import ScreenTitleComponent from "../common/ScreenTitleComponent";

export default class AuditSummaryComponent extends BaseComponent {

    selectableYears = [];

    constructor(props, context) {
        super(props, context);

        let site = DataManager.getCurrentSite();
        let submission = DataManager.getCurrentSubmission();

        let startYear = 2020;
        let maxYear = parseInt(Chronos.now().format("Y"));
        for (let i = startYear; i <= maxYear; i++) {
            this.selectableYears.push(i);
        }

        this.initState({
            auditDataNetworkInFlight : false,
            site : site,
            submission : submission,
            currentYear : Chronos.now().format("Y"),
            currentScore : this.getCurrentMonthPercentage(),
            gaugeData: this.generateGaugeDataset(),
            barData : this.generateBarDataset()
        });
    }

    componentDidMount() {
        this.fetchSiteAuditReportDataFromNetwork();

        window._epShowSiteChangeButton(false);
    }

    componentWillUnmount() {
        window._epShowSiteChangeButton(true);
    }

    moveToNextScreen = () => {
        this.props.history.push("/site-inspections");
    }

    getCurrentMonthPercentage = (reportData) => {
        if (reportData !== undefined) {
            let currentMonth = parseInt(Chronos.now().format("M"));
            for (let i = 0; i < reportData.length; i++) {
                if (parseInt(reportData[i].month) === currentMonth) {
                    return parseInt(reportData[i].value);
                }
            }
        }
        return 0;
    }

    generateGaugeDataset = (reportData) => {
        let data = [0,0];

        if (reportData !== undefined) {
            let currentMonth = parseInt(Chronos.now().format("M"));
            for (let i = 0; i < reportData.length; i++) {
                if (parseInt(reportData[i].month) === currentMonth) {
                    let positive = parseInt(reportData[i].value);
                    data[0] = positive;
                    data[1] = 100 - positive;
                }
            }
        }

        return {
            datasets:[
                {
                    data: data,
                    backgroundColor: ["#59c134", "#c13434"],
                    hoverBackgroundColor: ["#59c134", "#c13434"]
                }
            ],
            labels: ["Good or Average", "Bad"],
        }
    }

    generateBarDataset = (reportData) => {
        let data = [];
        let labels = [];
        let backgroundColours = [];

        if (reportData !== undefined) {
            for (let i = 0; i <= 11; i++) {
                let value = 0;
                let label = "";
                for (let x = 0; x < reportData.length; x++) {
                    if (parseInt(reportData[x].month) === (i + 1)) {
                        value = reportData[x].value;
                        label = reportData[x].label;
                        backgroundColours[x] = "#59c134";
                    }
                }
                data[i] = value;
                labels[i] = label;
            }
        }

        return {
            datasets : [
                {
                    data : data,
                    backgroundColor : backgroundColours
                }
            ],
            labels : labels
        }
    }

    reportYearDidChange = (e) => {
        let value = e.target.value;

        if (value !== this.state.currentYear) {
            this.fetchSiteAuditReportDataFromNetwork(value);
        }

        this.setState({
            currentYear : value
        });
    }

    fetchSiteAuditReportDataFromNetwork = (year) => {
        if (this.state.auditDataNetworkInFlight) return;

        this.setState({
            auditDataNetworkInFlight : true
        });

        if (year === undefined) {
            year = this.state.currentYear;
        }

        let formData = new FormData();
        formData.append("siteId", this.state.site.id);
        formData.append("siteAreaId", this.state.submission.siteAreaId);
        formData.append("year", year);

        Axios.post(ENDPOINTS.audit.getSiteAuditReportData, formData)
            .then((r) => {
                let reportData = null;
                let gaugeData = {};
                let barData = {};
                let currentScore = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    reportData = resp.data.reportData;
                    gaugeData = this.generateGaugeDataset(reportData);
                    barData = this.generateBarDataset(reportData);
                    currentScore = this.getCurrentMonthPercentage(reportData);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    auditDataNetworkInFlight : false,
                    currentScore : currentScore,
                    reportData : reportData,
                    gaugeData : gaugeData,
                    barData : barData
                });
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Could not fetch report data at this time. [A2700]");

                this.setState({
                    auditDataNetworkInFlight: false
                });
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        return (
            <div className={"container audit-summary-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent title={"Summary"} history={this.props.history} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6 gauge-chart"}>
                        <Doughnut
                            data={this.state.gaugeData}
                            options={{
                                cutoutPercentage: 70,
                                legend : {
                                    display : false
                                },
                                rotation: Math.PI,
                                circumference: Math.PI
                            }} />

                        <div className={"overlay-display"}>
                            <div className={"title"}>{this.state.currentScore}%</div>
                            <div className={"subtitle"}>Current Store Score</div>
                        </div>
                    </div>
                </div>

                <div className={"row bar-options"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <div className={"row"}>
                            <div className={"col-12 col-md-6 col-lg-8"}>
                                <label>Historical Store Scores</label>
                            </div>
                            <div className={"col-12 col-md-6 col-lg-4"}>
                                <select className={"form-control"} value={this.state.currentYear} onChange={this.reportYearDidChange}>
                                    {
                                        this.selectableYears.map((year) =>
                                            <option value={year}>{year}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <HorizontalBar
                            data={this.state.barData}
                            options={{
                                legend : {
                                    display : false
                                },
                                scales: {
                                    xAxes: [{
                                        display: true,
                                        ticks: {
                                            suggestedMax: 100,
                                            beginAtZero: true
                                        }
                                    }]
                                }
                            }} />
                    </div>
                </div>

                <div className={"row button-area"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <button className={"btn btn-success btn-rounded btn-fullwidth"} onClick={this.moveToNextScreen}>Continue</button>
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.auditDataNetworkInFlight} />
            </div>
        )
    }

}