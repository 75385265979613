import React from 'react';
import BaseComponent from "../../BaseComponent";

import {Audit} from "../../../data/Audit";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Chronos} from "../../../data/Chronos";

import mainMenuAlerts from "../../../assets/europackaging/mainmenu_alerts.jpg";
import mainMenuChecks from "../../../assets/europackaging/mainmenu_check.jpg";
import mainMenuSpecs from "../../../assets/europackaging/mainmenu_specification.jpg";
import ChartIcon from "../../../assets/europackaging/icon_chart.svg";

import ScreenTitleComponent from "../common/ScreenTitleComponent";
import SiteConfigurationStatisticsVerticalGraphComponent from "../../dashboard/sites/SiteConfigurationStatisticsVerticalGraphComponent";
import LoadingSpinner from "../../common/LoadingSpinner";

import "./MainMenuComponent.css";
import EstateReportTableComponent from "../../common/estatereport/EstateReportTableComponent";
import ScreenSearchComponent from "../../dashboard/common/ScreenSearchComponent";
import ExportDataWidgetComponent from "../../common/estatereport/ExportDataWidgetComponent";

export default class MainMenuComponent extends BaseComponent {

    MENU_ITEM_ALERTS = 1;
    MENU_ITEM_INSPECTIONS = 2;
    MENU_ITEM_CHECKS = 3;
    MENU_ITEM_SPECIFICATIONS = 4;

    constructor(props, context) {
        super(props, context);

        let user = DataManager.getUser();
        let site = DataManager.getCurrentSite();

        this.initState({
            user,
            site,
            alertCountNetworkInFlight : false,
            incompleteSiteNetworkInFlight : false,
            latestAuditNetworkInFlight : false,
            lastAuditSubmission : null,
            estateTableKeyword : undefined
        });
    }

    componentDidMount() {
        this.getAlertCountFromNetwork();
        this.getLatestAuditSubmissionFromNetwork()

        if (this.state.user != null) {
            if (this.state.user.userTypeId === API.userTypes.COVID19) {
                this.menuItemWasClicked({id:this.MENU_ITEM_CHECKS});
            }
        }

        // Listener to detect when an app config network request ended
        window._appConfigDidUpdate = () => {
            this.setState({
                uselessUpdate : Math.random()
            });
        }
    }

    componentWillUnmount() {
        window._appConfigDidUpdate = undefined;
    }

    setupMenuItems = () => {
        let menuItems = [];

        let appConfig = DataManager.getAppConfig();

        let auditType = Audit.getAuditTypeForUser(this.state.user.userTypeId);

        menuItems.push({
            id :this.MENU_ITEM_ALERTS,
            title : this.getOrDefault(appConfig, DataManager.appConfigKeys.Home.LABEL_ALERTS, "Alerts"),
            badge : this.state.alertCount,
            className : "alerts",
            style : {
                backgroundImage : "url(" + mainMenuAlerts + ")"
            }
        });

        let checkBadge = null;
        if (this.state.lastAuditSubmission != null) {
            checkBadge = "Last Check: " + Chronos.withTimestampSeconds(this.state.lastAuditSubmission.dateCreated).format("dd/MM/yyyy");
        }

        if (auditType === Audit.TYPE.CLEANING_CHECK || auditType === Audit.TYPE.COVID) {
            menuItems.push({
                id: this.MENU_ITEM_CHECKS,
                title: this.getOrDefault(appConfig, DataManager.appConfigKeys.Home.LABEL_CLEANING_CHECKS, "Cleaning Checks"),
                badge : checkBadge,
                className: "audit",
                style : {
                    backgroundImage : "url(" + mainMenuChecks + ")"
                }
            });
        } else {
            menuItems.push({
                id : this.MENU_ITEM_INSPECTIONS,
                title : this.getOrDefault(appConfig, DataManager.appConfigKeys.Home.LABEL_INSPECTION_AUDIT, "Inspections"),
                badge : checkBadge,
                className : "audit",
                style : {
                    backgroundImage : "url(" + mainMenuChecks + ")"
                }
            });
        }

        menuItems.push({
            id : this.MENU_ITEM_SPECIFICATIONS,
            title : this.getOrDefault(appConfig, DataManager.appConfigKeys.Home.LABEL_CLEANING_SPEC, "Cleaning Specifications"),
            className : "specification",
            style : {
                backgroundImage : "url(" + mainMenuSpecs + ")"
            }
        });

        return menuItems;
    }

    menuItemWasClicked = (menuItem) => {
        let newPath = null;

        switch (menuItem.id) {
            case this.MENU_ITEM_ALERTS:
                newPath = "/alerts"; break;
            case this.MENU_ITEM_CHECKS:
            case this.MENU_ITEM_INSPECTIONS:
                 this.auditItemWasSelected(menuItem.id); break;
            case this.MENU_ITEM_SPECIFICATIONS:
                newPath = "/cleaning-specifications"; break;
            default :
                newPath = null;
        }

        if (newPath != null) {
            this.props.history.push(newPath);
        }
    }

    auditItemWasSelected = (selectionId) => {
        let site = DataManager.getCurrentSite();
        if (site == null) {
            this.showError("Cannot continue. No Asset has been selected. Please pick an Asset by selecting 'Change Asset' above.");
            return;
        }

        let user = DataManager.getUser();
        let userTypeId = parseInt(user.userTypeId);

        if (userTypeId === API.userTypes.COVID19) {
            let submission = DataManager.getCurrentSubmission();
            if (submission != null) {
                this.moveToAuditStart(selectionId);
                return;
            }
        }

        this.getIncompleteSubmissionFromNetwork(selectionId);
    }

    getAlertCountFromNetwork = () => {
        if (this.state.alertCountNetworkInFlight) return;

        this.setState({
            alertCountNetworkInFlight : true
        });

        let formData = new FormData();

        if (this.state.user != null) {
            if (parseInt(this.state.user.userTypeId) === API.userTypes.STORE_CHECK) {
                let site = DataManager.getCurrentSite();
                if (site != null) {
                    formData.append("siteId", site.id);
                } else {
                    return;
                }
            }
        }

        Axios.post(ENDPOINTS.alerts.getAlertCount, formData)
            .then((r) => {
                let alertCount = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    alertCount = resp.data.count;
                } else {
                    console.log("SILENT ERROR: " + API.formatError(resp));
                }

                this.setState({
                    alertCountNetworkInFlight : false,
                    alertCount : alertCount
                });
            })
            .catch((e) => {
                console.log("SILENT ERROR: Could not get read count from network. [A0000]");

                this.setState({
                    alertCountNetworkInFlight : false
                });
            });
    }

    getIncompleteSubmissionFromNetwork = (selectionId) => {
        if (this.state.incompleteSiteNetworkInFlight) return;

        this.setState({
            incompleteSiteNetworkInFlight : true
        });

        let site = DataManager.getCurrentSite();

        let formData = new FormData();
        formData.append("siteId", site.id);

        Axios.post(ENDPOINTS.audit.getIncompleteAudit, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.hasIncomplete && resp.data.submission != null) {
                        let moveToQuestions = false;

                        let currentSubmission = DataManager.getCurrentSubmission();
                        if (currentSubmission != null) {
                            if (resp.data.submission.id == currentSubmission.id) {
                                console.log("Submission is the same submission we currently have.");
                                moveToQuestions = true;
                            }
                        }

                        if (moveToQuestions) {
                            // The existing submission is the same as the currently
                            // selected submission. Just go to the question UI.
                            this.moveToAuditStart(selectionId);
                        } else {
                            DataManager.setCurrentSubmission(resp.data.submission);
                            this.moveToAuditDecisionScreen(selectionId);
                        }
                    } else {
                        this.createSubmissionOverNetwork(selectionId);
                    }

                    this.setState({
                        incompleteSiteNetworkInFlight : false
                    });
                } else {
                    this.showError(API.formatError(resp));

                    this.setState({
                        incompleteSiteNetworkInFlight : false
                    });
                }
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Please reload the page.[A200]");

                this.setState({
                    incompleteSiteNetworkInFlight : false
                });
            });
    }

    createSubmissionOverNetwork = (selectionId) => {
        if (this.state.createAuditNetworkInFlight) return;

        this.setState({
            createAuditNetworkInFlight : true
        });

        let site = DataManager.getCurrentSite();

        let formData = new FormData();
        formData.append("siteId", site.id);

        Axios.post(ENDPOINTS.audit.createAudit, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.auditSubmission != null) {
                        DataManager.setCurrentSubmission(resp.data.auditSubmission);

                        this.moveToAuditStart(selectionId);
                    }
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    createAuditNetworkInFlight : false
                });
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Please reload the page. [A100]");

                this.setState({
                    createAuditNetworkInFlight : false
                });
            });
    }

    getLatestAuditSubmissionFromNetwork = () => {
        if (this.state.latestAuditNetworkInFlight) return;
        let site = DataManager.getCurrentSite();

        if (site == null) {
            return;
        }

        this.setState({
            latestAuditNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("siteId", site.id);
        // TODO Filter by Audit Type?

        Axios.post(ENDPOINTS.audit.getLastAudit, formData)
            .then((r) => {
                let lastAudit = null;

                let resp = API.parse(r);
                if (resp.success) {
                    lastAudit = resp.data.audit;
                } else {
                    console.log("SILENT ERROR: " + API.formatError(resp));
                }

                this.setState({
                    latestAuditNetworkInFlight : false,
                    lastAuditSubmission : lastAudit
                });
            })
            .catch((e) => {
                this.setState({
                    latestAuditNetworkInFlight : false
                });

                console.log("SILENT ERROR: Unknown error occurred getting last audit.");
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    moveToAuditDecisionScreen = (selectionId) => {
        let mode = Audit.TYPE.CLEANING_CHECK;
        if (selectionId === this.MENU_ITEM_INSPECTIONS) {
            mode = Audit.TYPE.INSPECTION;
        }

        this.props.history.push("/audit-decision/" + mode);
    }

    moveToAuditStart = (selectionId) => {
        let destination = "/cleaning-checks";
        if (selectionId === this.MENU_ITEM_INSPECTIONS) {
            destination = "/inspection-summary";
        }

        this.props.history.push(destination);
    }

    moveToSiteStats = () => {
        this.props.history.push("/site-details");
    }

    downloadLatestAuditPDF = (auditTypeId) => {
        window.location = API.getAPIUrl(ENDPOINTS.audit.exportLatestAuditSubmission, false) + "/" + this.state.site.id + "/" + auditTypeId;
    }

    estateSearchKeywordDidChange = (keyword) => {
        this.setState({
            estateTableKeyword : keyword
        });
    }

    render() {
        if (this.state.user != null) {
            if (this.state.user.userTypeId === API.userTypes.COVID19) {
                // Do not show any menu items for COVID users, they will be redirected out.
                return (
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                <LoadingSpinner />
                            </div>
                        </div>
                    </div>
                );
            }
        }

        let menuItems = this.setupMenuItems();

        let reportWidgets = [];

        if (this.state.user.userTypeId == API.userTypes.AUDIT_TEAM || this.state.user.userTypeId == API.userTypes.STORE_CHECK) {
            if (this.state.site != null) {
                reportWidgets = (
                    <div className={"row graph-section"}>
                        <div className={"hidden-md col-lg-2"}/>
                        <div className={"col-12 col-md-6 col-lg-4 graph-space text-center"}>
                            <SiteConfigurationStatisticsVerticalGraphComponent
                                auditTypeId={Audit.TYPE.CLEANING_CHECK}
                                siteId={this.state.site.id}
                                latestOnly={true}/>

                            <button className={"btn btn-outline-dark mt-3"} onClick={() => this.downloadLatestAuditPDF(Audit.TYPE.CLEANING_CHECK)}>Download Latest Check</button>
                        </div>
                        <div className={"col-12 col-md-6 col-lg-4 graph-space text-center"}>
                            <SiteConfigurationStatisticsVerticalGraphComponent
                                auditTypeId={Audit.TYPE.CONTRACTOR}
                                siteId={this.state.site.id}
                                latestOnly={true}/>

                            <button className={"btn btn-outline-dark mt-3"} onClick={() => this.downloadLatestAuditPDF(Audit.TYPE.CONTRACTOR)}>Download Latest Audit</button>
                        </div>
                    </div>
                )
            }
        }

        let statButton = [];
        let estateReportTable = [];
        if (parseInt(this.state.user.userTypeId) === API.userTypes.CONTRACTOR) {
            statButton = (
                <div className={"row"}>
                    <div className={"hidden-xs col-sm-2 col-md-3 col-lg-4"} />
                    <div className={"col-12 col-sm-8 col-md-6 col-lg-4"}>
                        <div className={"statistics-button"} onClick={this.moveToSiteStats}>
                            <div className={"spacer"} />
                            <div className={"icon"} style={{"background-image" : "url(" + ChartIcon + ")"}}/>
                            <div className={"text"}>View Site Stats</div>
                            <div className={"spacer"} />
                        </div>
                    </div>
                </div>
            )

            estateReportTable.push(
                <div className={"row estate-report-table"}>
                    <div className={"col-sm-12 col-md-4"}>
                        <ScreenTitleComponent
                            title={"Estate Report"}
                            showBack={false} />
                    </div>

                    <div className={"col-sm-6 col-md-4 screen-actions"}>
                        <ScreenSearchComponent callback={(keyword) => { this.estateSearchKeywordDidChange(keyword) }} />
                    </div>
                    <div className={"col-sm-6 col-md-4 screen-actions text-right"}>
                        <ExportDataWidgetComponent {...this.props} />
                    </div>
                    <div className={"col-12"}>
                        <EstateReportTableComponent
                            tableKeyword={this.state.estateTableKeyword}
                            siteChangeCallback={this.props.siteChangeCallback}
                            {...this.props} />
                    </div>
                </div>
            )
        }

        return (
            <div className={"container main-menu-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent title={"Main Menu"} showBack={false} />
                    </div>
                </div>

                <div className={"row"}>
                    {
                        menuItems.map((item) => {
                            let badgeElem = [];
                            if (item.hasOwnProperty("badge")) {
                                if (item.badge != null && item.badge !== 0) {
                                    badgeElem = (
                                        <span className={"badge-container"}>
                                            <span className={"badge"}>{item.badge}</span>
                                        </span>
                                    )
                                }
                            }

                            let classExtra = "";
                            if (item.hasOwnProperty("className")) {
                                classExtra = item.className;
                            }

                            let style = {};
                            if (item.hasOwnProperty("style")) {
                                style = item.style;
                            }

                            return (
                                <div className={"col-12 col-md-6 col-lg-4 item-margin"} onClick={() => this.menuItemWasClicked(item)}>
                                    <div className={"menu-item embed-responsive embed-responsive-4by3 " + classExtra} style={style}>
                                        <div className={"menu-item-content"}>
                                            {badgeElem}
                                            <div className={"item-title"}>{item.title}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {reportWidgets}

                {statButton}

                {estateReportTable}
            </div>
        );
    }
}