import React from 'react';
import BaseComponent from "../../BaseComponent";

import "./AlertListComponent.css";
import LoadingSpinner from "../../common/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import indicatorIcon from "../../../assets/europackaging/arrow_right-black.svg";
import ScreenTitleComponent from "../common/ScreenTitleComponent";
import {DataManager} from "../../../data/DataManager";

export default class AlertListComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            alertFetchNetworkInFlight : false,
            shouldLoadMoreAlerts : true,
            currentPage : 1,
            filterKeyword : null,
            alertData : []
        });
    }

    componentDidMount() {
        this.fetchAlertsFromNetwork();
    }

    moveToDetailScreen = (id) => {
        this.props.history.push("/alerts/view/" + id);
    }

    getNextPage = () => {
        let page = this.state.currentPage + 1;

        this.setState({
            currentPage : page
        });

        this.fetchAlertsFromNetwork(page);
    }

    fetchAlertsFromNetwork = (page, keyword) => {
        if (this.state.alertFetchNetworkInFlight) return;

        this.setState({
            alertFetchNetworkInFlight : true
        });

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.filterKeyword;
        }

        let user = DataManager.getUser();

        let formData = new FormData();
        formData.append("page", page);

        let userTypeId = parseInt(user.userTypeId);

        if (userTypeId === API.userTypes.STORE_CHECK || userTypeId === API.userTypes.COVID19) {
            let site = DataManager.getCurrentSite();
            formData.append("siteId", site.id);
        }

        if (keyword !== undefined && keyword != null) {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.alerts.getAlerts, formData)
            .then((r) => {
                let alerts = this.state.alertData;
                let shouldGetMore = true;

                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.alerts != null) {
                        if (resp.data.alerts.length > 0) {
                            resp.data.alerts.forEach((alert) => {
                                alerts.push(alert);
                            });
                        } else {
                            shouldGetMore = false;
                        }
                    }
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    alertFetchNetworkInFlight : false,
                    shouldLoadMoreAlerts : shouldGetMore,
                    alertData : alerts
                });
            })
            .catch((e) => {
                console.log(e);

                this.showError("Could not retrieve Alerts. [A4001]");
            });
    }

    getNextPage = () => {
        if (this.state.shouldLoadMoreAlerts) {
            let nextPage = this.state.currentPage + 1;

            this.fetchAlertsFromNetwork(nextPage);

            this.setState({
                currentPage : nextPage
            });
        }
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        let listElements = [];

        if (this.state.alertData.length > 0) {
            this.state.alertData.forEach((alert) => {
                let readClass = "";
                if (alert.read == 1) {
                    readClass = " read";
                }

                listElements.push(
                    <div className={"alert-list-item" + readClass} onClick={() => this.moveToDetailScreen(alert.id)}>
                        <div className={"read-indicator"} />
                        <div className={"alert-content"}>
                            <div className={"alert-title"}>{alert.title}</div>
                            <div className={"alert-message"}>{alert.message}</div>
                        </div>
                        <div className={"indicator"} style={{backgroundImage : "url(" + indicatorIcon + ")"}} />
                    </div>
                )
            });

            if (this.state.shouldLoadMoreAlerts && !this.state.alertFetchNetworkInFlight) {
                listElements.push(
                    <button className={"btn btn-primary btn-rounded btn-fullwidth form-block"} onClick={this.getNextPage}>Load More</button>
                )
            }
        } else if (!this.state.alertFetchNetworkInFlight) {
            listElements.push(
                <div className={"empty-message"}>
                    You currently have no Alerts
                </div>
            )
        }

        if (this.state.alertFetchNetworkInFlight) {
            listElements.push(
                <LoadingSpinner key={"loading"} />
            )
        }

        return (
            <div className={"container alert-list-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent title={"Alerts"} history={this.props.history} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6 alert-list"}>
                        {listElements}
                    </div>
                </div>
            </div>
        )
    }

}