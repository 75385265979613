import React from 'react';

import LoadingSpinner from "../../common/LoadingSpinner";

import './UIBlockerComponent.css';

export default class UIBlockerComponent extends React.Component {

    render() {
        let shown = false;
        if (this.props.hasOwnProperty("shown")) {
            shown = this.props.shown;
        }

        let extraClass = "";
        if (this.props.hasOwnProperty("containable")) {
            if (this.props.containable) {
                extraClass += " containable";
            }
        }

        if (shown) {
            return (
                <div className={"ui-blocker-component" + extraClass} onClick={(e) => e.stopPropagation()}>
                    <div className={"spacer"} />
                    <LoadingSpinner />
                    <div className={"spacer"} />
                </div>
            )
        }
        return [];
    }
}